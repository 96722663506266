import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: 01/10/2024.</p>
      <p>
        This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Platform and tells You about Your privacy rights and how the law protects You.
      </p>
      <p>
        We use Your Personal data to provide and improve the Platform. By using the Platform, You agree to the collection and use of information in accordance with this Privacy Policy.
      </p>
      <p>
        Please read this Privacy Policy carefully before using Our Platform.
      </p>

      
      <h2>1 - Interpretation and Definitions</h2>
      <h3>1.1 - Interpretation</h3>
      <p>
        The words of which the initial letter is capitalised have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in the singular or in the plural.
      </p>

      <h3>1.2 - Definitions</h3>
      <p>
        For the purposes of this Privacy Policy:
      </p>
      <ul className={css.unorderedList}>
        <li className={css.liStyles}><b>Account</b> means a unique account created for You to access our Platform or parts of our Platform.</li>
        <li className={css.liStyles}><b>Affiliate</b> means an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for the election of directors or other managing authority.</li>
        <li className={css.liStyles}><b>Application</b> means the software program provided by the Company or Operator downloaded by You on any electronic device, named Aqtive Co.</li>
        <li className={css.liStyles}><b>Business</b> refers to the Company or Operator as the legal entity that collects Consumers' personal information and determines the purposes and means of the processing of Consumers' personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers' personal information.</li>
        <li className={css.liStyles}><b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Aqtive Co, AQTIVECO PERFORMANCE LIMITED..</li>
        <li className={css.liStyles}><b>Consumer,</b> refers to You.</li>
        <li className={css.liStyles}><b>Cookies</b> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
        <li className={css.liStyles}><b>Country</b> refers to Kenya.</li>
        <li className={css.liStyles}><b>Data Controller</b>, for the purposes of the GDPR (General Data Protection Regulation), refers to the Company or Operator as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.</li>
        <li className={css.liStyles}><b>Device</b> means any device that can access the Platform such as a computer, a cellphone, or a digital tablet.</li>
        <li className={css.liStyles}><b>Operator</b> (referred to as either "the Operator", "We", "Us" or "Our" in this Agreement) refers to Aqtive Co.</li>
        <li className={css.liStyles}><b>Personal Data</b> is any information that relates to an identified or identifiable individual. For the purposes of GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity.</li>
        <li className={css.liStyles}><b>Sale,</b> means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer's personal information to another business or a third party for monetary or other valuable consideration.</li>
        <li className={css.liStyles}><b>Platform</b> refers to the Application or the Website or both.</li>
        <li className={css.liStyles}><b>Service Provider</b> means any natural or legal person who processes the data on behalf of the Company or Operator. It refers to third-party companies or individuals employed by the Company or Operator to facilitate the Platform, to provide the Platform on behalf of the Company or Operator, to perform services related to the Platform or to assist the Company or Operator in analysing how the Platform is used. For the purpose of the GDPR, Service Providers are considered Data Processors.</li>
        <li className={css.liStyles}><b>Third-party Social Media Service</b> refers to any website or any social network website through which a User can log in or create an account to use the Platform.</li>
        <li className={css.liStyles}><b>Usage Data</b> refers to data collected automatically, either generated by the use of the Platform or from the Platform infrastructure itself (for example, the duration of a page visit).</li>
        <li className={css.liStyles}><b>Website</b> refers to Aqtive Co, accessible from <a href="https://aqtive.co/" target="_blank">https://aqtive.co/</a></li>
        <li className={css.liStyles}><b>You</b> means the individual accessing or using the Platform, or the company, or other legal entity on behalf of which such individual is accessing or using the Platform, as applicable. <br />
        Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the User as you are the individual using the Platform.</li>
        
      </ul>

      
      <h2>2 - Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, You can contact us:
      </p>
      <ul className={css.unorderedList}>
        <li className={css.liStyles}>By email: <a href="mailto:info@aqtive.co">info@einfo@aqtive.co</a></li>
        <li className={css.liStyles}>By visiting this page on our website: <a href="https://aqtive.co/contactus" target="_blank">https://aqtive.co/contactus</a></li>
      </ul>


      <h2>3 - Collecting and Using Your Personal Data</h2>
      <h3>3.1 - Types of Data Collected</h3>
      <h4 className={css.headingStyle}>3.1.1 - Personal Data</h4>
      <p>
        While using Our Platform, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
      </p>
      <ul className={css.unorderedList}>
        <li className={css.liStyles}>Email address</li>
        <li className={css.liStyles}>First name and last name</li>
        <li className={css.liStyles}>Phone number</li>
        <li className={css.liStyles}>Usage Data</li>
      </ul>

      <h4 className={css.headingStyle}>3.1.2 - Usage Data</h4>
      <p>
        Usage Data is collected automatically when using the Platform.
      </p>
      <p>
        Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Platform that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
      </p>
      <p>
        When You access the Platform by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
      </p>
      <p>
        We may also collect information that Your browser sends whenever You visit our Platform or when You access the Platform by or through a mobile device.
      </p>

      <h4 className={css.headingStyle}>3.1.3 - Information from Third-Party Social Media Services</h4>
      <p>
        The Company or Operator may allow You to create an account and log in to use the Platform through the following Third-party Social Media Services. These Third-party Social Media Services, may include, but is not limited to:
      </p>
      <ul className={css.unorderedList}>
        <li className={css.liStyles}>Google</li>
        <li className={css.liStyles}>Facebook</li>
      </ul>
      <p>
        If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, including but not limited to Your name, Your email address or Your age.
      </p>
      <p>
        You may also have the option of sharing additional information with the Company or Operator through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company or Operator permission to use, share, and store it in a manner consistent with this Privacy Policy.
      </p>

      <h4 className={css.headingStyle}>3.1.4 - Tracking Technologies and Cookies</h4>
      <p>
        We use Cookies and similar tracking technologies to track the activity on Our Platform and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyse Our Platform. The technologies We use may include:
      </p>
      <ul className={css.unorderedList}>
        <li className={css.liStyles}><b>Cookies or Browser Cookies.</b> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Platform. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Platform may use Cookies.</li>
        <li className={css.liStyles}><b>Web Beacons.</b> Certain sections of our Platform and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company or Operator, for example, to count users who have visited those pages or opened an email and for other related application or website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
      </ul>
      <p>
        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.
      </p>
      <p>We use both Session and Persistent Cookies for the purposes set out below:</p>

      <ul className={css.unorderedList}>
        <li className={css.liStyles}><b>Necessary / Essential Cookies</b><br/>
          Type: Session Cookies<br/>
          Administered by: Us<br/>
          Purpose: These Cookies are essential to provide You with services available through the Application or Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
        </li>
        <li className={css.liStyles}><b>Cookies Policy / Notice Acceptance Cookies</b><br/>
          Type: Persistent Cookies<br/>
          Administered by: Us<br/>
          Purpose: These Cookies identify if users have accepted the use of cookies on the Application or Website.</li>
        <li className={css.liStyles}><b>Functionality Cookies</b><br/>
          Type: Persistent Cookies<br/>
          Administered by: Us<br/>
          Purpose: These Cookies allow us to remember choices You make when You use the Application or Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Application or Website.</li>
        <li className={css.liStyles}>
          <b>Tracking and Performance Cookies</b><br/>
          Type: Persistent Cookies<br/>
          Administered by: Third-Parties<br/>
          Purpose: These Cookies are used to track information about traffic to the Application or Website and how users use the Application or Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Application or Website. We may also use these Cookies to test new pages, features or new functionality of the Application or Website to see how our users react to them.
        </li>
      </ul>
      

      <h3>3.2 - Use of Your Personal Data</h3>
      <p>The Company or Operator may use Personal Data for the following purposes:</p>
      <ul className={css.unorderedList}>
        <li className={css.liStyles}><b>To provide and maintain our Platform,</b> including to monitor the usage of our Platform. </li>
        <li className={css.liStyles}><b>To manage Your Account:</b> to manage Your registration as a user of the Platform. The Personal Data You provide can give You access to different functionalities of the Platform that are available to You as a registered user. </li>
        <li className={css.liStyles}><b>For the performance of a contract:</b> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Platform.</li>
        <li className={css.liStyles}><b>To contact You:</b> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
        <li className={css.liStyles}><b>To provide You</b> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</li>
        <li className={css.liStyles}><b>To manage Your requests:</b> To attend and manage Your requests to Us.</li>
        <li className={css.liStyles}><b>To deliver targeted advertising to You:</b> We may use Your information to develop and display content and advertising (and work with third-party vendors who do so) tailored to Your interests and/or location and to measure its effectiveness.</li>
        <li className={css.liStyles}><b>For business transfers:</b> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganisation, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Platform users is among the assets transferred.</li>
        <li className={css.liStyles}><b>For other purposes:</b> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Platform, products, services, marketing and your experience.</li>
      </ul>
      <p>We may share Your personal information in the following situations:</p>
      <ul className={css.unorderedList}>
        <li className={css.liStyles}><b>With Service Providers:</b> We may share Your personal information with Service Providers to monitor and analyse the use of our Platform, to advertise on third party websites, for payment processing, to contact You.</li>
        <li className={css.liStyles}><b>For business transfers:</b> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company or Operator assets, financing, or acquisition of all or a portion of Our business to another company.</li>
        <li className={css.liStyles}><b>With Affiliates:</b> We may share Your information with Our affiliates, in which case we will require those affiliates to honour this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
        <li className={css.liStyles}><b>With other users:</b> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.</li>
        <li className={css.liStyles}><b>With Your consent:</b> We may disclose Your personal information for any other purpose with Your consent.</li>
      </ul>

      <h3>3.3 - Retention of Your Personal Data</h3>
      <p>
        The Company or Operator will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
      </p>
      <p>
        The Company or Operator will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Platform, or We are legally obligated to retain this data for longer time periods.
      </p>
      <p>
        When Your Personal Data is no longer required by law or rights or obligations by Us or You, We will delete the Personal Data. In most cases, Personal Data will be deleted upon termination or expiry of the agreement between You and the Company or Operator or upon Your written request.
      </p>

      <h3>3.4 - Transfer of Your Personal Data</h3>
      <p>
        Your information, including Personal Data, is processed at the Company’s or Operator's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ from those from Your jurisdiction.
      </p>
      <p>
        Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
      </p>
      <p>
        The Company or Operator will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of Your data and other personal information.
      </p>

      <h3>3.5 - Delete Your Personal Data</h3>
      <p>
        You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.
      </p>
      <p>
        Our Platform may give You the ability to delete certain information about You from within the Platform.
      </p>
      <p>
        You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.
      </p>
      <p>
        Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.
      </p>


      <h3>3.6 - Disclosure of Your Personal Data</h3>
      <h4 className={css.headingStyle}>3.6.1 - Business Transactions</h4>
      <p>
        If the Company or Operator is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
      </p>

      <h4 className={css.headingStyle}>3.6.2 - Law enforcement</h4>
      <p>
        Under certain circumstances, the Company or Operator may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
      </p>

      <h4 className={css.headingStyle}>3.6.3 - Other legal requirements</h4>
      <p>
        The Company or Operator may disclose Your Personal Data in the good faith belief that such action is necessary to:
      </p>
      <ul className={css.unorderedList}>
        <li className={css.liStyles}>Comply with a legal obligation</li>
        <li className={css.liStyles}>Protect and defend the rights or property of the Company or Operator</li>
        <li className={css.liStyles}>Prevent or investigate possible wrongdoing in connection with the Platform</li>
        <li className={css.liStyles}>Protect the personal safety of Users of the Platform or the public</li>
        <li className={css.liStyles}>Protect against legal liability</li>
      </ul>

      <h3>3.7 - Security of Your Personal Data</h3>
      <p>
        The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
      </p>

      <h2>4 - Detailed Information on the Processing of Your Personal Data</h2>
      <p>
        The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store, use, process and/or transfer information about Your activity on Our Platform in accordance with their Privacy Policies.
      </p>

      <h3>4.1 - Analytics</h3>
      <p>
        We may use third-party Service providers to monitor and analyse the use of our Platform. They may include, but are not limited to:
      </p>

      <ul className={css.unorderedList}>
        <li className={css.liStyles}><b>Google Analytics</b><br/>
          Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Platform. This data is shared with other Google services. You can opt-out of having made your activity on the Platform available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.<br/>
          You may opt-out of certain Google Analytics features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a> <br/>
          For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a></li>
        <li className={css.liStyles}><b>Lemonado</b> <br />
          Lemonado is a web analytics service. You can visit their Privacy Policy page here: <a href="https://lemonado.io/privacy" target="_blank">https://lemonado.io/privacy</a></li>
      </ul>

      <h3>4.2 - Email Marketing</h3>
      <p>
        We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other information that may be of interest to You. You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any email We send or by contacting Us.
      </p>
      <p>
        We may use Email Marketing Service Providers to manage and send emails to You. They may include, but are not limited to:
      </p>
      <ul className={css.unorderedList}>
        <li className={css.liStyles}><b>Mailchimp</b><br />
          Mailchimp is an email marketing sending service provided by The Rocket Science Group LLC. <br />
          For more information on the privacy practices of Mailchimp, please visit their Privacy policy: <a href="https://mailchimp.com/legal/privacy/" target="_blank">https://mailchimp.com/legal/privacy/</a>
        </li>
      </ul>

      <h3>4.3 - Payments</h3>
      <p>
        We may provide paid products and/or services within the Platform. In that case, we may use third-party services for payment processing (e.g. payment processors).
      </p>
      <p>
        We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa and Mastercard. PCI-DSS requirements help ensure the secure handling of payment information.
      </p>
      <p>They may include, but are not limited to:</p>
      <ul className={css.unorderedList}>
        <li className={css.liStyles}>
        <b>Paystack</b> <br/>
          Their Privacy Policy can be viewed at <a href="https://paystack.com/ke/terms?q=/terms#privacy" target="_blank">https://paystack.com/ke/terms?q=/terms#privacy</a>
        </li>
      </ul>

      <h3>4.4 - Behavioral Remarketing</h3>
      <p>
        The Company or Operator uses remarketing services to advertise to You after You accessed or visited our Platform. We and Our third-party vendors use cookies and non-cookie technologies to help Us recognize Your Device and understand how You use our Platform so that We can improve our Platform to reflect Your interests and serve You advertisements that are likely to be of more interest to You.
      </p>
      <p>
        These third-party vendors collect, store, use, process and transfer information about Your activity on Our Platform in accordance with their Privacy Policies and to enable Us to:
      </p>
      <ul className={css.unorderedList}>
        <li className={css.liStyles}>Measure and analyse traffic and browsing activity on Our Platform</li>
        <li className={css.liStyles}>Show advertisements for our products and/or services to You on third-party websites or apps</li>
        <li className={css.liStyles}>Measure and analyse the performance of Our advertising campaigns</li>
      </ul>

      <p>
        Some of these third-party vendors may use non-cookie technologies that may not be impacted by browser settings that block cookies. Your browser may not permit You to block such technologies. You can use the following third-party tools to decline the collection and use of information for the purpose of serving You interest-based advertising:
      </p>

      <ul className={css.unorderedList}>
        <li className={css.liStyles}>The NAI's opt-out platform: <a href="http://www.networkadvertising.org/choices/" target="_blank">http://www.networkadvertising.org/choices/</a></li>
        <li className={css.liStyles}>The EDAA's opt-out platform <a href="http://www.youronlinechoices.com/" target="_blank">http://www.youronlinechoices.com/</a></li>
        <li className={css.liStyles}>The DAA's opt-out platform: <a href="http://optout.aboutads.info/?c=2&lang=EN" target="_blank">http://optout.aboutads.info/?c=2&lang=EN</a></li>
      </ul>

      <p>
        You may opt-out of all personalised advertising by enabling privacy features on Your mobile device such as Limit Ad Tracking (iOS) and Opt Out of Ads Personalization (Android). See Your mobile device Help system for more information.
      </p>

      <p>
        We may share information, such as hashed email addresses (if available) or other online identifiers collected on Our Platform with these third-party vendors. This allows Our third-party vendors to recognize and deliver You ads across devices and browsers. To read more about the technologies used by these third-party vendors and their cross-device capabilities please refer to the Privacy Policy of each vendor listed below.
      </p>

      <p>The third-party vendors We may use are, but are not limited to:</p>
      <ul className={css.unorderedList}>
        <li className={css.liStyles}><b>Google Ads (AdWords)</b> <br />
          Google Ads (AdWords) remarketing service is provided by Google Inc. <br />
          You can opt-out of Google Analytics for Display Advertising and customise the Google Display Network ads by visiting the Google Ads Settings page: <a href="http://www.google.com/settings/ads" target="_blank">http://www.google.com/settings/ads</a><br/>
          Google also recommends installing the Google Analytics Opt-out Browser Add-on - <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a> - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics. <br />
          For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>
        </li>
        <li className={css.liStyles}><b>Facebook / Meta</b> <br />
          Facebook or Meta remarketing service is provided by Facebook Inc. and Meta Inc.
          You can learn more about interest-based advertising from Facebook by visiting this page: <a href="https://www.facebook.com/help/516147308587266" target="_blank">https://www.facebook.com/help/516147308587266</a> <br />
          To opt-out from Facebook's interest-based ads, follow these instructions from Facebook: <a href="https://www.facebook.com/help/568137493302217" target="_blank">https://www.facebook.com/help/568137493302217</a><br />
          Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA
          <a href="http://www.aboutads.info/choices/" target="_blank">http://www.aboutads.info/choices/</a>, the Digital Advertising Alliance of Canada in Canada <a href="http://youradchoices.ca/" target="_blank">http://youradchoices.ca/</a> or the European Interactive Digital Advertising Alliance in Europe <a href="http://www.youronlinechoices.eu/" target="_blank">http://www.youronlinechoices.eu/</a>, or opt-out using your mobile device settings. <br />
          For more information on the privacy practices of Facebook, please visit Facebook's Data Policy: <a href="https://www.facebook.com/privacy/explanation" target="_blank">https://www.facebook.com/privacy/explanation</a>
        </li>
      </ul>

      <h3>4.5 - Usage, Performance and Miscellaneous</h3>
      <p>
        We may use third-party Service Providers to provide better improvement of our Platform. They may include, but are not limited to:
      </p>
      <ul className={css.unorderedList}>
        <li className={css.liStyles}><b>Intercom</b> <br />
        Their Privacy Policy can be viewed at <a href="https://www.intercom.com/legal/privacy" target="_blank">https://www.intercom.com/legal/privacy</a></li>
        <li className={css.liStyles}><b>Facebook Messenger</b> <br />
        Their Privacy Policy can be viewed at <a href="https://www.facebook.com/privacy/policy" target="_blank">https://www.facebook.com/privacy/policy</a></li>
        <li className={css.liStyles}><b>Zendesk</b> <br />
        Their Privacy Policy can be viewed at <a href="https://www.zendesk.com/company/agreements-and-terms/privacy-notice/" target="_blank">https://www.zendesk.com/company/agreements-and-terms/privacy-notice/</a></li>
        <li className={css.liStyles}><b>Tawk</b> <br />
        Their Privacy Policy can be viewed at <a href="https://www.tawk.to/privacy-policy/" target="_blank">https://www.tawk.to/privacy-policy/</a></li>
      </ul>


      <h2>5 - Links to Other Websites</h2>
      <p>
        Our Platform may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
      </p>

      <h2>6 - Children's and Minor Users Privacy Rights</h2>
      <h3>6.1 - General Privacy Rights for Children</h3>
      <p>
        Our Platform does not address anyone under the age of 18. We do not knowingly collect personally identifiable information from anyone under the age of 18. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 18 without verification of parental consent, We take steps to remove that information from Our servers.
      </p>
      <p>
        If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
      </p>

      <h2>7 - Changes to this Privacy Policy</h2>
      <p>
        We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        We will let You know via email and/or a prominent notice on Our Platform, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
      </p>

    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
