import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { TRANSITIONS } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import { addOwnEntities } from '../../containers/ManageListingsPage/ManageListingsPage.duck';

const sortedTransactions = (txs) =>
  reverse(
    sortBy(txs, (tx) => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/VendorDashboard/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/VendorDashboard/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/VendorDashboard/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_PRIVATE_CUSTOMERS_REQUEST =
  'app/VendorDashboard/FETCH_PRIVATE_CUSTOMERS_REQUEST';
export const FETCH_PRIVATE_CUSTOMERS_SUCCESS =
  'app/VendorDashboard/FETCH_PRIVATE_CUSTOMERS_SUCCESS';
export const FETCH_PRIVATE_CUSTOMERS_ERROR = 'app/VendorDashboard/FETCH_PRIVATE_CUSTOMERS_ERROR';

export const FETCH_ALL_PRIVATE_CUSTOMERS_REQUEST =
  'app/VendorDashboard/FETCH_ALL_PRIVATE_CUSTOMERS_REQUEST';
export const FETCH_ALL_PRIVATE_CUSTOMERS_SUCCESS =
  'app/VendorDashboard/FETCH_ALL_PRIVATE_CUSTOMERS_SUCCESS';
export const FETCH_ALL_PRIVATE_CUSTOMERS_ERROR =
  'app/VendorDashboard/FETCH_ALL_PRIVATE_CUSTOMERS_ERROR';

export const FETCH_LISTINGS_REQUEST = 'app/VendorDashboard/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/VendorDashboard/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/VendorDashboard/FETCH_LISTINGS_ERROR';

export const FETCH_PRIVATE_BOOKINGS_REQUEST = 'app/VendorDashboard/PRIVATE_BOOKINGS_REQUEST';
export const FETCH_PRIVATE_BOOKINGS_SUCCESS = 'app/VendorDashboard/PRIVATE_BOOKINGS_SUCCESS';
export const FETCH_PRIVATE_BOOKINGS_ERROR = 'app/VendorDashboard/PRIVATE_BOOKINGS_ERROR';

export const FETCH_ALL_BOOKINGS_REQUEST = 'app/VendorDashboard/ALL_BOOKINGS_REQUEST';
export const FETCH_ALL_BOOKINGS_SUCCESS = 'app/VendorDashboard/ALL_BOOKINGS_SUCCESS';
export const FETCH_ALL_BOOKINGS_ERROR = 'app/VendorDashboard/ALL_BOOKINGS_ERROR';

export const FETCH_VENUES_REQUEST = 'app/VendorDashboard/FETCH_VENUES_REQUEST';
export const FETCH_VENUES_SUCCESS = 'app/VendorDashboard/FETCH_VENUES_SUCCESS';
export const FETCH_VENUES_ERROR = 'app/VendorDashboard/FETCH_VENUES_ERROR';
export const CLEAR_VENUE_SEARCH = 'app/VendorDashboard/CLEAR_VENUE_SEARCH';
export const FETCH_VENUE_BY_NAME_SUCCESS = 'app/VendorDashboard/FETCH_VENUE_BY_NAME_SUCCESS';
// ================ Reducer ================ //

const entityRefs = (entities) =>
  entities.map((entity) => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  fetchPrivateCustomersRequest: false,
  fetchPrivateCustomersSuccess: false,
  privateCustomers: [],
  fetchPrivateCustomersError: null,
  fetchAllPrivateCustomersRequest: false,
  fetchAllPrivateCustomersSuccess: false,
  allPrivateCustomers: [],
  fetchAllPrivateCustomersError: null,
  queryInProgress: { state: false, venueNames: [] },
  queryListingsSuccess: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownListingPagination: null,
  venueRequest: false,
  venueError: null,
  venueSuccess: false,
  venues: [],
  venuePagination: null,
  allBookings: [],
  allBookingsRequest: false,
  allBookingsError: null,
  allBookingsSuccess: false,
  privateBookings: [],
  privateBookingsRequest: false,
  privateBookingsError: null,
  privateBookingsSuccess: false,
  privateBookingPagination: null,
};
export default function VendorDashboardPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_VENUES_REQUEST:
      return {
        ...state,
        venueRequest: true,
        // venueSuccess: false,
        venueError: null,
      };
    case CLEAR_VENUE_SEARCH:
      return {
        ...state,
        venues: [],
      };
    case FETCH_VENUE_BY_NAME_SUCCESS:
      return {
        ...state,
        vendorRequest: false,
        venueSuccess: true,
        venues: [payload],
      };
    case FETCH_VENUES_SUCCESS:
      return {
        ...state,
        venueRequest: false,
        venueSuccess: true,
        venues: [
          ...state.venues,
          ...payload.venues.filter((v) => !state.venues.map((f) => f._id).includes(v._id)),
        ],
        venuePagination: { ...payload.pagination },
        venueError: null,
      };
    case FETCH_VENUES_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        venueRequest: false,
        venueError: payload,
        venueSuccess: false,
        venues: [],
        venuePagination: null,
      };
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryInProgress: { state: true, venueNames: payload.payload },
        queryListingsError: null,
        // currentPageResultIds: [],
        queryListingsSuccess: false,
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: [
          ...state.currentPageResultIds,
          ...payload.filter(
            (f) => !state.currentPageResultIds.map((i) => i.venueName).includes(f.venueName)
          ),
        ],
        queryInProgress: { state: false, venueNames: [] },
        queryListingsSuccess: true,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        queryInProgress: { state: false, venueNames: [] },
        queryListingsError: payload,
        queryListingsSuccess: false,
      };
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case FETCH_PRIVATE_CUSTOMERS_REQUEST:
      return {
        ...state,
        fetchPrivateCustomersRequest: true,
        fetchPrivateCustomersError: null,
        fetchPrivateCustomersSuccess: false,
      };

    case FETCH_PRIVATE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        fetchPrivateCustomersSuccess: true,
        privateCustomers: payload,
        fetchPrivateCustomersRequest: false,
      };

    case FETCH_PRIVATE_CUSTOMERS_ERROR:
      return {
        ...state,
        fetchPrivateCustomersError: payload,
        fetchPrivateCustomersRequest: false,
        fetchPrivateCustomersSuccess: false,
        privateCustomers: [],
      };

    case FETCH_ALL_PRIVATE_CUSTOMERS_REQUEST:
      return {
        ...state,
        fetchAllPrivateCustomersRequest: true,
        fetchAllPrivateCustomersError: null,
        fetchAllPrivateCustomersSuccess: false,
      };

    case FETCH_ALL_PRIVATE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        fetchAllPrivateCustomersSuccess: true,
        allPrivateCustomers: payload,
        fetchAllPrivateCustomersRequest: false,
      };

    case FETCH_ALL_PRIVATE_CUSTOMERS_ERROR:
      return {
        ...state,
        fetchAllPrivateCustomersError: payload,
        fetchAllPrivateCustomersRequest: false,
        fetchAllPrivateCustomersSuccess: false,
        allPrivateCustomers: [],
      };
    case FETCH_ALL_BOOKINGS_REQUEST:
      return {
        ...state,
        allBookingsRequest: true,
        allBookingsError: null,
        allBookingsSuccess: false,
      };

    case FETCH_ALL_BOOKINGS_SUCCESS:
      return {
        ...state,
        allBookingsSuccess: true,
        allBookings: payload?.data,
        allBookingsRequest: false,
        allBookingPagination: payload?.metadata,
      };

    case FETCH_ALL_BOOKINGS_ERROR:
      return {
        ...state,
        allBookingsError: payload,
        allBookingsRequest: false,
        allBookingsSuccess: false,
        allBookings: [],
      };

    case FETCH_PRIVATE_BOOKINGS_REQUEST:
      return {
        ...state,
        privateBookingsRequest: true,
        privateBookingsError: null,
        privateBookingsSuccess: false,
        privateBookings: [],
      };

    case FETCH_PRIVATE_BOOKINGS_SUCCESS:
      return {
        ...state,
        privateBookingsSuccess: true,
        privateBookings: payload?.data?.data,
        privateBookingsRequest: false,
        privateBookingsError: null,
        privateBookingPagination: payload?.data?.metadata,
      };

    case FETCH_PRIVATE_BOOKINGS_ERROR:
      return {
        ...state,
        privateBookingsError: payload,
        privateBookingsRequest: false,
        privateBookingsSuccess: false,
        // privateBookings: [],
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = (response) => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = (e) => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

export const fetchPrivateCustomersRequest = () => ({ type: FETCH_PRIVATE_CUSTOMERS_REQUEST });
const fetchPrivateCustomersSuccess = (response) => ({
  type: FETCH_PRIVATE_CUSTOMERS_SUCCESS,
  payload: response,
});
const fetchPrivateCustomersError = (e) => ({
  type: FETCH_PRIVATE_CUSTOMERS_ERROR,
  error: true,
  payload: e,
});

const fetchAllPrivateCustomersRequest = () => ({ type: FETCH_ALL_PRIVATE_CUSTOMERS_REQUEST });
const fetchAllPrivateCustomersSuccess = (response) => ({
  type: FETCH_ALL_PRIVATE_CUSTOMERS_SUCCESS,
  payload: response,
});
const fetchAllPrivateCustomersError = (e) => ({
  type: FETCH_ALL_PRIVATE_CUSTOMERS_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = (payload) => {
  return {
    type: FETCH_LISTINGS_REQUEST,
    payload: { payload },
  };
};

export const queryListingsSuccess = (response) => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});

export const queryListingsError = (e) => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});
export const queryVenuesRequest = (queryParams) => ({
  type: FETCH_VENUES_REQUEST,
  payload: { queryParams },
});

export const queryVenuesSuccess = (response) => ({
  type: FETCH_VENUES_SUCCESS,
  payload: response.data[0],
});

export const queryVenuesError = (e) => ({
  type: FETCH_VENUES_ERROR,
  error: true,
  payload: e,
});
export const clearVenueSearch = (response) => ({
  type: CLEAR_VENUE_SEARCH,
});
export const fetchVenueByNameSuccess = (response) => ({
  type: FETCH_VENUE_BY_NAME_SUCCESS,
  payload: response.data,
});
export const fetchallBookingsRequest = () => ({
  type: FETCH_ALL_BOOKINGS_REQUEST,
});

export const fetchallBookingsSuccess = (res) => ({
  type: FETCH_ALL_BOOKINGS_SUCCESS,
  payload: res,
});

export const fetchallBookingsError = (e) => ({
  type: FETCH_ALL_BOOKINGS_ERROR,
  payload: e,
  error: true,
});
export const fetchprivateBookingsRequest = () => ({
  type: FETCH_PRIVATE_BOOKINGS_REQUEST,
});

export const fetchprivateBookingsSuccess = (res) => ({
  type: FETCH_PRIVATE_BOOKINGS_SUCCESS,
  payload: { data: res },
});

export const fetchprivateBookingsError = (e) => ({
  type: FETCH_PRIVATE_BOOKINGS_ERROR,
  payload: e,
  error: true,
});

// ================ Thunks ================ //

const PAGE_SIZE = 10;

export const clearVenueSearchResults = () => async (dispatch, getState, sdk) => {
  dispatch(clearVenueSearch());
};

export const venueSearchById = (params) => async (dispatch, getState, sdk) => {
  const { venueId } = params;

  dispatch(queryVenuesRequest());
  const resp = await axios
    .post(
      `${apiBaseUrl()}/api/venue-by-id`,
      {
        venueId,
      },
      { withCredentials: true }
    )
    .then((res) => {
      dispatch(fetchVenueByNameSuccess(res));
      dispatch(
        queryOwnListings({
          page: 1,
          venueNames: [res.data.venueName],
        })
      );
    })
    .catch((e) => {
      dispatch(queryVenuesError(storableError(e)));
    });
};
export const fetchPrivateCustomer = (param) => (dispatch, getState, sdk) => {
  const { privateCustomerParams } = param;
  const {
    firstName = '',
    lastName = '',
    email = '',
    phoneNumber = '',
    createdAt = '',
    page,
  } = privateCustomerParams;
  return axios
    .get(
      `${apiBaseUrl()}/api/private-customer?firstName=${firstName}&lastName=${lastName}&email=${email}&phoneNumber=${phoneNumber}&createdAt=${createdAt}&page=${page}`,
      {
        withCredentials: true,
      }
    )
    .then((response) => {
      dispatch(fetchPrivateCustomersSuccess(response.data));
    })
    .catch((error) => {
      dispatch(fetchOrdersOrSalesError(error));
    });
};

export const fetchAllPrivateCustomer = (param) => (dispatch, getState, sdk) => {
  dispatch(fetchAllPrivateCustomersRequest());
  return axios
    .get(`${apiBaseUrl()}/api/private-customers`, {
      withCredentials: true,
    })
    .then((response) => {
      dispatch(fetchAllPrivateCustomersSuccess(response.data));
    })
    .catch((error) => {
      dispatch(fetchOrdersOrSalesError(error));
    });
};

export const fetchPrivateBookings = (params) => (dispatch, getState, sdk) => {
  const { privateBookingParams } = params;
  const {
    page = 1,
    bookingType = '',
    date = 1,
    extraFacilities = '',
    venue = '',
  } = privateBookingParams;

  dispatch(fetchprivateBookingsRequest());
  return axios
    .get(
      `${apiBaseUrl()}/api/private/getPrivateBookings?page=${page}&bookingType=${bookingType}&date=${date}&extraFacilities=${extraFacilities}&venue=${venue}`,
      // { finalBody },
      { withCredentials: true }
    )
    .then((res) => {
      dispatch(fetchprivateBookingsSuccess(res?.data));
    })
    .catch((error) => {
      dispatch(fetchprivateBookingsError(error));
      console.log(error?.message);
    });
};

export const fetchAllBookings = (params) => (dispatch, getState, sdk) => {
  const { allBookingsParams } = params;
  // const {
  //   page = 1,
  //   venue = '',
  //   startDate = '',
  //   listingType = '',
  //   title = '',
  // } = allBookingParams;

  dispatch(fetchallBookingsRequest());
  return axios
    .post(
      `${apiBaseUrl()}/api/transactionsDataByVendorId`,
      allBookingsParams,
      // { finalBody },
      { withCredentials: true }
    )
    .then((res) => {
      dispatch(fetchallBookingsSuccess(res?.data));
    })
    .catch((error) => {
      dispatch(fetchallBookingsError(error));
      console.log(error?.message);
    });
};

export const currentUserVenues = (params) => async (dispatch, getState, sdk) => {
  const currentUserResp = await sdk.currentUser.show();
  const userId = currentUserResp.data.data.id.uuid;
  const { page } = params;

  dispatch(queryVenuesRequest({ userId }));
  const resp = await axios
    .post(
      `${apiBaseUrl()}/api/venue-currentUser`,
      {
        userId,
        page,
      },
      { withCredentials: true }
    )
    .then((res) => {
      if (res.data?.length > 0) {
        dispatch(queryVenuesSuccess(res));
        dispatch(
          queryOwnListings({
            page: 1,
            venueNames: [
              ...res.data[0].venues?.map((d) => d.venueName),
              // ...currentVenues.map((d) => d.venueName),
            ],
          })
        );
      } else {
        dispatch(
          queryVenuesSuccess({
            data: [
              {
                pagination: {},
                venues: [],
              },
            ],
          })
        );
      }
      // const venues = res.data?.length > 0 &&
    })
    .catch((e) => {
      dispatch(queryVenuesError(storableError(e)));
    });
};
export const queryOwnListings = (queryParams) => async (dispatch, getState, sdk) => {
  const currentUserResp = await sdk.currentUser.show({ expand: true });
  const userId = currentUserResp.data.data.id.uuid;
  const { perPage, venueNames, ...rest } = queryParams;
  dispatch(queryListingsRequest(venueNames));

  const params = {
    ...rest,
    per_page: perPage,
    include: ['images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  };
  const listing = venueNames.map(
    (name) =>
      new Promise((resolve, reject) => {
        sdk.listings
          .query({ ...params, pub_venueName: name, authorId: userId })
          .then((resp) => resolve(resp))
          .catch((err) => reject(err));
      })
  );
  return Promise.all(listing)
    .then((res) => {
      const trueArr = res.filter((r) => r.data.data?.length > 0);
      trueArr.map((r) => {
        dispatch(addMarketplaceEntities(r));
      });
      dispatch(
        queryListingsSuccess(
          trueArr.map((r) => ({
            venueName: r.data.data[0].attributes.publicData.venueName,
            listings: r.data.data,
          }))
        )
      );
    })
    .catch((e) => {
      dispatch(queryListingsError(storableError(e)));
    });
  // return sdk.ownListings
  //   .query(params)
  //   .then((response) => {
  //     dispatch(addOwnEntities(response));
  //     dispatch(queryListingsSuccess(response));
  //     return response;
  //   })
  //   .catch((e) => {
  //     dispatch(queryListingsError(storableError(e)));
  //     throw e;
  //   });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab, type } = params;
  const {
    page = 1,
    type: searchType,
    firstName,
    lastName,
    email,
    phoneNumber,
    createdAt,
    actionType,
    listing,
    id,
    bookingType,
    date,
    extraFacilities,
    venue,
    startDate,
    listingType,
    title,
  } = parse(search);
  const privateCustomerParams = { firstName, lastName, email, phoneNumber, createdAt, page };
  const privateBookingParams = { page, bookingType, date, extraFacilities, venue };
  const allBookingsParams = { page, venue, startDate, listingType, title };
  const onlyFilterValues = {
    dashboard: 'dashboard',
    calendar: 'calendar',
    booking: 'booking',
    listings: 'listings',
    customers: 'customers',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for VendorDashboard: ${tab}`));
  }
  if (
    tab === 'listings' &&
    searchType === 'allListings'
    // &&
    // getState().VendorDashBoardPage.venues?.length === 0 &&
    // !parse(search).page
  ) {
    return dispatch(currentUserVenues({ page }));
  }
  if (tab === 'customers' && type === 'list') {
    dispatch(fetchPrivateCustomersRequest());
    return dispatch(fetchPrivateCustomer({ privateCustomerParams }));
  }

  if (tab === 'booking' && actionType === 'all') {
    return dispatch(fetchAllBookings({ allBookingsParams }));
  }

  if (tab === 'booking' && actionType === 'private') {
    return dispatch(fetchPrivateBookings({ privateBookingParams }));
  }
  dispatch(fetchOrdersOrSalesRequest());

  const apiQueryParams = {
    only: 'order', //'sale',
    lastTransitions: TRANSITIONS,
    include: [
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
      'listing',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then((response) => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch((e) => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
