import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { ListingCard, NamedLink, PrimaryButton } from '../../components';

import Slider from 'react-slick';

import css from './SectionLocations.module.css';

import nyImage from './images/ny-yogi.jpg';
import laImage from './images/la-yogi.jpg';
import sfImage from './images/sf-yogi.jpg';

import { AiOutlineHeart, AiFillStar } from 'react-icons/ai';
import { apiBaseUrl } from '../../util/api';
import axios from 'axios';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        />
      </div>
    </NamedLink>
  );
};

const SectionLocations = (props) => {
  const { rootClassName, className, listings, history, location, currentUser } = props;
  const classes = classNames(rootClassName || css.root, className);

  var settings = {
    dots: false,
    infinite: listings.length >= 5,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    draggable: false,
    swipe: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          draggable: true,
          swipe: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          draggable: true,
          swipe: true,
        },
      },
    ],
  };

  //fav functionality
  const [currentUserFavourites, setCurrentUserFavourites] = useState(null);

  const getCurrentUserFavourites = async (userId) => {
    const currentUserFavourites = await axios.post(
      `${apiBaseUrl()}/api/currentuser-favourite-listings`,
      { userId },
      {
        withCredentials: true,
      }
    );
    return currentUserFavourites;
  };

  useEffect(() => {
    !!currentUser &&
      getCurrentUserFavourites(currentUser.id.uuid)
        .then((res) => {
          setCurrentUserFavourites(res.data);
        })
        .catch((err) => console.error(err));
  }, [currentUser]);

  const toggleFavouritesButton = async (userId, listingId) => {
    const isFavouriteListing = await axios.post(
      `${apiBaseUrl()}/api/toggle-favourites`,
      {
        userId,
        listingId,
      },
      {
        withCredentials: true,
      }
    );

    if (isFavouriteListing?.data) {
      setCurrentUserFavourites((state) => [...state, listingId]);
    } else {
      setCurrentUserFavourites((state) => state.filter((id) => id !== listingId));
    }

    return isFavouriteListing;
  };
  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  return (
    <div className={classes}>
      <div className={css.title}>
        You might <span>like...</span>
        {/* <FormattedMessage id="SectionLocations.title" /> */}
      </div>
      <div className={css.locations}>
        <div className={`${css.filteredSearches} filteredSearches`}>
          <Slider {...settings}>
            {listings?.length > 0 &&
              listings?.map((l, indx) => {
                {
                  return (
                    <ListingCard
                      className={classNames(css.listingCard, {
                        [css.noCursor]:
                          location.pathName === undefined || location.pathName === '/',
                      })}
                      key={l.id.uuid}
                      listing={l}
                      renderSizes={cardRenderSizes}
                      // setActiveListing={setActiveListing}

                      currentUser={currentUser}
                      toggleFavouritesButton={toggleFavouritesButton}
                      isFavourite={
                        _.isArray(currentUserFavourites) &&
                        currentUserFavourites?.includes(l.id.uuid)
                      }
                      history={history}
                      location={location}
                    />
                  );
                }
              })}
          </Slider>
        </div>
      </div>

      <button
        className={css.btngbl}
        onClick={() =>
          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}))
        }
      >
        See All
      </button>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
{
  /* <Slider {...settings}>
            <div className={css.searchLink}>
              <div className={css.imagewrap}>
                <img src={nyImage} alt="" />
                <div className={css.favicon}>
                  <AiOutlineHeart />
                </div>
              </div>
              <div className={css.cardText}>
                <h2>
                  <div className={css.dotttl}> 5-A-Side Football</div>

                  <span>
                    5.0 <AiFillStar />
                  </span>
                </h2>
                <span className={css.tagscont}>Fitness Class</span>
                <div className={css.srttxt}>
                  <p>
                    Jaffery Sports Club
                    <span>Lavington, Nairobi</span>
                  </p>
                </div>
                <div className={css.listDiv}>
                  <span className={css.listsText}>Football</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>Beginner Level</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>Women Only</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>1 Hour</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>5 a side</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>3G Astroturf</span>
                </div>

                <div className={css.pricetxt}>
                  $200 <span>/person</span>
                </div>

                <hr />

                <div className={css.bookingDiv}>
                  <button className={css.bookingBtn}>
                    <p>Sat 25th - 3:00pm</p>
                    <span>05 spots left</span>
                  </button>
                  <button className={css.bookingBtn}>
                    <p>Sat 25th - 3:00pm</p>
                    <span>05 spots left</span>
                  </button>
                  <button className={css.bookingBtn}>
                    <p>Sat 25th - 3:00pm</p>
                    <span>05 spots left</span>
                  </button>
                </div>
              </div>
            </div>

            <div className={css.searchLink}>
              <div className={css.imagewrap}>
                <img src={laImage} alt="" />
                <div className={css.favicon}>
                  <AiOutlineHeart />
                </div>
              </div>
              <div className={css.cardText}>
                <h2>
                  <div className={css.dotttl}> 5-A-Side Football</div>

                  <span>
                    5.0 <AiFillStar />
                  </span>
                </h2>

                <span className={css.tagscont}>Fitness Class</span>
                <div className={css.srttxt}>
                  <p>
                    Jaffery Sports Club
                    <span>Lavington, Nairobi</span>
                  </p>
                </div>
                <div className={css.listDiv}>
                  <span className={css.listsText}>Football</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>Beginner Level</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>Women Only</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>1 Hour</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>5 a side</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>3G Astroturf</span>
                </div>
                <div className={css.pricetxt}>
                  $200 <span>/person</span>
                </div>

                <hr />
                <div className={css.bookingDiv}>
                  <button className={css.bookingBtn}>
                    <p>Sat 25th - 3:00pm</p>
                    <span>05 spots left</span>
                  </button>
                  <button className={css.bookingBtn}>
                    <p>Sat 25th - 3:00pm</p>
                    <span>05 spots left</span>
                  </button>
                </div>
              </div>
            </div>

            <div className={css.searchLink}>
              <div className={css.imagewrap}>
                <img src={sfImage} alt="" />
                <div className={css.favicon}>
                  <AiOutlineHeart />
                </div>
              </div>
              <div className={css.cardText}>
                <h2>
                  <div className={css.dotttl}> 5-A-Side Football</div>
                  <span>
                    5.0 <AiFillStar />
                  </span>
                </h2>

                <span className={css.tagscont}>Fitness Class</span>
                <div className={css.srttxt}>
                  <p>
                    Jaffery Sports Club
                    <span>Lavington, Nairobi</span>
                  </p>
                </div>
                <div className={css.listDiv}>
                  <span className={css.listsText}>Football</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>Beginner Level</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>Women Only</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>1 Hour</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>5 a side</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>3G Astroturf</span>
                </div>
                <div className={css.pricetxt}>
                  $200 <span>/person</span>
                </div>

                <hr />
                <div className={css.bookingDiv}>
                  <button className={css.bookingBtn}>
                    <p>Sat 25th - 3:00pm</p>
                    <span>05 spots left</span>
                  </button>
                  <button className={css.bookingBtn}>
                    <p>Sat 25th - 3:00pm</p>
                    <span>05 spots left</span>
                  </button>
                </div>
              </div>
            </div>

            <div className={css.searchLink}>
              <div className={css.imagewrap}>
                <img src={laImage} alt="" />
                <div className={css.favicon}>
                  <AiOutlineHeart />
                </div>
              </div>
              <div className={css.cardText}>
                <h2>
                  <div className={css.dotttl}> 5-A-Side Football</div>
                  <span>
                    5.0 <AiFillStar />
                  </span>
                </h2>

                <span className={css.tagscont}>Fitness Class</span>
                <div className={css.srttxt}>
                  <p>
                    Jaffery Sports Club
                    <span>Lavington, Nairobi</span>
                  </p>
                </div>
                <div className={css.listDiv}>
                  <span className={css.listsText}>Football</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>Beginner Level</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>Women Only</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>1 Hour</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>5 a side</span>
                  <span className={css.circleDiv}></span>

                  <span className={css.listsText}>3G Astroturf</span>
                </div>
                <div className={css.pricetxt}>
                  $200 <span>/person</span>
                </div>

                <hr />
                <div className={css.bookingDiv}>
                  <button className={css.bookingBtn}>
                    <p>Sat 25th - 3:00pm</p>
                    <span>05 spots left</span>
                  </button>
                  <button className={css.bookingBtn}>
                    <p>Sat 25th - 3:00pm</p>
                    <span>05 spots left</span>
                  </button>
                </div>
              </div>
            </div>
          </Slider> */
}
