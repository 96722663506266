import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ExternalLink, ListingCard, PaginationLinks } from '../../components';
import css from './SearchResultsPanel.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import { apiBaseUrl } from '../../util/api';
import axios from 'axios';

const SearchResultsPanel = (props) => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    hasNoResult,
    currentUser,
    history,
    urlQueryParams,
    location,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  //favorites

  const [currentUserFavourites, setCurrentUserFavourites] = useState([]);

  const getCurrentUserFavourites = async (userId) => {
    const currentUserFavourites = await axios.post(
      `${apiBaseUrl()}/api/currentuser-favourite-listings`,
      { userId },
      {
        withCredentials: true,
      }
    );
    return currentUserFavourites;
  };

  const toggleFavouritesButton = async (userId, listingId) => {
    const isFavouriteListing = await axios.post(
      `${apiBaseUrl()}/api/toggle-favourites`,
      {
        userId,
        listingId,
      },
      {
        withCredentials: true,
      }
    );
    const data = await getCurrentUserFavourites(currentUser.id.uuid);
    // .then(res => {
    setCurrentUserFavourites(data.data);
    // });
    return isFavouriteListing;
  };

  useEffect(() => {
    !!currentUser &&
      getCurrentUserFavourites(currentUser.id.uuid)
        .then((res) => {
          setCurrentUserFavourites(res.data);
        })
        .catch((err) => console.error(err));
  }, [currentUser]);

  return (
    <div className={classes}>
      {hasNoResult ? (
        <div className={css.noSearchResults}>
          <div>We couldn't find any listings that match your criteria.</div>

          <div>Please adjust your filters and try again.</div>
        </div>
      ) : null}
      <div className={css.listingCards}>
        {listings.map((l) => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes}
            setActiveListing={setActiveListing}
            currentUser={currentUser}
            toggleFavouritesButton={toggleFavouritesButton}
            isFavourite={
              _.isArray(currentUserFavourites) && currentUserFavourites?.includes(l.id.uuid)
            }
            history={history}
            urlQueryParams={search}
            location={location}
          />
        ))}
        {props.children}
      </div>
      <div className={css.nft}>
        Can’t find an activity that suits you?
        <ExternalLink
          className={css.clickHere}
          href={
            'https://docs.google.com/forms/d/e/1FAIpQLSck-QxIwlAw-4AxTGCbvvabPxI-sLhaV-gFmPd77G98EMeuSw/viewform?usp=sf_link'
          }
        >
          Click here
        </ExternalLink>
      </div>
      {paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
