import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import { Card } from 'react-bootstrap';
import heroBannerImg from '../../assets/herobanner.png';
import { ReactComponent as Athletics } from '../../assets/icons/Athletics.svg';
import { ReactComponent as HIIT } from '../../assets/icons/HIIT.svg';
import { ReactComponent as Yoga } from '../../assets/icons/Yoga.svg';
import { ReactComponent as Lens } from '../../assets/icons/Lens.svg';
import css from './SectionHero.module.css';
import LandingPageCard from '../LandingPageCard/LandingPageCard';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const SectionHero = (props) => {
  const { history } = props;
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className } = props;
  const [selectedFilter, setSelectedFilter] = useState('allActivities');
  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.contentWrapper}>
          <div className={css.leftContent}>
            <h1 className={classNames(css.title, css.animation)}>
              <FormattedMessage id="SectionHero.heroContentTitle" />
            </h1>
            {/* <p className={classNames(css.subTitle, css.animation)}>
              <FormattedMessage id="SectionHero.heroContentDescription" />
            </p> */}
          </div>
          <div className={css.rightContent}>
            <img src={heroBannerImg} alt="hero banner" />
          </div>
          <div className={css.searchDiv}>
            <div className={css.filterButtonDiv}>
              <div className={css.buttonRow}>
                <button
                  type="button"
                  className={classNames(css.filtrBtn, {
                    [css.selectedFilterBtn]: selectedFilter === 'allActivities',
                  })}
                  onClick={() => setSelectedFilter('allActivities')}
                >
                  <HIIT />
                  <span>All Activities</span>
                </button>
                <button
                  type="button"
                  className={classNames(css.filtrBtn, {
                    [css.selectedFilterBtn]: selectedFilter === 'sessions',
                  })}
                  onClick={() => setSelectedFilter('sessions')}
                >
                  <Athletics />
                  <span>Sports Sessions</span>
                </button>
                <button
                  type="button"
                  className={classNames(css.filtrBtn, {
                    [css.selectedFilterBtn]: selectedFilter === 'classes',
                  })}
                  onClick={() => setSelectedFilter('classes')}
                >
                  <Yoga />
                  <span>Fitness Classes</span>
                </button>
              </div>

              <div className={css.filterInfoRow}>
                {selectedFilter === 'allActivities' ? (
                  <span>
                    Whether you want to sweat it out in a yoga class or hit the basketball court,
                    there’s something for everyone. Get active and stay active through a variety of
                    sports and fitness activities that suit your schedule.
                  </span>
                ) : selectedFilter === 'sessions' ? (
                  <span>
                    Play organized games of the sports you love with a community of people who share
                    your passion. Whether you're new in town, looking to meet new people, or just
                    craving some competition, we've got you covered.
                  </span>
                ) : selectedFilter === 'classes' ? (
                  <span>
                    Learn from qualified and experienced instructors who will guide you through
                    structured workout classes. Sweat, stretch, relax, or meditate, all while being
                    supported and motivated by a group.
                  </span>
                ) : null}
              </div>
            </div>
            <div className={css.srchButtonDiv}>
              <button
                className={css.srchButton}
                onClick={() => {
                  let params = {};
                  if (selectedFilter === 'sessions') {
                    params = {
                      pub_activity: `sessions`,
                    };
                  }
                  if (selectedFilter === 'classes') {
                    params = {
                      pub_activity: `classes`,
                    };
                  }
                  history.push(
                    createResourceLocatorString('SearchPage', routeConfiguration(), {}, params)
                  );
                }}
              >
                <Lens />
                Search
              </button>
            </div>
          </div>
        </div>

        {/* <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        <NamedLink
          name="SearchPage"
          to={{
            search:
              'address=United%20States%20of%20America&bounds=71.540724%2C-66.885444%2C18.765563%2C-179.9',
          }}
          className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
        >
          <FormattedMessage id="SectionHero.browseButton" />
        </NamedLink> */}

        <LandingPageCard />
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
