import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { array, bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button } from '../../components';
import { BookingTimeForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BookingPanel.module.css';
import { isArray } from 'lodash';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';

const formatTimeToHoursMinute = (minutes) => {
  if (typeof minutes !== 'number' || minutes < 0) {
    return '';
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours === 0) {
    return `${remainingMinutes} minutes`;
  } else if (remainingMinutes === 0) {
    return hours === 1 ? 'hour' : `${hours} 'hours'}`;
  } else {
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ${remainingMinutes} minutes`;
  }
};
const { Money } = sdkTypes;

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const getDayOfWeek = (num) => {
  const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  return days[num];
};

const isDateWithinInterval = (date, interval) => {
  const startHour = parseInt(interval.startTime.split(':')[0]);
  const startMinute = parseInt(interval.startTime.split(':')[1]);
  const endHour = parseInt(interval.endTime.split(':')[0]);
  const endMinute = parseInt(interval.endTime.split(':')[1]);

  const intervalStart = new Date(date);
  intervalStart.setHours(startHour, startMinute, 0, 0);

  const intervalEnd = new Date(date);
  intervalEnd.setHours(endHour, endMinute, 0, 0);

  return date >= intervalStart && date <= intervalEnd;
};
const isPeakMaybe = (listing, startDate) => {
  const { fromMongo, activity } = listing?.attributes?.publicData;
  const { schedule = [] } = fromMongo;
  const dayOfWeek = startDate.getDay();
  if (activity?.[0] !== 'facilityHire') return false;
  const peakTimes =
    activity?.[0] === 'facilityHire'
      ? schedule?.reduce((arr, curr) => {
          const currArr =
            curr.isActive && curr.day === getDayOfWeek(dayOfWeek)
              ? curr.times.filter((f) => f.isPeak)
              : [];
          return currArr?.length > 0 ? [...arr, ...currArr] : arr;
        }, [])
      : [];
  const isPeakTime = peakTimes.some((interval) => {
    return isDateWithinInterval(startDate, interval);
  });
  return isPeakTime;
};
const getPeakPrice = (listing, category) => {
  const { activity, fromMongo } = listing.attributes?.publicData;
  const { sportsInfo = [] } = fromMongo || {};
  if (activity?.[0] !== 'facilityHire') return null;
  const currentSport =
    sportsInfo?.length > 0 && sportsInfo?.find((sport) => sport?.sportName === category);
  const peakPrice = currentSport?.peakTimingPrice;
  if (typeof peakPrice === 'number') {
    return peakPrice;
  } else {
    return null;
  }
};

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const BookingPanel = (props) => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    searchParams,
  } = props;
  const {
    attributes: {
      publicData: { activity, fromMongo },
    },
  } = listing || {};
  const [startDateInfo, setStartDateInfo] = useState(null);
  const [isPeak, setIsPeak] = useState(startDateInfo ? isPeakMaybe(listing, startDateInfo) : null);
  useEffect(() => {
    if (startDateInfo) {
      setIsPeak(isPeakMaybe(listing, startDateInfo));
    }
  }, [startDateInfo]);
  const price = listing.attributes.price;
  const defaultTimeZone = () =>
    typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';
  const timeZone = defaultTimeZone();
  // listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingTimeForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isBook = !!parse(location.search).book;
  const category = parse(location.search).category;

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);
  const peakPrice = getPeakPrice(listing, category);
  const timeIntervalMaybe = formatTimeToHoursMinute(fromMongo?.listingDetails?.timeInterval);
  const durationName =
    fromMongo?.listingDetails?.durationType === 'session'
      ? fromMongo?.listingDetails?.durationName || ''
      : timeIntervalMaybe;
  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
        </div>
        <div className={css.bookingHeading}>
          {isArray(activity) && (activity.includes('classes') || activity.includes('sessions')) && (
            <div className={css.desktopPriceContainer}>
              <div className={css.desktopPriceValue} title={priceTitle}>
                {formattedPrice}
              </div>
              <div className={css.desktopPerUnit}>/ person</div>
            </div>
          )}
          {isArray(activity) &&
            activity.includes('facilityHire') &&
            fromMongo &&
            isArray(fromMongo.sportsInfo) && (
              <div className={css.desktopPriceContainer}>
                <div className={css.desktopPriceValue} title={priceTitle}>
                  {
                    priceData(
                      new Money(
                        isPeak
                          ? peakPrice
                          : fromMongo.sportsInfo.find(({ sportName }) => sportName === category)
                              ?.fullFacilityDurationAndPrice[0]?.fullPrice || 0,
                        config.currency
                      ),
                      intl
                    ).formattedPrice
                  }
                </div>

                <div className={css.desktopPerUnit}>
                  {fromMongo.sportsInfo
                    .find(({ sportName }) => sportName === category)
                    ?.groupBookingInfo?.options.includes('multiPlyChargesByAttendee') ||
                  fromMongo.sportsInfo
                    .find(({ sportName }) => sportName === category)
                    ?.groupBookingInfo?.options?.includes('multiPlyDepositByAttendee')
                    ? `/${durationName}/person`
                    : `/${durationName}`}
                </div>
              </div>
            )}
          <div className={css.bookingHeadingContainer}>
            <h2 className={titleClasses}>{title}</h2>
            {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
          </div>
        </div>
        {showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.submitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listing.id}
            listing={listing}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            searchParams={searchParams}
            setStartDateInfo={setStartDateInfo}
            initialValues={{
              reservationMethod: 'fullPayment',
              // attendee: { quantity: listingDetails?.groupBookingInfo?.minCapacity },
            }}
            category={category}
          />
        ) : null}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>

        {showBookingTimeForm ? (
          <Button
            rootClassName={css.bookButton}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            <FormattedMessage id="BookingPanel.ctaButtonMessage" />
          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
