import React, { useState } from 'react';
import axios from 'axios';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { ListingGeneralInfoForm } from '../../forms';
import { apiBaseUrl } from '../../util/api';
import ToastMessage, { notify } from '../ToastMessage/ToastMessage';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { withRouter } from 'react-router-dom';

function ListingGeneralInfoPanelComponent(props) {
  const [ready, setReady] = useState(false);
  const routes = routeConfiguration();

  const { history, listingId, listing, onCreateListingDraft, onUpdateListing } = props;

  const handleSubmit = (values) => {
    const body = {
      listingType: 'facilityHire',
      step: 1,
      listingName: values.facilityName,
      venueId: values.facilityVenue,
      visibility: values.facilityVisibility,
      facilityFormat: values.facilityFormat,
      facilitySurface: values.facilitySurface,
      facilityCommisionPercentage: values.facilityCommisionPercentage,
    };

    const axiosRequest = () =>
      listingId
        ? axios.patch(
            `${apiBaseUrl()}/api/listing/${listingId}`,
            { ...body, ...{ listingId } },
            {
              withCredentials: true,
            }
          )
        : axios.post(`${apiBaseUrl()}/api/listing`, body, {
            withCredentials: true,
          });

    axiosRequest()
      .then((res) => {
        if (res.data?.message === 'listing created' || res.data?.message === 'listing updated') {
          setReady(true);
          history.push(
            createResourceLocatorString(
              'SuperAdminDashboard',
              routes,
              { tab: 'listings' },
              {
                actionType: 'new',
                listingType: 'facilityHire',
                lTab: 'sportsInfo',
                listing: res.data.id,
              }
            )
          );
        }
      })
      .catch((err) => {
        console.log(116, err.response?.data);
        notify('error', err.response?.data);
      });
  };

  return (
    <React.Fragment>
      <ToastMessage position="bottom-center" />
      <ListingGeneralInfoForm onSubmit={handleSubmit} ready={ready} listing={listing} />
    </React.Fragment>
  );
}

const ListingGeneralInfoPanel = compose(injectIntl, withRouter)(ListingGeneralInfoPanelComponent);

export default ListingGeneralInfoPanel;
