import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../../util/errors';
import { parse } from '../../../util/urlHelpers';
import { TRANSITIONS } from '../../../util/transaction';
import { addMarketplaceEntities } from '../../../ducks/marketplaceData.duck';
import axios from 'axios';
import { apiBaseUrl } from '../../../util/api';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { omit } from 'lodash';
import { searchListings } from '../../../containers/VenueDetail/VenueDetail.duck';

const { UUID } = sdkTypes;

const requestAction = (actionType) => (params) => ({ type: actionType, payload: { params } });

const successAction = (actionType) => (result) => ({ type: actionType, payload: result.data });

const errorAction = (actionType) => (error) => ({ type: actionType, payload: error, error: true });

const sortedTransactions = (txs) =>
  reverse(
    sortBy(txs, (tx) => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

function deleteId(obj, id) {
  const updatedObj = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key].includes(id)) {
      updatedObj[key] = obj[key].filter((value) => value !== id);
    } else {
      updatedObj[key] = obj[key];
    }
  });

  return updatedObj;
}

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/VendorDashboard/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/VendorDashboard/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/VendorDashboard/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_PRIVATE_CUSTOMERS_REQUEST =
  'app/VendorDashboard/FETCH_PRIVATE_CUSTOMERS_REQUEST';
export const FETCH_PRIVATE_CUSTOMERS_SUCCESS =
  'app/VendorDashboard/FETCH_PRIVATE_CUSTOMERS_SUCCESS';
export const FETCH_PRIVATE_CUSTOMERS_ERROR = 'app/VendorDashboard/FETCH_PRIVATE_CUSTOMERS_ERROR';

export const FETCH_ALL_PRIVATE_CUSTOMERS_REQUEST =
  'app/SperAdminDashboard/FETCH_ALL_PRIVATE_CUSTOMERS_REQUEST';
export const FETCH_ALL_PRIVATE_CUSTOMERS_SUCCESS =
  'app/SuperAdminDashboard/FETCH_ALL_PRIVATE_CUSTOMERS_SUCCESS';
export const FETCH_ALL_PRIVATE_CUSTOMERS_ERROR =
  'app/SuperAdminDashboard/FETCH_ALL_PRIVATE_CUSTOMERS_ERROR';

export const FETCH_MARKETPLACE_CUSTOMERS_REQUEST =
  'app/VendorDashboard/FETCH_MARKETPLACE_CUSTOMERS_REQUEST';
export const FETCH_MARKETPLACE_CUSTOMERS_SUCCESS =
  'app/VendorDashboard/FETCH_MARKETPLACE_CUSTOMERS_SUCCESS';
export const FETCH_MARKETPLACE_CUSTOMERS_ERROR =
  'app/VendorDashboard/FETCH_MARKETPLACE_CUSTOMERS_ERROR';

export const FETCH_ALL_BOOKINGS_REQUEST = 'app/VendorDashboard/ALL_BOOKINGS_REQUEST';
export const FETCH_ALL_BOOKINGS_SUCCESS = 'app/VendorDashboard/ALL_BOOKINGS_SUCCESS';
export const FETCH_ALL_BOOKINGS_ERROR = 'app/VendorDashboard/ALL_BOOKINGS_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/EditListingPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/EditListingPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/EditListingPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_IMAGE_ORDER = 'app/EditListingPage/UPDATE_IMAGE_ORDER';

export const REMOVE_LISTING_IMAGE = 'app/EditListingPage/REMOVE_LISTING_IMAGE';

// SDK method: images.upload
export const uploadImage = requestAction(UPLOAD_IMAGE_REQUEST);
export const uploadImageSuccess = successAction(UPLOAD_IMAGE_SUCCESS);
export const uploadImageError = errorAction(UPLOAD_IMAGE_ERROR);

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/ListingPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_LISTINGS_REQUEST = 'app/SuperAdminDashboard/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/SuperAdminDashboard/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/SuperAdminDashboard/FETCH_LISTINGS_ERROR';

export const FETCH_VENUES_REQUEST = 'app/SuperAdminDashboard/FETCH_VENUES_REQUEST';
export const FETCH_VENUES_SUCCESS = 'app/SuperAdminDashboard/FETCH_VENUES_SUCCESS';
export const FETCH_VENUES_ERROR = 'app/SuperAdminDashboard/FETCH_VENUES_ERROR';

export const FETCH_PRIVATE_BOOKINGS_REQUEST = 'app/SuperAdminDashboard/PRIVATE_BOOKINGS_REQUEST';
export const FETCH_PRIVATE_BOOKINGS_SUCCESS = 'app/SuperAdminDashboard/PRIVATE_BOOKINGS_SUCCESS';
export const FETCH_PRIVATE_BOOKINGS_ERROR = 'app/SuperAdminDashboard/PRIVATE_BOOKINGS_ERROR';

export const FETCH_VENUE_BY_NAME_SUCCESS = 'app/SuperAdminDashboard/FETCH_VENUE_BY_NAME_SUCCESS';

export const CLEAR_VENUE_SEARCH = 'app/SuperAdminDashboard/CLEAR_VENUE_SEARCH';

export const RESET_REST_FORM = 'app/SuperAdminDashboard/RESET_REST_FORM';

export const UPDATE_FACILITY_IMAGE = 'app/SuperAdminDashboard/UPDATE_FACILITY_IMAGE';

// ================ Reducer ================ //

const entityRefs = (entities) =>
  entities.map((entity) => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  id: null,
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  fetchPrivateCustomersRequest: false,
  fetchPrivateCustomersSuccess: false,
  privateCustomers: [],
  fetchPrivateCustomersError: null,
  fetchAllPrivateCustomersRequest: false,
  fetchAllPrivateCustomersSuccess: false,
  allPrivateCustomers: [],
  fetchAllPrivateCustomersError: null,
  fetchMarketplaceCustomersRequest: false,
  fetchMarketplaceCustomersSuccess: false,
  marketplaceCustomers: [],
  fetchMarketplaceCustomersError: null,
  images: {},
  imageOrder: [],
  removedImageIds: [],
  showListingError: null,
  queryInProgress: { state: false, venueNames: [] },
  queryListingsSuccess: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownListingPagination: null,
  venueRequest: false,
  venueError: null,
  venueSuccess: false,
  venues: [],
  venuePagination: null,
  restFormValues: {},
  facilityImage: {},
  allBookings: [],
  allBookingsRequest: false,
  allBookingsError: null,
  allBookingsSuccess: false,
  privateBookings: [],
  privateBookingsRequest: false,
  privateBookingsError: null,
  privateBookingsSuccess: false,
  privateBookingPagination: null,
};

export default function SuperAdminDashboardPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_VENUES_REQUEST:
      return {
        ...state,
        venueRequest: true,
        venueSuccess: false,
        venueError: null,
      };
    case CLEAR_VENUE_SEARCH:
      return {
        ...state,
        venues: [],
      };
    case FETCH_VENUE_BY_NAME_SUCCESS:
      return {
        ...state,
        vendorRequest: false,
        venueSuccess: true,
        venues: [payload],
      };
    case FETCH_VENUES_SUCCESS:
      return {
        ...state,
        venueRequest: false,
        venueSuccess: true,
        venues: [
          ...state.venues,
          ...payload.venues.filter((v) => !state.venues.map((f) => f._id).includes(v._id)),
        ],
        venuePagination: { ...payload.pagination },
        venueError: null,
      };
    case FETCH_VENUES_ERROR:
      return {
        ...state,
        venueRequest: false,
        venueError: payload,
        venueSuccess: false,
        venues: [],
        venuePagination: null,
      };
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryInProgress: { state: true, venueNames: payload.payload },
        queryListingsError: null,
        currentPageResultIds: [], // this line is opened to solve a bug,where listing cards were not showing properly
        queryListingsSuccess: false,
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,

        currentPageResultIds: [
          ...state.currentPageResultIds,
          ...payload.filter(
            (f) => !state.currentPageResultIds.map((i) => i.venueName).includes(f.venueName)
          ),
        ],
        queryInProgress: { state: false, venueNames: [] },
        queryListingsSuccess: true,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        queryInProgress: { state: false, venueNames: [] },
        queryListingsError: payload,
        queryListingsSuccess: false,
      };
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case FETCH_PRIVATE_CUSTOMERS_REQUEST:
      return {
        ...state,
        fetchPrivateCustomersRequest: true,
        fetchPrivateCustomersError: null,
        fetchPrivateCustomersSuccess: false,
      };

    case FETCH_PRIVATE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        fetchPrivateCustomersSuccess: true,
        privateCustomers: payload,
        fetchPrivateCustomersRequest: false,
      };

    case FETCH_PRIVATE_CUSTOMERS_ERROR:
      return {
        ...state,
        fetchPrivateCustomersError: payload,
        fetchPrivateCustomersRequest: false,
        fetchPrivateCustomersSuccess: false,
        privateCustomers: [],
      };

    case FETCH_ALL_PRIVATE_CUSTOMERS_REQUEST:
      return {
        ...state,
        fetchAllPrivateCustomersRequest: true,
        fetchAllPrivateCustomersError: null,
        fetchAllPrivateCustomersSuccess: false,
      };

    case FETCH_ALL_PRIVATE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        fetchAllPrivateCustomersSuccess: true,
        allPrivateCustomers: payload,
        fetchAllPrivateCustomersRequest: false,
      };

    case FETCH_ALL_PRIVATE_CUSTOMERS_ERROR:
      return {
        ...state,
        fetchAllPrivateCustomersError: payload,
        fetchAllPrivateCustomersRequest: false,
        fetchAllPrivateCustomersSuccess: false,
        allPrivateCustomers: [],
      };

    case FETCH_MARKETPLACE_CUSTOMERS_REQUEST:
      return {
        ...state,
        fetchMarketplaceCustomersRequest: true,
        fetchMarketplaceCustomersError: null,
        fetchMarketplaceCustomersSuccess: false,
      };

    case FETCH_MARKETPLACE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        fetchMarketplaceCustomersSuccess: true,
        marketplaceCustomers: payload,
        fetchMarketplaceCustomersRequest: false,
      };

    case FETCH_MARKETPLACE_CUSTOMERS_ERROR:
      return {
        ...state,
        fetchMarketplaceCustomersError: payload,
        fetchMarketplaceCustomersRequest: false,
        fetchMarketplaceCustomersSuccess: false,
        marketplaceCustomers: [],
      };

    case FETCH_ALL_BOOKINGS_REQUEST:
      return {
        ...state,
        allBookingsRequest: true,
        allBookingsError: null,
        allBookingsSuccess: false,
      };

    case FETCH_ALL_BOOKINGS_SUCCESS:
      return {
        ...state,
        allBookingsSuccess: true,
        allBookings: payload?.data,
        allBookingsRequest: false,
        allBookingPagination: payload?.metadata,
      };

    case FETCH_ALL_BOOKINGS_ERROR:
      return {
        ...state,
        allBookingsError: payload,
        allBookingsRequest: false,
        allBookingsSuccess: false,
        allBookings: [],
      };

    case FETCH_PRIVATE_BOOKINGS_REQUEST:
      return {
        ...state,
        privateBookingsRequest: true,
        privateBookingsError: null,
        privateBookingsSuccess: false,
        privateBookings: [],
      };

    case FETCH_PRIVATE_BOOKINGS_SUCCESS:
      return {
        ...state,
        privateBookingsSuccess: true,
        privateBookings: payload?.data?.data,
        privateBookingsRequest: false,
        privateBookingsError: null,
        privateBookingPagination: payload?.data?.metadata,
      };

    case FETCH_PRIVATE_BOOKINGS_ERROR:
      return {
        ...state,
        privateBookingsError: payload,
        privateBookingsRequest: false,
        privateBookingsSuccess: false,
        // privateBookings: [],
      };

    case UPLOAD_IMAGE_REQUEST: {
      // payload.params: { id: 'tempId', file }
      const images = {
        ...state.images,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        restFormValues: !!payload.params.restFormValues ? { ...payload.params.restFormValues } : {},
        images,
        imageOrder: state.imageOrder.concat([payload.params.id]),
        uploadImageError: null,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id'}
      const { id, imageId } = payload;
      const file = state.images[id].file;
      const images = {
        ...state.images,
        [id]: { id, imageId, file },
      };

      return { ...state, images };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const imageOrder = state.imageOrder.filter((i) => i !== id);
      const images = omit(state.images, id);
      return { ...state, imageOrder, images, uploadImageError: error };
    }
    case UPDATE_IMAGE_ORDER:
      return { ...state, imageOrder: payload.imageOrder };

    case UPDATE_FACILITY_IMAGE: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id'}
      const key = Object.keys(payload.id)[0];
      const { id } = payload.id[key];

      // Extract facility type and id from payload dynamically
      return {
        ...state,
        facilityImage: {
          ...state.facilityImage,
          [key]: [...(state.facilityImage[key] || []), id],
          // Use computed property names to update the specified facility type array with the new id
        },
      };
    }

    case REMOVE_LISTING_IMAGE: {
      const id = payload.imageId;

      // Only mark the image removed if it hasn't been added to the
      // listing already
      const removedImageIds = state.images[id]
        ? state.removedImageIds
        : state.removedImageIds.concat(id);

      const updatedFacilityImage = deleteId(state.facilityImage, id);

      // Always remove from the draft since it might be a new image to
      // an existing listing.
      const images = omit(state.images, id);
      const imageOrder = state.imageOrder.filter((i) => i !== id);

      return { ...state, images, imageOrder, removedImageIds, facilityImage: updatedFacilityImage };
    }
    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_SUCCESS:
      const listingFacilityImage = payload?.attributes?.publicData?.facilityImage;

      return {
        ...state,
        images: {},
        imageOrder: [],
        facilityImage: { ...state.facilityImage, ...listingFacilityImage },
      };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case RESET_REST_FORM:
      return { ...state, restFormValues: {} };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = (response) => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = (e) => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const fetchPrivateCustomersRequest = () => ({ type: FETCH_PRIVATE_CUSTOMERS_REQUEST });
const fetchPrivateCustomersSuccess = (response) => ({
  type: FETCH_PRIVATE_CUSTOMERS_SUCCESS,
  payload: response,
});
const fetchPrivateCustomersError = (e) => ({
  type: FETCH_PRIVATE_CUSTOMERS_ERROR,
  error: true,
  payload: e,
});

const fetchAllPrivateCustomersRequest = () => ({ type: FETCH_ALL_PRIVATE_CUSTOMERS_REQUEST });
const fetchAllPrivateCustomersSuccess = (response) => ({
  type: FETCH_ALL_PRIVATE_CUSTOMERS_SUCCESS,
  payload: response,
});
const fetchAllPrivateCustomersError = (e) => ({
  type: FETCH_ALL_PRIVATE_CUSTOMERS_ERROR,
  error: true,
  payload: e,
});

const fetchMarketPlaceCustomersRequest = () => ({ type: FETCH_MARKETPLACE_CUSTOMERS_REQUEST });
const fetchMarketPlaceCustomersSuccess = (response) => ({
  type: FETCH_MARKETPLACE_CUSTOMERS_SUCCESS,
  payload: response,
});
const fetchMarketPlaceCustomersError = (e) => ({
  type: FETCH_MARKETPLACE_CUSTOMERS_ERROR,
  error: true,
  payload: e,
});

export const fetchallBookingsRequest = () => ({
  type: FETCH_ALL_BOOKINGS_REQUEST,
});

export const fetchallBookingsSuccess = (res) => ({
  type: FETCH_ALL_BOOKINGS_SUCCESS,
  payload: res,
});

export const fetchallBookingsError = (e) => ({
  type: FETCH_ALL_BOOKINGS_ERROR,
  payload: e,
  error: true,
});

export const fetchprivateBookingsRequest = () => ({
  type: FETCH_PRIVATE_BOOKINGS_REQUEST,
});

export const fetchprivateBookingsSuccess = (res) => ({
  type: FETCH_PRIVATE_BOOKINGS_SUCCESS,
  payload: { data: res },
});

export const fetchprivateBookingsError = (e) => ({
  type: FETCH_PRIVATE_BOOKINGS_ERROR,
  payload: e,
  error: true,
});

export const showListingRequest = (id) => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingSuccess = (data) => ({
  type: SHOW_LISTING_SUCCESS,
  payload: data,
});

export const showListingError = (e) => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = (payload) => {
  return {
    type: FETCH_LISTINGS_REQUEST,
    payload: { payload },
  };
};

export const queryListingsSuccess = (response) => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});

export const queryListingsError = (e) => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});
export const queryVenuesRequest = () => ({
  type: FETCH_VENUES_REQUEST,
  payload: {},
});

export const queryVenuesSuccess = (response) => ({
  type: FETCH_VENUES_SUCCESS,
  payload: response.data[0],
});

export const fetchVenueByNameSuccess = (response) => ({
  type: FETCH_VENUE_BY_NAME_SUCCESS,
  payload: response.data,
});

export const clearVenueSearch = (response) => ({
  type: CLEAR_VENUE_SEARCH,
  // payload: response.data,
});

export const queryVenuesError = (e) => ({
  type: FETCH_VENUES_ERROR,
  error: true,
  payload: e,
});

export const resetRestForm = (e) => ({
  type: RESET_REST_FORM,
});

export const updateFacilityImage = (id) => {
  return {
    type: UPDATE_FACILITY_IMAGE,
    payload: { id },
  };
};
// ================ Thunks ================ //

export const resetRestFormOnSubmit = () => async (dispatch, getState, sdk) => {
  dispatch(resetRestForm());
};

const PAGE_SIZE = 10;

export const clearVenueSearchResults = () => async (dispatch, getState, sdk) => {
  dispatch(clearVenueSearch());
};

export const venueSearchById = (params) => async (dispatch, getState, sdk) => {
  const { venueId } = params;

  dispatch(queryVenuesRequest());
  const resp = await axios
    .post(
      `${apiBaseUrl()}/api/venue-by-id`,
      {
        venueId,
      },
      { withCredentials: true }
    )
    .then((res) => {
      // if (res.data?.length > 0) {
      dispatch(fetchVenueByNameSuccess(res));
      dispatch(
        queryOwnListings({
          page: 1,
          venueNames: [res.data.venueName],
        })
      );
      //
      // const venues = res.data?.length > 0 &&
    })
    .catch((e) => {
      dispatch(queryVenuesError(storableError(e)));
    });
};

export const currentUserVenues = (params) => async (dispatch, getState, sdk) => {
  const { page } = params;

  dispatch(queryVenuesRequest());
  const resp = await axios
    .post(
      `${apiBaseUrl()}/api/venue-super-admin`,
      {
        page,
      },
      { withCredentials: true }
    )
    .then((res) => {
      if (res.data?.length > 0) {
        dispatch(queryVenuesSuccess(res));
        dispatch(
          queryOwnListings({
            page: 1,
            venueNames: [
              ...res.data[0].venues?.map((d) => d.venueName),
              // ...currentVenues.map((d) => d.venueName),
            ],
          })
        );
      }
      // const venues = res.data?.length > 0 &&
    })
    .catch((e) => {
      dispatch(queryVenuesError(storableError(e)));
    });
};
export const queryOwnListings = (queryParams) => async (dispatch, getState, sdk) => {
  const currentUserResp = await sdk.currentUser.show({ expand: true });
  const userId = currentUserResp.data.data.id.uuid;
  const { perPage, venueNames, ...rest } = queryParams;
  dispatch(queryListingsRequest(venueNames));

  const params = {
    ...rest,
    per_page: perPage,
    include: ['images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  };
  const listing = venueNames.map(
    (name) =>
      new Promise((resolve, reject) => {
        sdk.listings
          .query({ ...params, pub_venueName: name })
          .then((resp) => resolve(resp))
          .catch((err) => reject(err));
      })
  );
  return Promise.all(listing)
    .then((res) => {
      const trueArr = res.filter((r) => r.data.data?.length > 0);
      trueArr.map((r) => {
        dispatch(addMarketplaceEntities(r));
      });
      dispatch(
        queryListingsSuccess(
          trueArr.map((r) => ({
            venueName: r.data.data[0].attributes.publicData.venueName,
            listings: r.data.data,
          }))
        )
      );
    })
    .catch((e) => {
      dispatch(queryListingsError(storableError(e)));
    });
  // return sdk.ownListings
  //   .query(params)
  //   .then((response) => {
  //     dispatch(addOwnEntities(response));
  //     dispatch(queryListingsSuccess(response));
  //     return response;
  //   })
  //   .catch((e) => {
  //     dispatch(queryListingsError(storableError(e)));
  //     throw e;
  //   });
};

export const fetchPrivateCustomer = (param) => (dispatch, getState, sdk) => {
  const { customerParams } = param;
  const {
    firstName = '',
    lastName = '',
    email = '',
    phoneNumber = '',
    createdAt = '',
    page,
  } = customerParams;
  return axios
    .get(
      `${apiBaseUrl()}/api/private-customer?firstName=${firstName}&lastName=${lastName}&email=${email}&phoneNumber=${phoneNumber}&createdAt=${createdAt}&page=${page}`,
      {
        withCredentials: true,
      }
    )
    .then((response) => {
      dispatch(fetchPrivateCustomersSuccess(response.data));
    })
    .catch((error) => {
      dispatch(fetchOrdersOrSalesError(error));
    });
};

export const fetchAllPrivateCustomer = (param) => (dispatch, getState, sdk) => {
  dispatch(fetchAllPrivateCustomersRequest());
  return axios
    .get(`${apiBaseUrl()}/api/private-customers`, {
      withCredentials: true,
    })
    .then((response) => {
      dispatch(fetchAllPrivateCustomersSuccess(response.data));
    })
    .catch((error) => {
      dispatch(fetchOrdersOrSalesError(error));
    });
};

export const fetchMarketPlaceCustomers = (param) => (dispatch, getState, sdk) => {
  const { customerParams } = param;
  const {
    firstName = '',
    lastName = '',
    email = '',
    phoneNumber = '',
    createdAt = '',
    page,
  } = customerParams;
  return axios
    .get(
      `${apiBaseUrl()}/api/marketplace-customer/${page}?firstName=${firstName}&lastName=${lastName}&email=${email}&phoneNumber=${phoneNumber}&createdAt=${createdAt}`,
      {
        withCredentials: true,
      }
    )
    .then((response) => {
      dispatch(fetchMarketPlaceCustomersSuccess(response.data));
    })
    .catch((error) => {
      dispatch(fetchMarketPlaceCustomersError(error));
    });
};

export const fetchPrivateBookings = (params) => (dispatch, getState, sdk) => {
  const { privateBookingParams } = params;
  const {
    page = 1,
    bookingType = '',
    date = 1,
    extraFacilities = '',
    venue = '',
  } = privateBookingParams;

  dispatch(fetchprivateBookingsRequest());
  return axios
    .get(
      `${apiBaseUrl()}/api/private/getPrivateBookings?page=${page}&bookingType=${bookingType}&date=${date}&extraFacilities=${extraFacilities}&venue=${venue}`,
      // { finalBody },
      { withCredentials: true }
    )
    .then((res) => {
      dispatch(fetchprivateBookingsSuccess(res?.data));
    })
    .catch((error) => {
      dispatch(fetchprivateBookingsError(error));
      console.log(error?.message);
    });
};

export const fetchAllBookings = (params) => (dispatch, getState, sdk) => {
  const { allBookingsParams } = params;
  // const {
  //   page = 1,
  //   venue = '',
  //   startDate = '',
  //   listingType = '',
  //   title = '',
  // } = allBookingParams;

  dispatch(fetchallBookingsRequest());
  return axios
    .post(
      `${apiBaseUrl()}/api/transactionDataByPage`,
      allBookingsParams,
      // { finalBody },
      { withCredentials: true }
    )
    .then((res) => {
      dispatch(fetchallBookingsSuccess(res?.data));
    })
    .catch((error) => {
      dispatch(fetchallBookingsError(error));
      console.log(error?.message);
    });
};

export const fetchUser = (id) => (dispatch, getState, sdk) => {
  return sdk.users.show({
    id,
    include: ['profileImage', 'profile.publicData'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  });
};

//upload image

export function requestImageUpload(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImage(actionPayload));
    return (
      sdk.images
        .upload({ image: actionPayload.file })
        .then((resp) => {
          if (id.split('_')[0]) {
            dispatch(updateFacilityImage({ [id.split('-')[0]]: { id: resp.data.data.id.uuid } }));
          }
          dispatch(uploadImageSuccess({ data: { id, imageId: resp.data.data.id } }));
        })
        // .then((resp) => dispatch(uploadImageSuccess({ data: { id, imageId: resp.data.data.id } })))
        .catch((e) => dispatch(uploadImageError({ id, error: storableError(e) })))
    );
  };
}

export const updateImageOrder = (imageOrder) => ({
  type: UPDATE_IMAGE_ORDER,
  payload: { imageOrder },
});

export const removeListingImage = (imageId) => ({
  type: REMOVE_LISTING_IMAGE,
  payload: { imageId },
});

export const showListing =
  (listingId, isOwn = false) =>
  (dispatch, getState, sdk) => {
    dispatch(showListingRequest(listingId));
    const params = {
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        // Listing page
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Image carousel
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    };

    const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

    return show
      .then((data) => {
        dispatch(showListingSuccess(data?.data?.data));
        dispatch(addMarketplaceEntities(data));
        return data;
      })
      .catch((e) => {
        dispatch(showListingError(storableError(e)));
      });
  };

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab, type } = params;
  const {
    page = 1,
    actionType,
    listing,
    id,
    firstName,
    lastName,
    email,
    phoneNumber,
    createdAt,
    bookingType,
    date,
    extraFacilities,
    venue,
    startDate,
    listingType,
    title,
  } = parse(search);
  const customerParams = { firstName, lastName, email, phoneNumber, createdAt, page };
  const privateBookingParams = { page, bookingType, date, extraFacilities, venue };
  const allBookingsParams = { page, venue, startDate, listingType, title };

  const onlyFilterValues = {
    dashboard: 'dashboard',
    calendar: 'calendar',
    booking: 'booking',
    listings: 'listings',
    customers: 'customers',
    vendorRequest: 'vendorRequest',
    agents: 'agents',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for VendorDashboard: ${tab}`));
  }
  if (tab === 'listings') {
    if (tab === 'listings' && actionType === 'editVenue') {
      const params = {
        pub_venueId: `${id}`,
        include: ['author', 'images'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      };
      return dispatch(searchListings(id, params));
    }
    dispatch(currentUserVenues({ page }));
  }
  if (tab === 'customers' && type === 'list') {
    dispatch(fetchPrivateCustomersRequest());

    return dispatch(fetchPrivateCustomer({ customerParams }));
  }

  if (tab === 'customers' && type === 'all') {
    dispatch(fetchMarketPlaceCustomersRequest());

    return dispatch(fetchMarketPlaceCustomers({ customerParams }));
  }

  if (tab === 'listings' && listing) {
    return dispatch(showListing(listing));
  }

  if (tab === 'booking' && actionType === 'all') {
    return dispatch(fetchAllBookings({ allBookingsParams }));
  }

  if (tab === 'booking' && actionType === 'private') {
    return dispatch(fetchPrivateBookings({ privateBookingParams }));
  }

  dispatch(fetchOrdersOrSalesRequest());

  const apiQueryParams = {
    only: 'order', //'sale',
    lastTransitions: TRANSITIONS,
    include: [
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
      'listing',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then((response) => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch((e) => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
