import React, { lazy, Suspense, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import {
  Avatar,
  Button,
  FieldTextInput,
  ImageFromFile,
  Form,
  FieldPhoneNumberInput,
  IconSpinner,
} from '../../components';
import { Field, Form as FinalForm } from 'react-final-form';
import { ensureCurrentUser } from '../../util/data';
import * as validators from '../../util/validators';
import classNames from 'classnames';
import { ReactComponent as UserSvg } from '../../assets/icons/user.svg';

import FileUpload from '../../components/FileUpload/FileUpload';
import css from './EditAgentsForm.module.css';
import { isUploadImageOverLimitError } from '../../util/errors';
import { useParams, withRouter } from 'react-router-dom';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
const ACCEPT_IMAGES = 'image/*';
const RichEditor = lazy(() => import('../../components/RichEditor/RichEditor'));

// const params = useParams();
// console.log(params, 123);

function EditAgentsFormComponent(props) {
  const [initState, setInitState] = useState({});
  return (
    <FinalForm
      initialValues={initState}
      {...props}
      render={(fieldRenderProps) => {
        const {
          className,
          currentUser,
          handleSubmit,
          intl,
          invalid,
          onImageUploadS3,
          pristine,
          profileImage,
          rootClassName,
          updateInProgress,
          updateProfileError,
          uploadImageError,
          uploadInProgress,
          form,
          values,
          image,
          history,
          tag,
          action,
          agentId,
        } = fieldRenderProps;
        // console.log(action, agentId, 789);

        const [editorText, setEditorText] = useState({});
        const [editorContent, setEditorContent] = useState({});
        const [imgData, setImgData] = useState(null);
        console.log(values, editorContent);

        useEffect(() => {
          if (agentId) {
            axios
              .get(`${apiBaseUrl()}/api/agent/${agentId}`)
              .then((res) => {
                console.log(res, 999, initState);
                setInitState(res.data);
              })
              .catch((err) => console.log(err.message, 999));
          }
        }, [agentId]);

        console.log(990, initState);
        const uploadDelay = false;
        // console.log(image, image?.file, 13);

        const user = ensureCurrentUser(currentUser);

        // First name
        const firstNameLabel = intl.formatMessage({
          id: 'EditAgentsForm.firstNameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'EditAgentsForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'EditAgentsForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // Last name
        const lastNameLabel = intl.formatMessage({
          id: 'EditAgentsForm.lastNameLabel',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'EditAgentsForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'EditAgentsForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        // email
        const emailLabel = intl.formatMessage({
          id: 'EditAgents.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'EditAgents.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'EditAgents.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'EditAgents.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // phone number
        const phoneLabel = intl.formatMessage({
          id: 'EditAgents.phoneLabel',
        });
        const phonePlaceholder = intl.formatMessage({
          id: 'EditAgents.phonePlaceholder',
        });
        const phoneRequiredMessage = intl.formatMessage({
          id: 'EditAgents.phoneRequired',
        });
        const phoneRequired = validators.required(phoneRequiredMessage);

        // Bio
        const bioLabel =
          tag === 'instructor'
            ? intl.formatMessage({
                id: 'EditAgentsForm.bioLabelInstructor',
              })
            : intl.formatMessage({
                id: 'EditAgentsForm.bioLabelReferee',
              });
        const bioPlaceholder =
          tag === 'instructor'
            ? intl.formatMessage({
                id: 'EditAgentsForm.bioPlaceholderInstructor',
              })
            : intl.formatMessage({
                id: 'EditAgentsForm.bioPlaceholderReferee',
              });

        // const profilePictureUploadLabel = intl.formatMessage({
        //   id: 'EditAgentsForm.profilePictureUploadLabel',
        // });

        // const profilePictureInstructorLabel = intl.formatMessage({
        //   id: 'EditAgentsForm.profilePictureInstructorLabel',
        // });

        const getImageSrc = (srcs) => {
          values.images = srcs;
        };

        const uploadingOverlay = uploadInProgress ? (
          <div className={css.uploadingImageOverlay}>
            <IconSpinner />
          </div>
        ) : null;

        const ImgUrl = initState?.profilePicture?.url;
        const hasUploadError = !!uploadImageError && !uploadInProgress;
        const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
        // const transientUserProfileImage = profileImage?.uploadedImage || user?.profileImage;
        const transientUserProfileImage = image?.uploadedImage || ImgUrl;
        const transientUser = { ...user, profileImage: transientUserProfileImage };

        // Ensure that file exists if imageFromFile is used
        const fileExists = !!image?.file;

        const fileUploadInProgress = uploadInProgress;
        const delayAfterUpload = uploadDelay;
        const imageFromFile =
          !fileUploadInProgress && fileExists ? (
            <ImageFromFile
              id={image?.id}
              className={errorClasses}
              rootClassName={css.uploadingImage}
              aspectRatioClassName={css.squareAspectRatio}
              file={image?.file}
            >
              {uploadingOverlay}
            </ImageFromFile>
          ) : null;

        // Avatar is rendered in hidden during the upload delay
        // Upload delay smoothes image change process:
        // responsive img has time to load srcset stuff before it is shown to user.
        const avatarClasses = classNames(errorClasses, css.avatar, css.borderAvatar, {
          [css.avatarInvisible]: uploadInProgress,
        });
        // console.log(profileImage, 852);
        const avatarComponent =
          !fileUploadInProgress && ImgUrl && !fileExists ? (
            // <Avatar
            //   className={avatarClasses}
            //   renderSizes="(max-width: 767px) 96px, 240px"
            //   user={transientUser}
            //   disableProfileLink
            // />
            <img src={ImgUrl} className={avatarClasses} />
          ) : null;

        const chooseAvatarLabel =
          image?.file || ImgUrl ? (
            <div className={css.avatarContainer}>
              {imageFromFile}
              {avatarComponent}
              {/* <div className={css.changeAvatar}>
              <FormattedMessage id="EditAgentsForm.changeAvatar" />
            </div> */}
            </div>
          ) : (
            <div className={css.avatarPlaceholder}>
              <UserSvg />
              {/* <div className={css.avatarPlaceholderText}>
              <FormattedMessage id="EditAgentsForm.addYourProfilePicture" />
            </div>
            <div className={css.avatarPlaceholderTextMobile}>
              <FormattedMessage id="EditAgentsForm.addYourProfilePictureMobile" />
            </div> */}
            </div>
          );

        const submitError = updateProfileError ? (
          <div className={css.error}>
            <FormattedMessage id="EditAgentsForm.updateProfileFailed" />
          </div>
        ) : null;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = updateInProgress;
        const submittedOnce = true; //Object.keys(submittedValues).length > 0;
        const pristineSinceLastSubmit = false; //submittedOnce && isEqual(values, submittedValues);
        const submitDisabled =
          invalid || pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;

        return (
          <Form
            className={classes}
            onSubmit={(e) => {
              //   submittedValues = values;
              e.certification = editorContent.certification;
              handleSubmit(e);
            }}
          >
            <div className={`${css.sectionContainer} ${css.uploadsec}`}>
              {/* <h3 className={css.sectionTitle}>
                  <FormattedMessage id="EditAgentsForm.yourProfilePicture" />
                </h3> */}
              <Field
                accept={ACCEPT_IMAGES}
                id="profileImage"
                name="profileImage"
                label={chooseAvatarLabel}
                type="file"
                form={null}
                uploadImageError={uploadImageError}
                disabled={uploadInProgress}
              >
                {(fieldProps) => {
                  const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                  const { name, type } = input;
                  const onChange = (e) => {
                    const file = e.target.files[0];
                    form.change(`profileImage`, file);
                    form.blur(`profileImage`);
                    if (file !== null) {
                      const tempId = `${file.name}_${Date.now()}`;
                      onImageUploadS3({ id: tempId, file });
                    }
                  };

                  let error = null;

                  if (isUploadImageOverLimitError(uploadImageError)) {
                    error = (
                      <div className={css.error}>
                        <FormattedMessage id="EditAgentsForm.imageUploadFailedFileTooLarge" />
                      </div>
                    );
                  } else if (uploadImageError) {
                    error = (
                      <div className={css.error}>
                        <FormattedMessage id="EditAgentsForm.imageUploadFailed" />
                      </div>
                    );
                  }

                  return (
                    <div className={css.uploadAvatarWrapper}>
                      <label className={css.label} htmlFor={id}>
                        {label}
                      </label>
                      <input
                        accept={accept}
                        id={id}
                        name={name}
                        className={css.uploadAvatarInput}
                        disabled={disabled}
                        onChange={onChange}
                        type={type}
                      />
                      <div className={css.uplaodTextDiv}>
                        <p>
                          <span>
                            {image?.file ? (
                              <label htmlFor={id}>Change</label>
                            ) : (
                              <label htmlFor={id}>Uplaod</label>
                            )}{' '}
                            {tag === 'instructor' ? 'Instructor' : 'Referee'} profile picture.
                          </span>
                        </p>
                        <p>.JPG, .PNG Maximum 20MB.</p>
                      </div>
                      {error}
                    </div>
                  );
                }}
              </Field>
              {/* <div>
                <div className={css.sectionhead}>
                  <span>Media</span> <hr />
                </div>
                <FileUpload
                  name="images"
                  isMultiple={true}
                  imageLabel={classImageLabel}
                  getImageSrc={getImageSrc}
                  // getImageBlob={getImageBlob}
                />
                {values.images?.map(file => (
                  <span>
                    <img src={file} height="200px" width="200px" />
                  </span>
                ))}
              </div> */}

              {/* <div className={css.tiptxt}>
                <div className={css.tip}>
                  <span>Upload</span> your profile picture.
                </div>
                <div className={css.fileInfo}>
                  <FormattedMessage id="EditAgentsForm.fileInfo" />
                </div>
              </div> */}
            </div>
            <div className={css.sectionContainer}>
              <div className={css.nameContainer}>
                <FieldTextInput
                  className={css.firstName}
                  type="text"
                  id="firstName"
                  name="firstName"
                  label={firstNameLabel}
                  placeholder={firstNamePlaceholder}
                  validate={firstNameRequired}
                />
                <FieldTextInput
                  className={css.lastName}
                  type="text"
                  id="lastName"
                  name="lastName"
                  label={lastNameLabel}
                  placeholder={lastNamePlaceholder}
                  validate={lastNameRequired}
                />
              </div>

              <div className={css.nameContainer}>
                <FieldTextInput
                  type="email"
                  id={'email'}
                  name="email"
                  autoComplete="email"
                  label={emailLabel}
                  placeholder={emailPlaceholder}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
                <FieldPhoneNumberInput
                  className={css.phone}
                  id={'phoneNumber'}
                  name="phoneNumber"
                  label={phoneLabel}
                  placeholder={phonePlaceholder}
                  validate={phoneRequired}
                />
              </div>
            </div>
            <div className={classNames(css.sectionContainer)}>
              <FieldTextInput
                type="textarea"
                id="bio"
                name="bio"
                label={bioLabel}
                placeholder={bioPlaceholder}
              />
            </div>
            <div className={css.richEditorContainer}>
              <label>{tag === 'instructor' ? 'Instructor' : 'Referee'} Certifications</label>
              <Suspense fallback={<div>Loading...</div>}>
                <RichEditor
                  name={`certification`}
                  placeholder={
                    tag === 'instructor'
                      ? 'Certifications of the Instructor...'
                      : 'Certifications of the Referee...'
                  }
                  editorText={editorText}
                  setEditorText={setEditorText}
                  setEditorContent={setEditorContent}
                  editorContent={editorContent}
                />
              </Suspense>
            </div>
            {submitError}
            <div className={css.lastSection}>
              <Button
                className={submitDisabled ? css.submitDisabledBtn : css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                {/* <FormattedMessage id="ProfileSettingsForm.saveChanges" /> */}
                {tag === 'instructor' ? (
                  <span>{action === 'edit' ? 'Save Changes' : 'Save and Add Instructor'}</span>
                ) : (
                  <span>{action === 'edit' ? 'Save Changes' : 'Save and Add Referee'}</span>
                )}
              </Button>
              <button className={css.cancelButton} onClick={() => history.goBack()}>
                Cancel
              </button>
            </div>
          </Form>
        );
      }}
    />
  );
}

const EditAgentsForm = compose(withRouter, injectIntl)(EditAgentsFormComponent);

export default EditAgentsForm;
