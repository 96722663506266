import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { PAST_TRANSITIONS, TRANSITIONS, UPCOMING_TRANSITIONS } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { TRANSACTION_TYPE_UPCOMMING } from '../../util/types';

const sortedTransactions = (txs) =>
  reverse(
    sortBy(txs, (tx) => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );
const entityRefs = (entities) =>
  entities.map((entity) => ({
    id: entity.id,
    type: entity.type,
  }));

// ================ Action types ================ //

export const FETCH_TRANSACTIONS_REQUEST = 'app/UserTransactionPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/UserTransactionPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/UserTransactionPage/FETCH_TRANSACTIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchTransactionError: null,
  transactions: [],
  transactionRefs: [],
  transactionPagination: null,
};

export default function fetchUserTransactions(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TRANSACTIONS_REQUEST:
      return { ...state, fetchInProgress: true };
    case FETCH_TRANSACTIONS_SUCCESS: {
      //   const transactions = fetchTransaction(payload.data.data);
      const stransactions = sortedTransactions(payload);
      return {
        ...state,
        fetchInProgress: false,
        transactions: action.payload,
        transactionRefs: entityRefs(stransactions),
      };
    }
    case FETCH_TRANSACTIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchTransactionError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchTransRequest = () => ({ type: FETCH_TRANSACTIONS_REQUEST });
const fetchTransSuccess = (response) => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload: response,
});
const fetchTransError = (e) => ({
  type: FETCH_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { hTab } = parse(search);

  dispatch(fetchTransRequest());

  return sdk.transactions
    .query({
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      include: [
        'provider',
        'provider.profileImage',
        'customer',
        'customer.profileImage',
        'booking',
        'listing',
      ],
      'fields.listing': [
        'title',
        'availabilityPlan',
        'geolocation',
        'price',
        'publicData',
        'deleted',
      ],
      only: 'order',
      lastTransitions:
        hTab === TRANSACTION_TYPE_UPCOMMING ? UPCOMING_TRANSITIONS : PAST_TRANSITIONS,
    })
    .then((res) => {
      const transactions = res.data.data;
      dispatch(addMarketplaceEntities(res));
      hTab === TRANSACTION_TYPE_UPCOMMING
        ? dispatch(fetchTransSuccess(transactions))
        : dispatch(fetchTransSuccess(transactions));

      return res;
    })
    .catch((e) => {
      dispatch(fetchTransError(e));
      throw e;
    });
};
