import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToTz, getExclusiveEndDateWithTz } from '../../util/dates';
import { parse } from '../../util/urlHelpers';
import config from '../../config';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';

const RESULT_PAGE_SIZE = 5;

// ================ Action types ================ //

export const VENUE_LISTINGS_REQUEST = 'app/VenueActivity/SEARCH_LISTINGS_REQUEST';
export const VENUE_LISTINGS_SUCCESS = 'app/VenueActivity/SEARCH_LISTINGS_SUCCESS';
export const VENUE_LISTINGS_ERROR = 'app/VenueActivity/SEARCH_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  venueLoading: false,
  venues: [],
  venueListings: [],
  venueSuccess: false,
  venueError: null,
  pagination: null,
  currentPageResultIds: [],
};

const resultIds = (data) => data.data.map((l) => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case VENUE_LISTINGS_REQUEST:
      return {
        ...state,
        venueLoading: true,
        venues: [],
        venueSuccess: false,
        venueError: [],
        venueListings: [],
        pagination: null,
        currentPageResultIds: [],
      };

    case VENUE_LISTINGS_SUCCESS:
      return {
        ...state,
        venueLoading: false,
        venues: payload.data,
        venueListings: payload.listing,
        venueSuccess: true,
        venueError: [],
        pagination: payload.listing.meta,
        currentPageResultIds: resultIds(payload.listing),
      };
    case VENUE_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        venueLoading: false,
        venues: [],
        venueSuccess: false,
        venueError: payload,
        venueListings: [],
        pagination: null,
        currentPageResultIds: [],
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const venueListingsRequest = () => ({
  type: VENUE_LISTINGS_REQUEST,
  payload: {},
});

export const venueListingsSuccess = (response, listings) => ({
  type: VENUE_LISTINGS_SUCCESS,
  payload: { data: response.data, listing: listings.data },
});

export const venueListingsError = (e) => ({
  type: VENUE_LISTINGS_ERROR,
  payload: e,
});

export const showListings = (params) => (dispatch, getState, sdk) => {
  let apiResp;
  const listings = sdk.listings
    .query(params)
    .then((response) => {
      apiResp = response;
      const promises = response.data.data?.map((d) => {
        const listingId = d.id.uuid;
        return axios.get(`${apiBaseUrl()}/api/listing/${listingId}`);
      });
      return Promise.all(promises);
    })
    .then((res) => {
      apiResp.data.data?.map((d) => {
        const current = res.find((r) => r.data.sharetribeId === d.id.uuid);
        d.attributes.publicData.fromMongo = current && current.data ? current.data : {};
      });
      return res;
    })
    .then((res) => {
      dispatch(addMarketplaceEntities(apiResp));
      return apiResp;
    });
  return listings;
};

export const searchListings = (id, params) => (dispatch, getState, sdk) => {
  let apiResp;
  dispatch(venueListingsRequest());
  return axios
    .get(`${apiBaseUrl()}/api/venue-detail/${id}`)
    .then((res) => {
      const Resp = res;

      if (res.data._id) {
        return sdk.listings
          .query(params)
          .then((response) => {
            apiResp = response;
            const promises = response.data.data?.map((d) => {
              const listingId = d.id.uuid;
              return axios.get(`${apiBaseUrl()}/api/listing/${listingId}`);
            });
            return Promise.all(promises);
          })
          .then((res) => {
            apiResp.data.data?.map((d) => {
              const current = res.find((r) => r.data.sharetribeId === d.id.uuid);
              d.attributes.publicData.fromMongo = current && current.data ? current.data : {};
            });
            return res;
          })
          .then((res) => {
            dispatch(addMarketplaceEntities(apiResp));
            return dispatch(venueListingsSuccess(Resp, apiResp));
          });
      } else {
        const newResp = { ...res, status: 404 };

        dispatch(venueListingsError(newResp));
        // return res
      }
      return res;
    })
    .catch((err) => {
      dispatch(venueListingsError(err));
      throw err;
    });
};

// export const searchVenues = (id) => (dispatch, getState, sdk) => {
//   // dispatch(venueListingsRequest());

//   axios.get(`${apiBaseUrl()}/api/venue-detail/${id}`)
//   .then((res)=>{
//     console.log(5555555, res);
//     // dispatch(venueListingsSuccess(res));
//     if(res.data._id)
//     dispatch(venueListingsSuccess(res));
//     else
//     dispatch(venueListingsError(res));

//   })
//   .catch((err)=>{
//     dispatch(venueListingsError(err));
//   })

//   // const priceSearchParams = (priceParam) => {
//   //   const inSubunits = (value) =>
//   //     convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
//   //   const values = priceParam ? priceParam.split(',') : [];
//   //   return priceParam && values.length === 2
//   //     ? {
//   //         price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
//   //       }
//   //     : {};
//   // };

//   // const availabilityParams = (datesParam, minDurationParam) => {
//   //   const dateValues = datesParam ? datesParam.split(',') : [];
//   //   const hasDateValues = datesParam && dateValues.length === 2;
//   //   const startDate = hasDateValues ? dateValues[0] : null;
//   //   const endDate = hasDateValues ? dateValues[1] : null;

//   //   const minDurationMaybe =
//   //     minDurationParam && Number.isInteger(minDurationParam) && hasDateValues
//   //       ? { minDuration: minDurationParam }
//   //       : {};

//   //   // Find configs for 'dates-length' filter
//   //   // (type: BookingDateRangeLengthFilter)
//   //   const filterConfigs = config.custom.filters;
//   //   const idOfBookingDateRangeLengthFilter = 'dates-length';
//   //   const dateLengthFilterConfig = filterConfigs.find(
//   //     (f) => f.id === idOfBookingDateRangeLengthFilter
//   //   );
//   //   // Extract time zone
//   //   const timeZone = dateLengthFilterConfig.config.searchTimeZone;

//   //   return hasDateValues
//   //     ? {
//   //         start: formatDateStringToTz(startDate, timeZone),
//   //         end: getExclusiveEndDateWithTz(endDate, timeZone),

//   //         // When we have `time-partial` value in the availability, the
//   //         // API returns listings that don't necessarily have the full
//   //         // start->end range available, but enough that the minDuration
//   //         // (in minutes) can be fulfilled.
//   //         //
//   //         // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering
//   //         availability: 'time-partial',

//   //         ...minDurationMaybe,
//   //       }
//   //     : {};
//   // };

//   // const { perPage, price, dates, minDuration, ...rest } = searchParams;
//   // const priceMaybe = priceSearchParams(price);
//   // const availabilityMaybe = availabilityParams(dates, minDuration);

//   // const params = {
//   //   ...rest,
//   //   ...priceMaybe,
//   //   ...availabilityMaybe,
//   //   per_page: perPage,
//   // };
//   // let apiResp;
//   // return sdk.listings
//   //   .query(params)
//   //   .then((response) => {
//   //     apiResp = response;
//   //     const promises = response.data.data?.map((d) => {
//   //       const listingId = d.id.uuid;
//   //       return axios.get(`${apiBaseUrl()}/api/venue-detail/${id}`)
//   //     });
//   //     return Promise.all(promises);
//   //   })
//   //   .then((res) => {
//   //     apiResp.data.data?.map((d) => {
//   //       const current = res.find((r) => r.data.sharetribeId === d.id.uuid);
//   //       d.attributes.publicData.fromMongo = current && current.data ? current.data : {};
//   //     });
//   //   })
//   //   .then(() => {
//   //     dispatch(addMarketplaceEntities(apiResp));
//   //     dispatch(venueListingsSuccess(apiResp));
//   //   })
//   //   .catch((e) => {
//   //     dispatch(venueListingsError(storableError(e)));
//   //     throw e;
//   //   });
// };

export const loadData = (param) => (dispatch, getState, sdk) => {
  const { id } = param;
  const params = {
    pub_venueId: `${id}`,
    include: ['author', 'images'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
  };
  return dispatch(searchListings(id, params));
  // return searchListings(Id, params)
};
