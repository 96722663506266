import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ReactComponent as UpArrow } from '../../assets/arrowUp.svg';
import { ReactComponent as DownArrow } from '../../assets/arrowDown.svg';
import { withRouter } from 'react-router-dom';
import css from './CustomFacilityBookingForm.module.css';
import { isArray, isEmpty } from 'lodash';
import moment from 'moment';
import { FieldDateInput, FieldSelect } from '../../components';
import {
  dateIsAfter,
  findNextBoundary,
  getEndHours,
  getMonthStartInTimeZone,
  getStartHours,
  isDayMomentInsideRange,
  isInRange,
  isSameDate,
  localizeAndFormatTime,
  monthIdStringInTimeZone,
  nextMonthFn,
  prevMonthFn,
  resetToStartOfDay,
  timeOfDayFromLocalToTimeZone,
  timeOfDayFromTimeZoneToLocal,
  timestampToDate,
} from '../../util/dates';
import config from '../../config';

import NextMonthIcon from './NextMonthIcon';
import PreviousMonthIcon from './PreviousMonthIcon';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import { parse } from '../../util/urlHelpers';
import { compose } from 'redux';

const EXTRA_PARTICIPANTS = ['Children', 'Infants'];

const MAX_TIME_SLOTS_RANGE = config.dayCountAvailableForBooking;

const TODAY = new Date();

const endOfRange = (date, timeZone) => {
  return resetToStartOfDay(date, timeZone, MAX_TIME_SLOTS_RANGE - 1);
};

const getAvailableStartTimes = (
  intl,
  timeZone,
  bookingStart,
  timeSlotsOnSelectedDate,
  duration,
  allBookingdetails,
  listing,
  category
) => {
  if (timeSlotsOnSelectedDate.length === 0 || !timeSlotsOnSelectedDate[0] || !bookingStart) {
    return [];
  }
  const bookingStartDate = resetToStartOfDay(bookingStart, timeZone);

  const { publicData } = listing.attributes || {};

  const { fromMongo } = publicData || {};
  const { listingDetails, scheduleDetails, sportsInfo = [] } = fromMongo || {};

  const selectedSports = sportsInfo?.find(({ sportName }) => sportName === category);

  const {
    facilityType,
    fullFacilityDurationAndPrice,
    halfFacilityDurationAndPrice,
    quaterFacilityDurationAndPrice,
    groupBookingInfo,
    rentalExtraEquipment,
  } = selectedSports || [];
  const { options } = groupBookingInfo || {};
  const shouldBlockTimeSlot = options.includes('blockTimeSlot');

  let allAvailableHour = [];

  let allAvailableTime = [];
  let allAvailableTime2 = [];

  timeSlotsOnSelectedDate.map((m) => {
    let start = moment();
    if (Number(moment(m.attributes.start).clone().format('mm')) % 5) {
      const min = moment(m.attributes.start).clone().format('mm');
      const minToAdd = 5 * (parseInt(min / 5) + 1);

      start = moment(m.attributes.start).clone().startOf('hour').add(minToAdd, 'm');
    } else {
      start = moment(m.attributes.start).clone();
    }
    const end = moment(m.attributes.end).clone();
    while (start.clone().isSameOrBefore(moment(end))) {
      allAvailableTime.push(start.format());
      allAvailableTime2.push(start.format('HH:mm'));

      start.add(5, 'm');
    }
  });

  let allformatedBookedSlots = [];
  allBookingdetails.forEach((r) => {
    if (r.listingCategory === category && !shouldBlockTimeSlot) {
      return;
    }
    allformatedBookedSlots.push({
      start: moment(r.startDate).toDate(),
      end: moment(r.endDate).toDate(),
    });
  });

  let allBookedSlot = [];

  allformatedBookedSlots.map((b, i) => {
    const start = moment(b.start).clone();
    const end = moment(b.end).clone();
    // const bookedCategory=b.bookedCategory

    // .subtract(1, 'm');
    let count = 0;

    while (start.clone().add(5, 'm').isSameOrBefore(moment(end))) {
      if (count === 0) {
        allBookedSlot.push(start.clone().add(1, 'm').format());
      } else {
        allBookedSlot.push(start.format());
      }
      start.add(5, 'm');
      count += 1;
    }
  });
  let availableTimeSlots = [
    ...new Set([...allAvailableTime.filter((f) => !allBookedSlot.includes(f))]),
  ];
  const allStartHour = [];

  availableTimeSlots.map((m) => {
    const start = moment(m).clone();
    const min = duration;

    const totalMin = min * 1;

    if (
      availableTimeSlots.includes(start.clone().add(min, 'm').format()) &&
      availableTimeSlots.indexOf(start.clone().add(min, 'm').format()) -
        availableTimeSlots.indexOf(start.clone().format()) ===
        totalMin / 5
    ) {
      allStartHour.push({
        timeOfDay: start.format('HH:mm'),
        timestamp: start.valueOf(),
      });
      start.add(5, 'm');
    }
  });
  return allStartHour;
};

const getAvailableEndTimes = (
  intl,
  timeZone,
  bookingStartTime,
  bookingEndDate,
  selectedTimeSlot,
  duration
) => {
  if (!selectedTimeSlot || !selectedTimeSlot.attributes || !bookingEndDate || !bookingStartTime) {
    return [];
  }

  const endDate = selectedTimeSlot.attributes.end;
  const bookingStartTimeAsDate = timestampToDate(bookingStartTime);

  const dayAfterBookingEnd = resetToStartOfDay(bookingEndDate, timeZone, 1);
  const dayAfterBookingStart = resetToStartOfDay(bookingStartTimeAsDate, timeZone, 1);
  const startOfEndDay = resetToStartOfDay(bookingEndDate, timeZone);

  let startLimit;
  let endLimit;

  if (!dateIsAfter(startOfEndDay, bookingStartTimeAsDate)) {
    startLimit = bookingStartTimeAsDate;
    endLimit = dateIsAfter(dayAfterBookingStart, endDate) ? endDate : dayAfterBookingStart;
  } else {
    // If the end date is on the same day as the selected booking start time
    // use the start time as limit. Otherwise use the start of the selected end date.
    startLimit = dateIsAfter(bookingStartTimeAsDate, startOfEndDay)
      ? bookingStartTimeAsDate
      : startOfEndDay;

    // If the selected end date is on the same day as timeslot end, use the timeslot end.
    // Else use the start of the next day after selected date.
    endLimit = isSameDate(resetToStartOfDay(endDate, timeZone), startOfEndDay)
      ? endDate
      : dayAfterBookingEnd;
  }
  return getEndHours(intl, timeZone, startLimit, endLimit, duration);
};

const getTimeSlots = (timeSlots, date, timeZone) => {
  return timeSlots && timeSlots[0]
    ? timeSlots.filter((t) =>
        isInRange(date, t.attributes.start, t.attributes.end, 'day', timeZone)
      )
    : [];
};

// Use start date to calculate the first possible start time or times, end date and end time or times.
// If the selected value is passed to function it will be used instead of calculated value.
const getAllTimeValues = (
  intl,
  timeZone,
  timeSlots,
  startDate,
  selectedStartTime,
  selectedEndDate,
  duration,
  allBookingdetails,
  listing,
  category
) => {
  const startTimes = selectedStartTime
    ? []
    : getAvailableStartTimes(
        intl,
        timeZone,
        startDate,
        getTimeSlots(timeSlots, startDate, timeZone),
        duration,
        allBookingdetails,
        listing,
        category
      );

  // Value selectedStartTime is a string when user has selected it through the form.
  // That's why we need to convert also the timestamp we use as a default
  // value to string for consistency. This is expected later when we
  // want to compare the sartTime and endTime.
  const startTime = selectedStartTime
    ? selectedStartTime
    : startTimes.length > 0 && startTimes[0] && startTimes[0].timestamp
    ? startTimes[0].timestamp.toString()
    : null;

  const startTimeAsDate = startTime ? timestampToDate(startTime) : null;

  // Note: We need to remove 1ms from the calculated endDate so that if the end
  // date would be the next day at 00:00 the day in the form is still correct.
  // Because we are only using the date and not the exact time we can remove the
  // 1ms.
  const endDate = selectedEndDate
    ? selectedEndDate
    : startTimeAsDate
    ? new Date(findNextBoundary(timeZone, startTimeAsDate).getTime() - 1)
    : null;

  const selectedTimeSlot = timeSlots.find((t) =>
    isInRange(startTimeAsDate, t.attributes.start, t.attributes.end)
  );

  const endTimes = getAvailableEndTimes(
    intl,
    timeZone,
    startTime,
    endDate,
    selectedTimeSlot,
    duration
  );

  // We need to convert the timestamp we use as a default value
  // for endTime to string for consistency. This is expected later when we
  // want to compare the sartTime and endTime.
  const endTime =
    endTimes.length > 0 && endTimes[0] && endTimes[0].timestamp
      ? endTimes[0].timestamp.toString()
      : null;

  return { startTime, endDate, endTime, selectedTimeSlot };
};

const getMonthlyTimeSlots = (monthlyTimeSlots, date, timeZone) => {
  const monthId = monthIdStringInTimeZone(date, timeZone);

  return !monthlyTimeSlots || Object.keys(monthlyTimeSlots).length === 0
    ? []
    : monthlyTimeSlots[monthId] && monthlyTimeSlots[monthId].timeSlots
    ? monthlyTimeSlots[monthId].timeSlots
    : [];
};

const Next = (props) => {
  const { currentMonth, timeZone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return dateIsAfter(nextMonthDate, endOfRange(TODAY, timeZone)) ? null : <NextMonthIcon />;
};
const Prev = (props) => {
  const { currentMonth, timeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? <PreviousMonthIcon /> : null;
};

function CustomFacilityBookingForm(props) {
  const {
    listing,
    values,
    form,
    disableCheck,
    submitDisable,
    formId,
    timeZone,
    monthlyTimeSlots,
    onMonthChanged,
    startDateInputProps,
    intl,
    category,
    bookingErrMsg,
    location,
    searchParams,
  } = props;

  const selectedSports = listing?.attributes?.publicData?.fromMongo?.sportsInfo?.find(
    ({ sportName }) => sportName === category
  );

  const {
    facilityType,
    fullFacilityDurationAndPrice,
    halfFacilityDurationAndPrice,
    quaterFacilityDurationAndPrice,
    groupBookingInfo,
    rentalExtraEquipment,
  } = selectedSports || [];
  const { publicData } = listing.attributes || {};
  const { fromMongo } = publicData || {};
  const { listingDetails, scheduleDetails } = fromMongo || {};
  const validExtraParticipants = EXTRA_PARTICIPANTS.filter(
    (f) =>
      (f === 'Children' &&
        fromMongo?.sportsInfo?.find((f) => f.sportName === searchParams?.category)
          ?.priceForChildren) ||
      (f === 'Infants' &&
        fromMongo?.sportsInfo?.find((f) => f.sportName === searchParams?.category)?.priceForInfants)
  );
  const { timeInterval: duration } = listingDetails || {};

  const [maxAttendee, setMaxAttendee] = useState(groupBookingInfo?.maxCapacity || 0);
  const [attendeeErr, setAttendeeErr] = useState(false);
  const [extraErr, setExtraErr] = useState({});
  const [currentMonth, setCurrentMonth] = useState(getMonthStartInTimeZone(TODAY, props.timeZone));

  const bookingStartDate =
    values.bookingStartDate && values.bookingStartDate.date ? values.bookingStartDate.date : null;
  const bookingStartTime = values.bookingStartTime ? values.bookingStartTime : null;
  const bookingEndDate =
    values.bookingEndDate && values.bookingEndDate.date ? values.bookingEndDate.date : null;

  const startTimeDisabled = !bookingStartDate;
  const endDateDisabled = !bookingStartDate || !bookingStartTime;
  const endTimeDisabled = !bookingStartDate || !bookingStartTime || !bookingEndDate;
  const isFacilityHireType = publicData?.activity.includes('facilityHire');
  const isSessionTypeFacility = fromMongo?.listingDetails?.durationType === 'session';
  const [allBookingdetails, setAllBookingdetails] = useState([]);

  useEffect(() => {
    facilityType?.length > 0 ? null : form.change('facilitySize', 'fullFacility');
  }, [facilityType]);
  useEffect(() => {
    form.change('attendee', { quantity: groupBookingInfo?.minCapacity });
  }, [scheduleDetails]);

  useEffect(() => {
    if (
      values.attendee &&
      (values.attendee['quantity'] < (groupBookingInfo?.minCapacity || 0) ||
        values.attendee['quantity'] > (groupBookingInfo?.maxCapacity || 0))
    ) {
      setAttendeeErr(true);
    } else {
      setAttendeeErr(false);
    }

    const hasExtraError = Object.values(extraErr).some((error) => error === true);
    const submitBtnDisable =
      attendeeErr ||
      hasExtraError ||
      !values.bookingStartTime ||
      !values.bookingEndTime ||
      !values.facilitySize ||
      (isSessionTypeFacility && !values.sessionOption);

    if (submitDisable !== submitBtnDisable) {
      disableCheck(submitBtnDisable);
    }
  }, [values, attendeeErr, extraErr]);

  const [menueSelected, setMenueSelected] = useState({
    time: false,
    participants: false,
    extra: false,
    reservation: false,
    facilitySize: false,
    date: false,
  });

  const timeSlotsOnSelectedMonth = getMonthlyTimeSlots(monthlyTimeSlots, currentMonth, timeZone);
  const timeSlotsOnSelectedDate = getTimeSlots(
    timeSlotsOnSelectedMonth,
    bookingStartDate,
    timeZone
  );
  useEffect(() => {
    const { listing, category, listingId } = props;
    const fetchData = async () => {
      const { data: bookingDetails } = await axios.get(
        `${apiBaseUrl()}/api/transactions/listing/${listingId.uuid}?startDate=${moment()
          .clone()
          .startOf('day')
          .valueOf()}&endDate=${moment(currentMonth).clone().endOf('month').valueOf()}`,
        {
          withCredentials: true,
        }
      );
      setAllBookingdetails(bookingDetails);
      const { transactions } =
        (bookingStartTime &&
          bookingDetails[0][
            moment(bookingStartTime * 1)
              .utc()
              .format('YYYY-MM-DD')
          ]) ||
        {};
    };
    // fetchData();
  }, [currentMonth]);

  const { transactions = [] } =
    // (bookingStartTime &&
    //   allBookingdetails[0][
    //     moment(bookingStartTime * 1)
    //       .utc()
    //       .format('YYYY-MM-DD')
    //   ]) ||
    {};
  const fetchMonthData = (date) => {
    const { listingId, timeZone, onFetchTimeSlots } = props;
    const endOfRangeDate = endOfRange(TODAY, timeZone);

    // Don't fetch timeSlots for past months or too far in the future
    if (isInRange(date, TODAY, endOfRangeDate)) {
      // Use "today", if the first day of given month is in the past
      const start = dateIsAfter(TODAY, date) ? TODAY : date;

      // Use endOfRangeDate, if the first day of the next month is too far in the future
      const nextMonthDate = nextMonthFn(date, timeZone);
      const end = dateIsAfter(nextMonthDate, endOfRangeDate)
        ? resetToStartOfDay(endOfRangeDate, timeZone, 0)
        : nextMonthDate;

      // Fetch time slots for given time range
      onFetchTimeSlots(listingId, start, end, timeZone);
    }
  };

  const onMonthClick = (monthFn) => {
    const { onMonthChanged, timeZone } = props;

    setCurrentMonth((prevState) => {
      fetchMonthData(monthFn(prevState, timeZone));
      const monthId = monthIdStringInTimeZone(monthFn(prevState, timeZone), timeZone);
      const currentMonthData = monthlyTimeSlots[monthId];

      if (currentMonthData && currentMonthData.fetchTimeSlotsError) {
        fetchMonthData(monthFn(prevState, timeZone), timeZone);
      }

      // Call onMonthChanged function if it has been passed in among props.
      if (onMonthChanged) {
        onMonthChanged(monthId);
      }
      return monthFn(prevState, timeZone);
    });
  };

  const onBookingStartDateChange = (value) => {
    const { monthlyTimeSlots, timeZone, intl, form, listingId } = props;
    const { fromMongo } = listing.attributes.publicData || {};
    const { listingDetails } = fromMongo || {};
    const { timeInterval: duration } = listingDetails || {};

    if (!value || !value.date) {
      form.batch(() => {
        form.change('bookingStartTime', null);
        form.change('bookingEndDate', { date: null });
        form.change('bookingEndTime', null);
      });
      // Reset the currentMonth too if bookingStartDate is cleared
      setCurrentMonth(getMonthStartInTimeZone(TODAY, timeZone));

      return;
    }

    // This callback function (onBookingStartDateChange) is called from react-dates component.
    // It gets raw value as a param - browser's local time instead of time in listing's timezone.
    const startDate = timeOfDayFromLocalToTimeZone(value.date, timeZone);
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, currentMonth, timeZone);
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);
    const fetchData = async () => {
      const { data: bookingDetails } = await axios.get(
        `${apiBaseUrl()}/api/transactions/listing/${listingId.uuid}?startDate=${moment(startDate)
          .clone()
          .startOf('day')
          .valueOf()}&endDate=${moment(startDate).clone().endOf('day').valueOf()}`,
        {
          withCredentials: true,
        }
      );
      setAllBookingdetails(bookingDetails);
      // const { transactions } =
      //   (bookingStartTime &&
      //     bookingDetails[0][
      //       moment(bookingStartTime * 1)
      //         .utc()
      //         .format('YYYY-MM-DD')
      //     ]) ||
      //   {};
      const { startTime, endDate, endTime } = getAllTimeValues(
        intl,
        timeZone,
        timeSlotsOnSelectedDate,
        startDate,
        null,
        null,
        duration,
        bookingDetails,
        listing,
        category
      );

      const availableSeats = monthlyTimeSlots[
        moment(startTime * 1).format('YYYY-MM')
      ]?.timeSlots?.find(({ attributes }) => {
        const start = new Date(attributes.start).getTime();
        const end = new Date(attributes.end).getTime();
        return startTime >= start && endTime <= end;
      })?.attributes?.seats;

      setMaxAttendee(availableSeats);
      form.batch(() => {
        form.change('bookingStartTime', startTime);
        form.change('bookingEndDate', { date: endDate });
        form.change('bookingEndTime', endTime);
        form.change('maxCapacity', availableSeats);
      });
    };
    fetchData();
  };

  const onBookingStartTimeChange = (value) => {
    const { monthlyTimeSlots, timeZone, intl, form, values } = props;
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, currentMonth, timeZone);
    const startDate = values.bookingStartDate.date;
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);

    const { endDate, endTime } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      value,
      null,
      duration,
      allBookingdetails,
      listing,
      category
    );

    const availableSeats = monthlyTimeSlots[moment(value * 1).format('YYYY-MM')].timeSlots.find(
      ({ attributes }) => {
        const start = new Date(attributes.start).getTime();
        const end = new Date(attributes.end).getTime();
        return value >= start && endTime <= end;
      }
    )?.attributes?.seats;

    setMaxAttendee(availableSeats);

    form.batch(() => {
      form.change('bookingEndDate', { date: endDate });
      form.change('bookingEndTime', endTime);
      form.change('maxCapacity', availableSeats);
    });
  };

  const onBookingEndDateChange = (value) => {
    const { monthlyTimeSlots, timeZone, intl, form, values } = props;
    if (!value || !value.date) {
      form.change('bookingEndTime', null);
      return;
    }

    // This callback function (onBookingStartDateChange) is called from react-dates component.
    // It gets raw value as a param - browser's local time instead of time in listing's timezone.
    const endDate = timeOfDayFromLocalToTimeZone(value.date, timeZone);

    const { bookingStartDate, bookingStartTime } = values;
    const startDate = bookingStartDate.date;
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, currentMonth, timeZone);
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);

    const { endTime } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      bookingStartTime,
      endDate
    );

    form.change('bookingEndTime', endTime);
  };

  const isOutsideRange = (day, bookingStartDate, selectedTimeSlot, timeZone) => {
    if (!selectedTimeSlot) {
      return true;
    }

    // 'day' is pointing to browser's local time-zone (react-dates gives these).
    // However, bookingStartDate and selectedTimeSlot refer to times in listing's timeZone.
    const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);
    // Given day (endDate) should be after the start of the day of selected booking start date.
    const startDate = resetToStartOfDay(bookingStartDate, timeZone);
    // 00:00 would return wrong day as the end date.
    // Removing 1 millisecond, solves the exclusivity issue.
    const inclusiveEnd = new Date(selectedTimeSlot.attributes.end.getTime() - 1);
    // Given day (endDate) should be before the "next" day of selected timeSlots end.
    const endDate = resetToStartOfDay(inclusiveEnd, timeZone, 1);
    return !(dateIsAfter(localizedDay, startDate) && dateIsAfter(endDate, localizedDay));
  };

  const isDayBlocked = timeSlotsOnSelectedMonth
    ? (day) =>
        !timeSlotsOnSelectedMonth.find((timeSlot) =>
          isDayMomentInsideRange(day, timeSlot.attributes.start, timeSlot.attributes.end, timeZone)
        )
    : () => false;
  const placeholderTime = localizeAndFormatTime(intl, timeZone, findNextBoundary(timeZone, TODAY));

  const availableStartTimes = getAvailableStartTimes(
    intl,
    timeZone,
    bookingStartDate,
    timeSlotsOnSelectedDate,
    duration,
    allBookingdetails,
    listing,
    category
  );

  const firstAvailableStartTime =
    availableStartTimes.length > 0 && availableStartTimes[0] && availableStartTimes[0].timestamp
      ? availableStartTimes[0].timestamp
      : null;

  const { startTime, endDate, selectedTimeSlot } = getAllTimeValues(
    intl,
    timeZone,
    timeSlotsOnSelectedDate,
    bookingStartDate,
    bookingStartTime || firstAvailableStartTime,
    bookingEndDate || bookingStartDate,
    duration,
    allBookingdetails,
    listing,
    category
  );

  const availableEndTimes = getAvailableEndTimes(
    intl,
    timeZone,
    bookingStartTime || startTime,
    bookingEndDate || endDate,
    selectedTimeSlot,
    // bookingEndDate || bookingStartDate,
    // bookingEndDate || bookingStartDate,
    duration,
    allBookingdetails,
    listing,
    category
  );
  const isDepositAvailable = fromMongo?.sportsInfo?.find(
    (f) => f.sportName === searchParams?.category
  )?.fullFacilityDurationAndPrice?.[0]?.deposit
    ? parseInt(
        fromMongo?.sportsInfo?.find((f) => f.sportName === searchParams?.category)
          ?.fullFacilityDurationAndPrice?.[0]?.deposit
      ) > 0
    : false;

  const startTimeLabel = intl.formatMessage({ id: 'FieldDateTimeInput.startTime' });
  const endTimeLabel = intl.formatMessage({ id: 'FieldDateTimeInput.endTime' });
  return (
    <div
      className="css.rootContainer"
      tabIndex={0}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setMenueSelected((menueSelected) => ({
            time: false,
            participants: false,
            extra: false,
            reservation: false,
            facilitySize: false,
          }));
        }
      }}
    >
      {/* Facility Size */}
      <div className={css.facilityWrap}>
        <div
          className={classNames(css.facilitySizeContainer, css.halfInp, css.collapsable, {
            [css.hidefacility]: facilityType?.length === 0,
          })}
          onClick={() =>
            facilityType?.length > 0
              ? setMenueSelected((menueSelected) => ({
                  time: false,
                  participants: false,
                  extra: false,
                  reservation: false,
                  facilitySize: !menueSelected.facilitySize,
                }))
              : null
          }
        >
          <div className={css.valueContainer}>
            <div className={css.label}>FACILITY SIZE</div>
            <div className={css.velue}>
              {values.facilitySize === 'fullFacility'
                ? 'Full Facility'
                : values.facilitySize === 'halfFacility'
                ? 'Half Facility'
                : values.facilitySize === 'quaterFacility'
                ? 'Quarter Facility'
                : 'Select'}
            </div>
          </div>
          <div className={css.arrowContainer}>
            {menueSelected.facilitySize ? <UpArrow /> : <DownArrow />}
          </div>
        </div>
        {menueSelected.facilitySize && (
          <div className={css.selectContainer}>
            <div className={css.optionsList}>
              <div
                key={'fullFacility'}
                className={classNames(
                  css.option,
                  values.facilitySize === 'fullFacility' && css.optionActive
                )}
                onClick={() => {
                  form.change('sessionOption', '');
                  form.change('facilitySize', 'fullFacility');

                  setMenueSelected((menueSelected) => ({
                    ...menueSelected,
                    facilitySize: false,
                  }));
                }}
              >
                Full Facility
              </div>
              {facilityType.includes('half') && (
                <div
                  key={'halfFacility'}
                  className={classNames(
                    css.option,
                    values.facilitySize === 'halfFacility' && css.optionActive
                  )}
                  onClick={() => {
                    form.change('sessionOption', '');
                    form.change('facilitySize', 'halfFacility');
                    setMenueSelected((menueSelected) => ({
                      ...menueSelected,
                      facilitySize: false,
                    }));
                  }}
                >
                  Half Facility
                </div>
              )}

              {facilityType.includes('quater') && (
                <div
                  key={'quaterFacility'}
                  className={classNames(
                    css.option,
                    values.facilitySize === 'quaterFacility' && css.optionActive
                  )}
                  onClick={() => {
                    form.change('sessionOption', '');
                    form.change('facilitySize', 'quaterFacility');

                    setMenueSelected((menueSelected) => ({
                      ...menueSelected,
                      facilitySize: false,
                    }));
                  }}
                >
                  Quarter Facility
                </div>
              )}
            </div>
          </div>
        )}

        {/* date section */}

        <div
          className={classNames(css.calendarContiner, css.collapsable, {
            [css.hideBorder]: facilityType?.length === 0,
          })}
          onClick={() =>
            setMenueSelected((menueSelected) => ({
              time: false,
              participants: false,
              extra: false,
              reservation: false,
              facilitySize: false,
              date: !menueSelected.date,
            }))
          }
        >
          <div
            className={classNames(css.valueContainer, css.valueContainerTwo, {
              [css.noFacility]: facilityType?.length === 0,
            })}
          >
            <div className={css.label}>DATE</div>
            {/* <div className={css.velue}>{'Select'}</div> */}
            <FieldDateInput
              className={css.fieldDateInput}
              name="bookingStartDate"
              id={formId ? `${formId}.bookingStartDate` : 'bookingStartDate'}
              label={''}
              placeholderText={startDateInputProps.placeholderText}
              format={(v) =>
                v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
              }
              parse={(v) =>
                v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
              }
              isDayBlocked={isDayBlocked}
              onChange={onBookingStartDateChange}
              onPrevMonthClick={() => onMonthClick(prevMonthFn)}
              onNextMonthClick={() => onMonthClick(nextMonthFn)}
              navNext={<Next currentMonth={currentMonth} timeZone={timeZone} />}
              navPrev={<Prev currentMonth={currentMonth} timeZone={timeZone} />}
              useMobileMargins
              showErrorMessage={false}
              // validate={bookingDateRequired('Required')}
              onClose={(event) =>
                setCurrentMonth(getMonthStartInTimeZone(event?.date ?? TODAY, props.timeZone))
              }
            />
          </div>
          {/* <div className={css.arrowContainer}>
            {menueSelected.date ? <UpArrow /> : <DownArrow />}
          </div> */}
        </div>
      </div>
      <div className={css.halfInpGroup}>
        {/* Start time selecetion section */}

        <div
          className={classNames(css.calendarContiner, css.halfInp, css.collapsable)}
          onClick={() =>
            setMenueSelected((menueSelected) => ({
              time: false,
              participants: false,
              extra: false,
              reservation: false,
              facilitySize: false,
              date: false,
              start: !menueSelected.start,
            }))
          }
        >
          <div className={css.timeContainer}>
            <div className={css.label}>START</div>
            {/* <div className={css.velue}>{'Select'}</div> */}
            <FieldSelect
              name="bookingStartTime"
              id={formId ? `${formId}.bookingStartTime` : 'bookingStartTime'}
              className={bookingStartDate ? css.fieldSelect : css.fieldSelectDisabled}
              selectClassName={bookingStartDate ? css.select : css.selectDisabled}
              // label={startTimeLabel}
              disabled={startTimeDisabled}
              onChange={onBookingStartTimeChange}
            >
              {bookingStartDate ? (
                availableStartTimes.map((p) => (
                  <option key={p.timeOfDay} value={p.timestamp}>
                    {moment(p.timestamp).format('hh:mm A')}
                  </option>
                ))
              ) : (
                <option>{placeholderTime}</option>
              )}
            </FieldSelect>
          </div>
          {/* <div className={css.arrowContainer}>
            {menueSelected.start ? <UpArrow /> : <DownArrow />}
          </div> */}
          {/* <div className={css.selectContainer}> */}

          {/* </div> */}
        </div>

        {/* End time section || quantity section */}

        {listingDetails?.durationType === 'session' ? (
          <div
            className={classNames(css.continer, css.halfInp, css.collapsable)}
            onClick={() =>
              setMenueSelected((menueSelected) => ({
                time: false,
                participants: false,
                extra: false,
                reservation: false,
                facilitySize: false,
                date: false,
                start: false,
                end: false,
                sessionOption: !menueSelected.sessionOption,
              }))
            }
          >
            <div className={classNames(css.sessionContainer)}>
              {/* <div className={classNames(css.timeContainer, css.option)}> */}
              <div className={css.label}>{listingDetails.durationName}</div>
              <FieldSelect
                name="sessionOption"
                id={formId ? `${formId}.sessionOption` : 'sessionOption'}
                className={bookingStartDate ? css.fieldSelect : css.fieldSelectDisabled}
                selectClassName={bookingStartDate ? css.select : css.selectDisabled}
                // label={endTimeLabel}
                // disabled={endTimeDisabled}
              >
                <option key="select" value="">
                  {'Select'}
                </option>

                {values.facilitySize === 'fullFacility'
                  ? fullFacilityDurationAndPrice?.map(({ quantity }) => (
                      <option key={quantity} value={quantity}>
                        {quantity}
                      </option>
                    ))
                  : values.facilitySize === 'halfFacility'
                  ? halfFacilityDurationAndPrice?.map(({ quantity }) => (
                      <option key={quantity} value={quantity}>
                        {quantity}
                      </option>
                    ))
                  : values.facilitySize === 'quaterFacility'
                  ? quaterFacilityDurationAndPrice?.map(({ quantity }) => (
                      <option key={quantity} value={quantity}>
                        {quantity}
                      </option>
                    ))
                  : null}
              </FieldSelect>
            </div>
          </div>
        ) : (
          <div
            className={classNames(css.calendarContiner, css.halfInp, css.collapsable)}
            onClick={() =>
              setMenueSelected((menueSelected) => ({
                time: false,
                participants: false,
                extra: false,
                reservation: false,
                facilitySize: false,
                date: false,
                start: false,
                end: !menueSelected.end,
              }))
            }
          >
            <div className={classNames(css.timeContainer, css.timeContainertwo)}>
              <div className={css.label}>END</div>
              <FieldSelect
                name="bookingEndTime"
                id={formId ? `${formId}.bookingEndTime` : 'bookingEndTime'}
                className={bookingStartDate ? css.fieldSelect : css.fieldSelectDisabled}
                selectClassName={bookingStartDate ? css.select : css.selectDisabled}
                // label={endTimeLabel}
                disabled={endTimeDisabled}
              >
                {bookingStartDate && (bookingStartTime || startTime) ? (
                  (availableEndTimes || []).map((p) => (
                    <option
                      key={p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                      value={p.timestamp}
                    >
                      {p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                    </option>
                  ))
                ) : (
                  <option>{placeholderTime}</option>
                )}
              </FieldSelect>
            </div>
          </div>
        )}
      </div>

      {/* Participants section section */}

      {groupBookingInfo &&
        isArray(groupBookingInfo.options) &&
        groupBookingInfo.options.includes('selectAttendees') && (
          <div
            className={classNames(css.ParticipantsContainer, css.collapsable, {
              [css.hideBorderBottom]:
                !isDepositAvailable &&
                !(
                  !isEmpty(rentalExtraEquipment) &&
                  rentalExtraEquipment[0]?.equipmentName &&
                  rentalExtraEquipment[0]?.maxQuantity
                ),
            })}
            onClick={() =>
              setMenueSelected((menueSelected) => ({
                time: false,
                extra: false,
                reservation: false,
                participants: !menueSelected.participants,
                facilitySize: false,
              }))
            }
          >
            <div className={css.valueContainer}>
              <div className={css.label}>PARTICIPANTS</div>
              <div className={css.velue}>{`Adult${values.attendee?.quantity || 0 > 1 ? 's' : ''}:${
                values.attendee?.quantity || ''
              } ${
                values.participants && values.participants['Children']?.quantity
                  ? '. Children:' + values.participants['Children']?.quantity
                  : ''
              } ${
                values.participants && values.participants['Infants']?.quantity
                  ? '. Infant:' + values.participants['Infants']?.quantity
                  : ''
              }`}</div>
            </div>
            <div className={css.arrowContainer}>
              {menueSelected.participants ? <UpArrow /> : <DownArrow />}
            </div>
          </div>
        )}
      {menueSelected.participants && (
        <div className={css.selectContainer}>
          <div className={css.optionsList}>
            <div
              key={'attendee'}
              className={classNames(css.option)}
              // onClick={() => handleOptionClick(option)}
            >
              <div className={css.AttendeeWrap}>
                <div className={css.checkBox}></div>
                <div className={css.equipmentName}>Adult</div>
              </div>
              <div className={css.rangeContainer}>
                <div className={classNames(css.rangeDiv, attendeeErr && css.invalidInp)}>
                  <button
                    type="button"
                    onClick={() => {
                      if (
                        (values.attendee &&
                          values.attendee['quantity'] > groupBookingInfo?.minCapacity) ||
                        0
                      ) {
                        form.change(`attendee['quantity']`, values.attendee['quantity'] - 1);
                      }
                    }}
                  >
                    -
                  </button>

                  <input
                    type="text"
                    name={`attendee['quantity']`}
                    value={
                      values.attendee && typeof values.attendee === 'object'
                        ? values.attendee['quantity'] || 0
                        : 0
                    }
                    onChange={(e) => {
                      if (e.target.value <= (groupBookingInfo?.maxCapacity || 0)) {
                        //   // if (parseInt(e.target.value) > 0) {
                        //   //   form.change(`attendee['quantity']`, parseInt(e.target.value).toString());
                        //   // } else {
                        //   //   form.change(`attendee['quantity']`, e.target.value);
                        //   // }
                        form.change(`attendee['quantity']`, e.target.value);
                      }
                    }}
                  />

                  <button
                    type="button"
                    onClick={() => {
                      if (
                        parseInt(values.attendee ? values.attendee['quantity'] : 0) +
                          parseInt(
                            values?.participants?.Children?.quantity
                              ? values?.participants?.Children['quantity']
                              : 0
                          ) +
                          parseInt(
                            values?.participants?.Infants?.quantity
                              ? values?.participants?.Infants['quantity']
                              : 0
                          ) <
                        // (maxAttendee || 0)
                        (groupBookingInfo?.maxCapacity || 0)
                      ) {
                        form.change(
                          `attendee['quantity']`,
                          (values.attendee && typeof values.attendee === 'object'
                            ? Number(values.attendee['quantity']) || 0
                            : 0) + 1
                        );
                      }
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            {validExtraParticipants.map((participant) => (
              <div
                key={participant}
                className={classNames(css.option)}
                // onClick={() => handleOptionClick(option)}
              >
                <div className={css.AttendeeWrap}>
                  <div className={css.checkBox}></div>
                  <div className={css.equipmentName}>{participant}</div>
                </div>
                <div className={css.rangeContainer}>
                  <div className={classNames(css.rangeDiv)}>
                    <button
                      type="button"
                      onClick={() => {
                        if (
                          values.participants &&
                          values.participants[participant] &&
                          values.participants[participant]['quantity'] > 0
                        ) {
                          form.change(
                            `participants[${participant}]['quantity']`,
                            values.participants[participant]['quantity'] - 1
                          );
                        }
                      }}
                    >
                      -
                    </button>

                    <input
                      type="text"
                      name={`participants[${participant}]['quantity']`}
                      value={
                        values.participants &&
                        values.participants[participant] &&
                        typeof values.participants[participant] === 'object'
                          ? values.participants[participant]['quantity'] || 0
                          : 0
                      }
                      onChange={(e) => {
                        // form.change(`participants[${participant}]['quantity']`, e.target.value)
                        if (parseInt(e.target.value) > 0) {
                          form.change(
                            `participants[${participant}]['quantity']`,
                            parseInt(e.target.value).toString()
                          );
                        } else {
                          form.change(`participants[${participant}]['quantity']`, e.target.value);
                        }
                      }}
                    />

                    <button
                      type="button"
                      onClick={() => {
                        form.change(
                          `participants[${participant}]['quantity']`,
                          (values.participants &&
                          values.participants[participant] &&
                          typeof values.participants[participant] === 'object'
                            ? Number(values.participants[participant]['quantity']) || 0
                            : 0) + 1
                        );
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            ))}
            {groupBookingInfo &&
              isArray(groupBookingInfo.options) &&
              groupBookingInfo.maxCapacity &&
              groupBookingInfo.minCapacity &&
              groupBookingInfo.options.includes('showMinMaxCapacity') && (
                <div className={css.msgContainer}>
                  Min: {groupBookingInfo.minCapacity || 1} and Max:{' '}
                  {groupBookingInfo.maxCapacity || 1}
                </div>
              )}
          </div>
        </div>
      )}

      {/* extras selection section */}

      {!isEmpty(rentalExtraEquipment) &&
        rentalExtraEquipment[0]?.equipmentName &&
        rentalExtraEquipment[0]?.maxQuantity && (
          <div
            className={classNames(css.extraContainer, css.collapsable, {
              [css.hideBorderBottom]: !isDepositAvailable,
            })}
            onClick={() =>
              setMenueSelected((menueSelected) => ({
                time: false,
                participants: false,
                reservation: false,
                extra: !menueSelected.extra,
                facilitySize: false,
              }))
            }
          >
            <div className={css.valueContainer}>
              <div className={css.label}>EXTRAS</div>
              <div className={css.velue}>
                {values.extra
                  ? Object.keys(values.extra).map((ext) => {
                      const { quantity } = values.extra[ext] || {};
                      return `${ext} : ${quantity} . `;
                    })
                  : 'Select'}
              </div>
            </div>
            <div className={css.arrowContainer}>
              {menueSelected.extra ? <UpArrow /> : <DownArrow />}
            </div>
          </div>
        )}
      {menueSelected.extra && (
        <div className={css.selectContainer}>
          <div className={css.optionsList}>
            {isArray(rentalExtraEquipment) &&
              rentalExtraEquipment.map(
                ({ equipmentName, maxQuantity }) =>
                  equipmentName &&
                  maxQuantity && (
                    <div
                      key={equipmentName}
                      className={classNames(css.option)}
                      // onClick={() => handleOptionClick(option)}
                    >
                      <div className={css.checkBox}></div>
                      <div className={css.equipmentName}>{equipmentName}</div>
                      <div className={css.rangeContainer}>
                        <div
                          className={classNames(
                            css.rangeDiv,
                            extraErr[`extra[${equipmentName}]['quantity']`] && css.invalidInp
                          )}
                        >
                          <button
                            type="button"
                            onClick={() => {
                              if (
                                values.extra &&
                                values.extra[equipmentName] &&
                                values.extra[equipmentName]['quantity'] > 0
                              ) {
                                if (
                                  Number(values.extra[equipmentName]['quantity']) - 1 <=
                                  maxQuantity
                                ) {
                                  setExtraErr((extraErr) => ({
                                    ...extraErr,
                                    [`extra[${equipmentName}]['quantity']`]: false,
                                  }));
                                }
                                form.change(
                                  `extra[${equipmentName}]['quantity']`,
                                  values.extra[equipmentName]['quantity'] - 1
                                );
                              }
                            }}
                          >
                            -
                          </button>

                          <input
                            type="text"
                            name={`extra[${equipmentName}]['quantity']`}
                            value={
                              values.extra &&
                              values.extra[equipmentName] &&
                              typeof values.extra[equipmentName] === 'object'
                                ? values.extra[equipmentName]['quantity'] || 0
                                : 0
                            }
                            onChange={(e) => {
                              if (e.target.value > maxQuantity) {
                                // setExtraErr((extraError) => ({
                                //   ...extraError,
                                //   [`extra[${equipmentName}]['quantity']`]: true,
                                // }));
                              } else {
                                if (parseInt(e.target.value) > 0) {
                                  form.change(
                                    `extra[${equipmentName}]['quantity']`,
                                    parseInt(e.target.value).toString()
                                  );
                                } else {
                                  form.change(
                                    `extra[${equipmentName}]['quantity']`,
                                    e.target.value
                                  );
                                }

                                setExtraErr((extraError) => ({
                                  ...extraError,
                                  [`extra[${equipmentName}]['quantity']`]: false,
                                }));
                              }
                              // form.change(`extra[${equipmentName}]['quantity']`, e.target.value);
                            }}
                          />

                          <button
                            type="button"
                            onClick={() => {
                              if (
                                (values.extra &&
                                values.extra[equipmentName] &&
                                typeof values.extra[equipmentName] === 'object'
                                  ? Number(values.extra[equipmentName]['quantity']) || 0
                                  : 0) < maxQuantity
                              ) {
                                setExtraErr((extraErr) => ({
                                  ...extraErr,
                                  [`extra[${equipmentName}]['quantity']`]: false,
                                }));
                                form.change(
                                  `extra[${equipmentName}]['quantity']`,
                                  (values.extra &&
                                  values.extra[equipmentName] &&
                                  typeof values.extra[equipmentName] === 'object'
                                    ? Number(values.extra[equipmentName]['quantity']) || 0
                                    : 0) + 1
                                );
                              }
                            }}
                          >
                            +
                          </button>
                        </div>
                        <div className={css.quantitiyMaxMsg}>{`Max: ${maxQuantity}`}</div>
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
      )}

      {/* reservation selecetion section */}
      <div
        className={classNames(css.reservationContainer, css.collapsable, {
          [css.hidePayment]: !isDepositAvailable,
        })}
        onClick={() =>
          isDepositAvailable
            ? setMenueSelected((menueSelected) => ({
                time: false,
                participants: false,
                extra: false,
                reservation: !menueSelected.reservation,
                facilitySize: false,
              }))
            : null
        }
      >
        <div className={css.valueContainer}>
          <div className={css.label}>RESERVATION METHOD</div>
          <div className={css.velue}>
            {values.reservationMethod === 'fullPayment'
              ? 'Full Payment'
              : values.reservationMethod === 'partialPayment'
              ? 'Partial Payment'
              : 'Select'}
          </div>
        </div>
        <div className={css.arrowContainer}>
          {menueSelected.reservation ? <UpArrow /> : <DownArrow />}
        </div>
      </div>
      {menueSelected.reservation && (
        <div className={css.selectContainer}>
          <div className={css.optionsList}>
            <div
              key={'fullPayment'}
              className={classNames(
                css.option,
                values.reservationMethod === 'fullPayment' && css.optionActive
              )}
              onClick={() => {
                form.change('reservationMethod', 'fullPayment');
                setMenueSelected((menueSelected) => ({
                  ...menueSelected,
                  reservation: false,
                }));
              }}
            >
              Full Payment
            </div>
            {isDepositAvailable && (
              <div
                key={'partialPayment'}
                className={classNames(
                  css.option,
                  values.reservationMethod === 'partialPayment' && css.optionActive
                )}
                onClick={() => {
                  form.change('reservationMethod', 'partialPayment');
                  setMenueSelected((menueSelected) => ({
                    ...menueSelected,
                    reservation: false,
                  }));
                }}
              >
                Partial Payment
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default compose(withRouter)(CustomFacilityBookingForm);
