import React, { Component, useEffect, useRef, useState } from 'react';
import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug, parse } from '../../util/urlHelpers';
import config from '../../config';
import { IconSpinner, NamedLink, ResponsiveImage } from '../../components';
import { AiOutlineHeart, AiFillStar } from 'react-icons/ai';
import { ReactComponent as HeartSvg } from '../../assets/icons/Heart.svg';
import css from './ListingCard.module.css';
import moment from 'moment';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import axios from 'axios';
import { createInstance } from '../../util/sdkLoader';
import { apiBaseUrl } from '../../util/api';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
const IS_PRODUCTION_ENVIRONMENT = process.env.REACT_APP_RENDER_CARD_SLOTS === 'true';
const MIN_LENGTH_FOR_LONG_WORDS = 10;
const listingTypeOption = [
  { label: 'Sports Session', key: 'sessions', value: 'sessions' },
  { label: 'Fitness Class', key: 'classes', value: 'classes' },
  { label: 'Facility Hire', key: 'facilityHire', value: 'facilityHire' },
];
const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find((c) => c.key === key);
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardVenueComponent = (props) => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    setActiveListing,
    currentUser,
    toggleFavouritesButton,
    isFavourite,
    history,
    urlQueryParams,
    location = {},
  } = props;
  const [isSpinner, setIsSpinner] = useState(true);
  const [rawDate, setrawDate] = useState([]);
  const sdkInstance = useRef(createInstance({ clientId: config.sdk.clientId }));
  const isOwnListing = currentUser?.id?.uuid === listing?.author?.id?.uuid;
  const {
    attributes: {
      publicData: {
        venueName,
        venueId,
        venueAddress,
        category,
        subCategory,
        fromMongo = {},
        activity,
        expiry,
      },
    },
  } = listing;

  const searchParams = parse(location.search);
  const listingType = activity
    ? listingTypeOption.find((l) => l.value === activity[0])
    : { label: 'Not set' };
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  useEffect(() => {
    if (publicData?.activity?.includes('sessions') || publicData?.activity?.includes('classes')) {
      const {
        recurringScheduleTime,
        recurringScheduleFrequency,
        startDate,
        startTime,
        endDate,
        endTime,
      } = fromMongo?.scheduleDetails || {};

      const { groupBookingInfo } = fromMongo?.listingDetails || {};

      const { options = [], minCapacity } = groupBookingInfo || {};
      const shouldBlockTimeSlot = options?.includes('blockTimeSlot');
      const timeZone = listing?.attributes?.availabilityPlan?.timezone;
      let [hours = 0, minutes = 0] = endTime?.split(':')?.map(Number) || [];
      const start = moment.tz(startDate, timeZone);
      const end = !endTime
        ? endDate
          ? moment(endDate).add(23, 'h')
          : moment(startDate).add(23, 'h')
        : endDate
        ? moment(endDate)
        : moment(startDate);

      // const dates = [];
      const rawDates = [];

      switch (recurringScheduleTime) {
        case 'daily':
          while (start <= end) {
            if (start > moment())
              rawDates.push({
                start: start.clone(),
                end: start.clone().startOf('day').add(hours, 'hours').add(minutes, 'minutes'),
              });
            start.add(recurringScheduleFrequency, 'day');
          }
          break;
        case 'weekly':
          while (start <= end) {
            if (start > moment())
              rawDates.push({
                start: start.clone(),
                end: start.clone().startOf('day').add(hours, 'hours').add(minutes, 'minutes'),
              });
            start.add(7 * recurringScheduleFrequency, 'days');
          }
          break;
        case 'monthly':
          while (start <= end) {
            if (start > moment())
              rawDates.push({
                start: start.clone(),
                end: start.clone().startOf('day').add(hours, 'hours').add(minutes, 'minutes'),
              });
            start.add(recurringScheduleFrequency, 'months');
          }
          break;
        default:
          console.log(`Invalid recurringScheduleTime: ${recurringScheduleTime}`);
      }

      const fetchData = async () => {
        const { data: bookingDetails } = await axios.get(
          `${apiBaseUrl()}/api/transactions/listing/groupByStartDate/${id}?startDate=${moment()
            .clone()
            .startOf('day')
            .valueOf()}&endDate=${moment(end).clone().endOf('day').valueOf()}`,
          {
            withCredentials: true,
          }
        );
        const allBookedSlots = bookingDetails.map(({ _id: startDate }) =>
          moment(startDate).valueOf()
        );
        const availableSlotsOnly = rawDates.filter(
          ({ start }) => !allBookedSlots.includes(moment(start).valueOf())
        );
        if (IS_PRODUCTION_ENVIRONMENT && availableSlotsOnly?.length > 0) {
          const promises = availableSlotsOnly.map((d, idx) => {
            let seats;
            const params = {
              listingId: id,
              start: d.start.toDate(),
              end: d.end.toDate(),
            };
            return sdkInstance.current?.timeslots
              .query(params)
              .then((resp) => {
                seats = resp?.data?.data?.[0]?.attributes?.seats || 0;
                availableSlotsOnly[idx] = { ...d, seats };
              })
              .catch((e) => (availableSlotsOnly[idx] = { ...d, seats: 0, seatError: true }));
          });
          await Promise.all(promises);
          setrawDate(availableSlotsOnly);
          setIsSpinner(false);
        }
        setIsSpinner(false);
        return bookingDetails;
      };

      const fetchDataForNoBlock = async () => {
        if (IS_PRODUCTION_ENVIRONMENT && rawDates?.length > 0) {
          const promises = rawDates.map((d, idx) => {
            let seats;
            const params = {
              listingId: id,
              start: d.start.toDate(),
              end: d.end.toDate(),
            };
            return sdkInstance.current?.timeslots
              .query(params)
              .then((resp) => {
                seats = resp?.data?.data?.[0]?.attributes?.seats || 0;
                rawDates[idx] = { ...d, seats };
              })
              .catch((e) => (rawDates[idx] = { ...d, seats: 0, seatError: true }));
          });
          await Promise.all(promises);
          setrawDate(rawDates);
          setIsSpinner(false);
        }
        setIsSpinner(false);
      };
      if (shouldBlockTimeSlot) {
        fetchData();
      } else {
        fetchDataForNoBlock();
      }
    }

    // form.change('attendee', { quantity: listingDetails?.groupBookingInfo?.minCapacity });
  }, [fromMongo?.scheduleDetails]);
  const firstImage =
    activity?.[0] === 'facilityHire'
      ? typeof urlQueryParams?.pub_category === 'undefined'
        ? listing?.attributes.publicData.fromMongo?.venueId?.venueImages.length > 0
          ? listing?.attributes.publicData.fromMongo?.venueId?.venueImages[0]
          : ''
        : currentListing.images?.filter((i) =>
            publicData?.facilityImage?.[urlQueryParams?.pub_category]?.includes(i.id.uuid)
          )?.[0]
      : currentListing.images && currentListing.images.length > 0
      ? currentListing.images[0]
      : null;
  const certificateOptions = findOptionsForSelectFilter('certificate', filtersConfig);
  const certificate = publicData
    ? getCertificateInfo(certificateOptions, publicData.certificate)
    : null;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const facilityPriceArray =
    activity?.[0] === 'facilityHire'
      ? listing?.attributes.publicData.fromMongo?.sportsInfo
          ?.map((s) =>
            s.fullFacilityDurationAndPrice?.length > 0
              ? s.fullFacilityDurationAndPrice?.[0].fullPrice
              : -1
          )
          .filter((f) => f >= 0)
      : [];
  const facilityPrice =
    facilityPriceArray?.length > 0 && facilityPriceArray.reduce((a, b) => Math.min(a, b));
  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';
  const minCapacity = fromMongo?.listingDetails?.groupBookingInfo?.minCapacity;
  const slotsDiv =
    (publicData?.activity?.includes('sessions') || publicData?.activity?.includes('classes')) &&
    rawDate?.length > 0 ? (
      rawDate
        // .filter((date) => {
        //   // checking whether there is atleast minimum seat available for the day or not if not then will not show the day in frontend
        //   return IS_PRODUCTION_ENVIRONMENT
        //     ? date?.seats >= fromMongo?.listingDetails?.groupBookingInfo?.minCapacity
        //     : true;
        // })
        .map((n, i) => {
          const { start, end, seats } = n;
          const formattedDate = start && start.format('ddd, MMM D');
          const startTimeFormatted =
            start && start.clone().tz(getDefaultTimeZoneOnBrowser()).format('h:mmA');
          const endTimeFormatted =
            end && end.clone().tz(getDefaultTimeZoneOnBrowser()).format('h:mmA');

          const isSlotsFull = typeof minCapacity === 'number' ? seats < minCapacity : false;
          return (
            <button
              type="button"
              className={classNames(css.bookingBtn, { [css.disabledBookingBtn]: isSlotsFull })}
              key={i}
            >
              {/* {console.log(n.start.toDate(), id)} */}
              <div className={css.listingbutton}>
                <div>
                  <span>{formattedDate}</span>
                </div>

                <div>
                  <span>
                    {/* {startTimeFormatted}-{n.end.format(endTimeFormatted)} */}
                    {startTimeFormatted}-{endTimeFormatted}
                  </span>
                </div>
              </div>
            </button>
          );
        })
    ) : (
      <></>
    );

  return (
    <div
      className={classes}
      onClick={() => {
        const params = {
          id,
          slug,
        };
        // location.pathname !== '/' &&
        history.push(createResourceLocatorString('ListingPage', routeConfiguration(), params, {}));
      }}
    >
      <div className={css.imgDetailRow}>
        <div
          className={css.threeToTwoWrapper}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          <div
            className={classNames(css.aspectWrapper, {
              [css.fullwh]: typeof firstImage === 'string',
            })}
          >
            {typeof firstImage === 'string' ? (
              <img src={firstImage} alt="" />
            ) : (
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
                sizes={renderSizes}
              />
            )}

            {!isOwnListing ? (
              <button
                className={css.favicon}
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  if (!currentUser) {
                    return history.push({
                      pathname: '/login',
                      state: {
                        from: `${location.pathname}${location.search}${location.hash}`,
                      },
                    });
                  }
                  !!currentUser && toggleFavouritesButton(currentUser.id.uuid, listing.id.uuid);
                }}
              >
                <HeartSvg
                  className={classNames(
                    { [css.unfavBtn]: isFavourite },
                    {
                      [css.favBtn]: !isFavourite,
                    }
                  )}
                />
              </button>
            ) : null}
          </div>
        </div>
        <div className={css.info}>
          <div className={css.mainInfo}>
            <div className={css.title}>
              {/* {richText(
                'SUN Intermediate Doubles Tennis SUN Intermediate Doubles Tennis SUN Intermediate Doubles Tennis',
                {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                }
              )} */}
              <div className={css.titleContainer}>{title}</div>
              {/* <span className={css.ratingSec}>
                5.0
                <AiFillStar />
              </span> */}
            </div>

            <div className={css.srttxt}>
              <span className={css.tagscont}>{listingType?.label}</span>

              <p>
                <span
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  //   history.push(
                  //     createResourceLocatorString(
                  //       'VenueDetail',
                  //       routeConfiguration(),
                  //       { id: venueId },
                  //       {}
                  //     )
                  //   );
                  // }}
                  className={css.venueNme}
                >
                  {venueName}
                </span>
                {/* <span>{venueAddress}</span> */}
                <span>{fromMongo?.venueId?.venueDisplayAddress}</span>
              </p>
            </div>
            {activity?.[0] === 'facilityHire' ? (
              typeof urlQueryParams?.pub_category !== 'undefined' ? (
                category
                  ?.filter((f) => f === urlQueryParams?.pub_category)
                  ?.map((category, i) => (
                    <div className={css.listDiv} key={i}>
                      <span className={css.listsText}>{category}</span>
                      {subCategory[i] && <span className={css.circleDiv}></span>}
                      <span className={css.listsText}>{subCategory[i]}</span>
                    </div>
                  ))
              ) : (
                <div className={css.listDiv}>
                  {publicData?.category?.map((c, i) => (
                    <>
                      <span className={css.listsText}>
                        {i === publicData?.category?.length - 1 ? c : `${c},`}
                      </span>
                    </>
                  ))}

                  {publicData?.subCategory?.length > 0 &&
                    publicData?.subCategory?.some(Boolean) && (
                      <span className={css.circleDiv}></span>
                    )}
                  {publicData?.subCategory?.map((sc) => (
                    <>
                      <span className={css.listsText}>{sc}</span>
                    </>
                  ))}
                  {publicData?.fromMongo?.facilityFormat && <span className={css.circleDiv}></span>}

                  <span className={css.listsText}>{publicData?.fromMongo?.facilityFormat}</span>
                  {publicData?.fromMongo?.facilitySurface && (
                    <span className={css.circleDiv}></span>
                  )}
                  <span className={css.listsText}>{publicData?.fromMongo?.facilitySurface}</span>
                </div>
              )
            ) : (
              category?.map((category, i) => (
                <div className={css.listDiv} key={i}>
                  <span className={css.listsText}>{category}</span>
                  {subCategory[i] && <span className={css.circleDiv}></span>}
                  <span className={css.listsText}>{subCategory[i]}</span>
                  <span className={css.circleDiv}></span>
                  <span className={css.listsText}>
                    {' '}
                    {publicData?.difficulty === 'medium'
                      ? 'Intermediate'
                      : publicData?.difficulty === 'easy'
                      ? 'Beginner'
                      : publicData?.difficulty === 'hard'
                      ? 'Advanced'
                      : publicData?.difficulty === 'allLevels'
                      ? 'All Levels'
                      : ''}
                  </span>
                  <span className={css.circleDiv}></span>
                  <span className={css.listsText}>
                    {' '}
                    {publicData?.gender === 'male'
                      ? 'Men’s'
                      : publicData?.gender === 'female'
                      ? 'Women’s'
                      : publicData?.gender === 'mixed'
                      ? 'Mixed'
                      : ''}
                  </span>
                </div>
              ))
            )}
          </div>
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {activity?.[0] === 'facilityHire' && typeof urlQueryParams?.pub_category
                ? `KES ${Number.isInteger(facilityPrice) ? `${facilityPrice}.00` : facilityPrice}`
                : formattedPrice}
            </div>
            <div className={css.perUnit}>
              {activity?.[0] === 'classes' || activity?.[0] === 'sessions'
                ? '/person'
                : activity?.[0] === 'facilityHire'
                ? searchParams?.pub_category
                  ? fromMongo.sportsInfo
                      .find(({ sportName }) => sportName === searchParams.pub_category)
                      ?.groupBookingInfo?.options.includes('multiPlyChargesByAttendee') ||
                    fromMongo.sportsInfo
                      .find(({ sportName }) => sportName === searchParams.pub_category)
                      ?.groupBookingInfo?.options?.includes('multiPlyDepositByAttendee')
                    ? '/hour/person'
                    : '/hour'
                  : 'onwards'
                : ''}
            </div>
          </div>
        </div>
      </div>
      {activity?.[0] === 'sessions' || activity?.[0] === 'classes' ? (
        <>
          {isSpinner ? (
            <IconSpinner className={css.spinner} />
          ) : (
            <div className={css.bookingDiv}>
              {rawDate?.length >= 1 ? slotsDiv : <p>No slots left</p>}
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

ListingCardVenueComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardVenueComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardVenueComponent);
