import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import toast from 'react-hot-toast';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import { isChangePasswordWrongPassword } from '../../util/errors';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import { ReactComponent as View } from '../../assets/icons/PasswordView.svg';
import { ReactComponent as Hide } from '../../assets/icons/PasswordHide.svg';
import css from './PasswordChangeForm.module.css';

const RESET_TIMEOUT = 800;

class PasswordChangeFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResetPasswordMessage: false,
      isOldPasswordHidden: true,
      isNewPasswordHidden: true,
      isNewConfirmPasswordHidden: true,
    };
    this.resetTimeoutId = null;
    this.submittedValues = {};
    this.passwordMatch = false;
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.notify = () => toast.success('Password updated successfully');
  }

  componentWillUnmount() {
    window.clearTimeout(this.resetTimeoutId);
  }

  handleResetPassword() {
    this.setState({ showResetPasswordMessage: true });
    const email = this.props.currentUser.attributes.email;

    this.props.onResetPassword(email);
  }
  toggleOldShow = () => {
    this.setState((prevState) => ({ isOldPasswordHidden: !prevState.isOldPasswordHidden }));
  };
  toggleNewShow = () => {
    this.setState((prevState) => ({ isNewPasswordHidden: !prevState.isNewPasswordHidden }));
  };
  toggleconfirmNewShow = () => {
    this.setState((prevState) => ({
      isNewConfirmPasswordHidden: !prevState.isNewConfirmPasswordHidden,
    }));
  };

  render() {
    return (
      <>
        <FinalForm
          {...this.props}
          render={(fieldRenderProps) => {
            const {
              rootClassName,
              className,
              formId,
              changePasswordError,
              currentUser,
              handleSubmit,
              inProgress,
              resetPasswordInProgress,
              intl,
              invalid,
              pristine,
              ready,
              form,
              values,
            } = fieldRenderProps;

            const user = ensureCurrentUser(currentUser);

            const isPasswordMatched = values.newPassword === values.newConfirmPassword;
            const isPasswordChangeWithSamePassword = values.oldPassword === values.newPassword;

            if (!user.id) {
              return null;
            }

            // New password
            const newPasswordLabel = intl.formatMessage({
              id: 'PasswordChangeForm.newPasswordLabel',
            });
            const newPasswordPlaceholder = intl.formatMessage({
              id: 'PasswordChangeForm.newPasswordPlaceholder',
            });
            const newPasswordRequiredMessage = intl.formatMessage({
              id: 'PasswordChangeForm.newPasswordRequired',
            });
            const newPasswordRequired = validators.requiredStringNoTrim(newPasswordRequiredMessage);

            const passwordMinLengthMessage = intl.formatMessage(
              {
                id: 'PasswordChangeForm.passwordTooShort',
              },
              {
                minLength: validators.PASSWORD_MIN_LENGTH,
              }
            );
            const passwordMaxLengthMessage = intl.formatMessage(
              {
                id: 'PasswordChangeForm.passwordTooLong',
              },
              {
                maxLength: validators.PASSWORD_MAX_LENGTH,
              }
            );

            //Old Password
            const oldPasswordLabel = intl.formatMessage({
              id: 'PasswordChangeForm.oldPasswordLabel',
            });
            const oldPasswordPlaceholder = intl.formatMessage({
              id: 'PasswordChangeForm.oldPasswordPlaceholder',
            });

            const oldPasswordRequiredMessage = intl.formatMessage({
              id: 'PasswordChangeForm.oldPasswordRequired',
            });

            const oldPasswordRequired = validators.requiredStringNoTrim(oldPasswordRequiredMessage);

            //New Confirm Password

            const newConfirmPasswordLabel = intl.formatMessage({
              id: 'PasswordChangeForm.newConfirmPasswordLabel',
            });
            const newConfirmPasswordPlaceholder = intl.formatMessage({
              id: 'PasswordChangeForm.newConfirmPasswordPlaceholder',
            });

            const newConfirmPasswordRequiredMessage = intl.formatMessage({
              id: 'PasswordChangeForm.newConfirmPasswordRequired',
            });

            const newConfirmPasswordRequired = validators.requiredStringNoTrim(
              newConfirmPasswordRequiredMessage
            );

            const passwordMinLength = validators.minLength(
              passwordMinLengthMessage,
              validators.PASSWORD_MIN_LENGTH
            );
            const passwordMaxLength = validators.maxLength(
              passwordMaxLengthMessage,
              validators.PASSWORD_MAX_LENGTH
            );

            const passwordFailedMessage = intl.formatMessage({
              id: 'PasswordChangeForm.passwordFailed',
            });
            const passwordTouched = this.submittedValues.currentPassword !== values.currentPassword;
            const passwordErrorText = isChangePasswordWrongPassword(changePasswordError)
              ? passwordFailedMessage
              : null;

            const confirmClasses = classNames(css.confirmChangesSection, {
              [css.confirmChangesSectionVisible]: !pristine,
            });

            const genericFailure =
              changePasswordError && !passwordErrorText ? (
                <span className={css.error}>
                  <FormattedMessage id="PasswordChangeForm.genericFailure" />
                </span>
              ) : passwordErrorText ? (
                <span className={css.error}>
                  <FormattedMessage id="PasswordChangeForm.passwordFailed" />
                </span>
              ) : null;

            const submittedOnce = Object.keys(this.submittedValues).length > 0;
            const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
            const classes = classNames(rootClassName || css.root, className);
            const submitDisabled =
              invalid ||
              inProgress ||
              pristineSinceLastSubmit ||
              !isPasswordMatched ||
              isPasswordChangeWithSamePassword;

            const isSocialSignUp = currentUser?.attributes?.profile?.publicData?.isSocialSignUp;

            const sendPasswordLink = (
              <span className={css.helperLink} onClick={this.handleResetPassword} role="button">
                <FormattedMessage id="PasswordChangeForm.resetPasswordLinkText" />
              </span>
            );

            const resendPasswordLink = (
              <span className={css.helperLink} onClick={this.handleResetPassword} role="button">
                <FormattedMessage id="PasswordChangeForm.resendPasswordLinkText" />
              </span>
            );

            const resetPasswordLink =
              this.state.showResetPasswordMessage || resetPasswordInProgress ? (
                <>
                  <FormattedMessage
                    id="PasswordChangeForm.resetPasswordLinkSent"
                    values={{
                      email: <span className={css.emailStyle}>{currentUser.attributes.email}</span>,
                    }}
                  />{' '}
                  {resendPasswordLink}
                </>
              ) : (
                sendPasswordLink
              );

            return (
              <Form
                className={classes}
                onSubmit={(e) => {
                  e.preventDefault();
                  this.submittedValues = values;
                  handleSubmit(values)
                    .then(() => {
                      form.reset();
                      form.change('oldPassword', undefined);
                      form.resetFieldState('oldPassword');
                      form.change('newPassword', undefined);
                      form.resetFieldState('newPassword');
                      form.change('newConfirmPassword', undefined);
                      form.resetFieldState('newConfirmPassword');
                      this.notify();
                      this.resetTimeoutId = window.setTimeout(form.reset, RESET_TIMEOUT);
                    })
                    .catch((err) => {
                      console.log(221, err.message);
                      // Error is handled in duck file already.
                    });
                }}
              >
                <div
                  className={css.contactDetailsSection}
                  style={
                    !currentUser?.attributes?.profile?.protectedData?.passwordFlowSignup
                      ? { background: '#F5F5F5' }
                      : {}
                  }
                >
                  <div
                    className={classNames(css.passwordDiv, {
                      [css.blurField]: currentUser?.attributes?.profile?.publicData?.isSocialSignUp,
                    })}
                  >
                    <FieldTextInput
                      type={this.state.isOldPasswordHidden ? 'password' : 'text'}
                      id={formId ? `${formId}.oldPassword` : 'oldPassword'}
                      name="oldPassword"
                      autoComplete="old-password"
                      label={oldPasswordLabel}
                      placeholder={oldPasswordPlaceholder}
                      validate={validators.composeValidators(
                        oldPasswordRequired,
                        passwordMinLength,
                        passwordMaxLength
                      )}
                      disabled={isSocialSignUp}
                    />
                    {this.state.isOldPasswordHidden ? (
                      <Hide
                        className={classNames(css.hide, {
                          [css.hideIcon]:
                            currentUser?.attributes?.profile?.publicData?.isSocialSignUp,
                        })}
                        onClick={this.toggleOldShow}
                      />
                    ) : (
                      <View
                        className={classNames(css.view, {
                          [css.hideIcon]:
                            currentUser?.attributes?.profile?.publicData?.isSocialSignUp,
                        })}
                        onClick={this.toggleOldShow}
                      />
                    )}
                  </div>
                  <div
                    className={classNames(css.passwordDiv, {
                      [css.blurField]: currentUser?.attributes?.profile?.publicData?.isSocialSignUp,
                    })}
                  >
                    <FieldTextInput
                      type={this.state.isNewPasswordHidden ? 'password' : 'text'}
                      id={formId ? `${formId}.newPassword` : 'newPassword'}
                      name="newPassword"
                      autoComplete="new-password"
                      label={newPasswordLabel}
                      placeholder={newPasswordPlaceholder}
                      validate={validators.composeValidators(
                        newPasswordRequired,
                        passwordMinLength,
                        passwordMaxLength
                      )}
                      disabled={isSocialSignUp}
                    />
                    {this.state.isNewPasswordHidden ? (
                      <Hide
                        className={classNames(css.hide, {
                          [css.hideIcon]:
                            currentUser?.attributes?.profile?.publicData?.isSocialSignUp,
                        })}
                        onClick={this.toggleNewShow}
                      />
                    ) : (
                      <View
                        className={classNames(css.view, {
                          [css.hideIcon]:
                            currentUser?.attributes?.profile?.publicData?.isSocialSignUp,
                        })}
                        onClick={this.toggleNewShow}
                      />
                    )}
                  </div>

                  {values.newPassword &&
                    values.newPassword.length >= validators.PASSWORD_MIN_LENGTH &&
                    values.newPassword.length <= validators.PASSWORD_MAX_LENGTH &&
                    values.newPassword === values.newConfirmPassword && (
                      <div style={{ color: 'green' }}>passwords match</div>
                    )}

                  {values.newPassword &&
                    values.newPassword.length >= validators.PASSWORD_MIN_LENGTH &&
                    values.newPassword.length <= validators.PASSWORD_MAX_LENGTH &&
                    isPasswordChangeWithSamePassword && (
                      <div style={{ color: 'red' }}>
                        New Password and Old password can't be same
                      </div>
                    )}
                  <div
                    className={classNames(css.passwordDiv, {
                      [css.blurField]: currentUser?.attributes?.profile?.publicData?.isSocialSignUp,
                    })}
                  >
                    <FieldTextInput
                      type={this.state.isNewConfirmPasswordHidden ? 'password' : 'text'}
                      id={formId ? `${formId}.newConfirmPassword` : 'newConfirmPassword'}
                      name="newConfirmPassword"
                      autoComplete="new-password"
                      label={newConfirmPasswordLabel}
                      placeholder={newConfirmPasswordPlaceholder}
                      validate={validators.composeValidators(
                        newConfirmPasswordRequired,
                        passwordMinLength,
                        passwordMaxLength
                      )}
                      disabled={isSocialSignUp}
                    />
                    {this.state.isNewConfirmPasswordHidden ? (
                      <Hide
                        className={classNames(css.hide, {
                          [css.hideIcon]:
                            currentUser?.attributes?.profile?.publicData?.isSocialSignUp,
                        })}
                        onClick={this.toggleconfirmNewShow}
                      />
                    ) : (
                      <View
                        className={classNames(css.view, {
                          [css.hideIcon]:
                            currentUser?.attributes?.profile?.publicData?.isSocialSignUp,
                        })}
                        onClick={this.toggleconfirmNewShow}
                      />
                    )}
                  </div>
                  {values.newConfirmPassword &&
                    values.newConfirmPassword.length >= validators.PASSWORD_MIN_LENGTH &&
                    values.newConfirmPassword.length <= validators.PASSWORD_MAX_LENGTH &&
                    isPasswordMatched && <div style={{ color: 'green' }}>passwords match</div>}

                  {values.newConfirmPassword &&
                    values.newConfirmPassword.length >= validators.PASSWORD_MIN_LENGTH &&
                    values.newConfirmPassword.length <= validators.PASSWORD_MAX_LENGTH &&
                    !isPasswordMatched && <div style={{ color: 'red' }}>Passwords don’t match</div>}
                </div>

                {/* <div className={confirmClasses}>
                <h3 className={css.confirmChangesTitle}>
                  <FormattedMessage id="PasswordChangeForm.confirmChangesTitle" />
                </h3>
                <p className={css.confirmChangesInfo}>
                  <FormattedMessage id="PasswordChangeForm.confirmChangesInfo" />
                  <br />
                  <FormattedMessage
                    id="PasswordChangeForm.resetPasswordInfo"
                    values={{ resetPasswordLink }}
                  />
                </p>

                <FieldTextInput
                  className={css.password}
                  type="password"
                  id="currentPassword"
                  name="currentPassword"
                  autoComplete="current-password"
                  label={passwordLabel}
                  placeholder={passwordPlaceholder}
                  validate={validators.composeValidators(
                    passwordRequired,
                    passwordMinLength,
                    passwordMaxLength
                  )}
                  customErrorText={passwordTouched ? null : passwordErrorText}
                />
              </div> */}
                <div className={css.bottomWrapper}>
                  {genericFailure}
                  <PrimaryButton
                    type="submit"
                    inProgress={inProgress}
                    ready={ready}
                    disabled={submitDisabled}
                  >
                    <FormattedMessage id="PasswordChangeForm.saveChanges" />
                  </PrimaryButton>
                </div>
              </Form>
            );
          }}
        />
      </>
    );
  }
}

PasswordChangeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  changePasswordError: null,
  inProgress: false,
  formId: null,
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

const { bool, string } = PropTypes;

PasswordChangeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  changePasswordError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
  formId: string,
  resetPasswordInProgress: bool,
  resetPasswordError: propTypes.error,
};

const PasswordChangeForm = compose(injectIntl)(PasswordChangeFormComponent);
PasswordChangeForm.displayName = 'PasswordChangeForm';

export default PasswordChangeForm;
