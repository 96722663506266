import React, { useState, useRef, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import css from './ListingSportsInfoPanel.module.css';
import ListingSportsInfoForm from '../../forms/ListingSportsInfoForm/ListingSportsInfoForm';
import ToastMessage, { notify } from '../ToastMessage/ToastMessage';
import axios from 'axios';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { apiBaseUrl } from '../../util/api';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

function ListingSportsInfoPanelComponent(props) {
  // const [ready, setReady] = useState(false);
  const routes = routeConfiguration();

  const {
    history,
    listingId,
    listing,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    page = {},
    stListingData,
    onShowListing,
    onResetRestForm,
  } = props;

  const facilityImage = page.facilityImage;

  const handleSubmit = (data) => {
    const { durationName, durationType, timeInterval, finalValues: values } = data;

    const categoryAll = [];
    const subCategoryAll = [];
    values.forEach(({ sportName, subCategory }) => {
      categoryAll.push(sportName);
      subCategoryAll.push(subCategory);
    });
    const body = {
      step: 2,
      facilityImage,
      listingDetails: {
        // listingId: listingId,
        category: categoryAll,
        subCategory: subCategoryAll,
        images: values[0].images,
        durationName,
        durationType,
        timeInterval,
      },
      sportsInfo: values.map((v) => {
        return {
          sportName: v.sportName,
          subCategory: v.subCategory,
          // images: v.images,
          description: v.description,
          aminities: v.aminities,
          fullFacilityDurationAndPrice: v.fullFacilityDurationAndPrice.map((data) => ({
            ...data,
            fullPrice: data.fullPrice * 100,
            deposit: data.deposit * 100,
          })),
          halfFacilityDurationAndPrice: v.halfFacilityDurationAndPrice?.map((data) => ({
            ...data,
            fullPrice: data.fullPrice * 100,
            deposit: data.deposit * 100,
          })),
          images: values.images,
          quaterFacilityDurationAndPrice: v.quaterFacilityDurationAndPrice?.map((data) => ({
            ...data,
            fullPrice: data.fullPrice * 100,
            deposit: data.deposit * 100,
          })),

          rentalExtraEquipment: v.rentalExtraEquipment.map((data) => ({
            ...data,
            chargeAmount: data.chargeAmount * 100,
          })),
          groupBookingInfo: {
            minCapacity: v.minCapacity,
            maxCapacity: v.maxCapacity,
            options: v.groupBookingOptions,
          },
          extraInformation: {
            rules: v.rules,
            whatToBring: v.whatToBring,
            whatIsProvided: v.whatIsProvided,
            thingsToKnow: v.thingsToKnow,
            whatYouDo: v.whatYouDo,
          },
          priceForAdult: v.priceForAdult * 100,
          priceForChildren: v.priceForChildren * 100,
          peakTimingPrice: v.peakTimingPrice * 100,
          offPeakTimingPrice: v.offPeakTimingPrice * 100,
          priceForInfants: v.priceForInfants * 100,
          editorData: v.editorData,
          facilityType: v.facilityType,
        };
      }),
    };

    axios
      .patch(`${apiBaseUrl()}/api/listing/${listingId}`, body, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data?.message === 'listing updated') {
          onResetRestForm();
          history.push(
            createResourceLocatorString(
              'SuperAdminDashboard',
              routes,
              { tab: 'listings' },
              {
                actionType: 'new',
                listingType: 'facilityHire',
                lTab: 'schedule',
                listing: res.data.id,
              }
            )
          );
        }
      })
      .catch((err) => {
        notify('error', err.response?.data);
      });
  };

  const initialFacilities = {};
  const listingFormatedData = listing?.sportsInfo.map((s) => ({
    [s.sportName]: {
      ...s,
      fullFacilityDurationAndPrice: s.fullFacilityDurationAndPrice?.map((data) => ({
        ...data,
        fullPrice: data.fullPrice / 100,
        deposit: data.deposit / 100,
      })),
      halfFacilityDurationAndPrice: s.halfFacilityDurationAndPrice?.map((data) => ({
        ...data,
        fullPrice: data.fullPrice / 100,
        deposit: data.deposit / 100,
      })),
      quaterFacilityDurationAndPrice: s.quaterFacilityDurationAndPrice?.map((data) => ({
        ...data,
        fullPrice: data.fullPrice / 100,
        deposit: data.deposit / 100,
      })),
      peakTimingPrice: s.peakTimingPrice / 100,
      priceForAdult: s.priceForAdult / 100,
      priceForChildren: s.priceForChildren / 100,
      priceForInfants: s.priceForInfants / 100,
      rentalExtraEquipment: s.rentalExtraEquipment.map((data) => ({
        ...data,
        chargeAmount: data.chargeAmount / 100,
      })),
    },
    fullFacilityDurationAndPrice: s.fullFacilityDurationAndPrice.map((data) => ({
      ...data,
      fullPrice: data.fullPrice / 100,
      deposit: data.deposit / 100,
    })),
    minCapacity: s.groupBookingInfo.minCapacity,
    maxCapacity: s.groupBookingInfo.maxCapacity,
    groupBookingOptions: s.groupBookingInfo.options,
    facilityType: s.facilityType,
  }));

  let initialEditorData = {};

  listingFormatedData?.forEach((facility) => {
    const key = Object.keys(facility)[0];
    for (let subKey in facility[key]?.editorData) {
      initialEditorData[`${key}.${subKey}`] = facility[key].editorData[subKey];
    }
    initialFacilities[key] = {
      ...facility[key],
      ...initialEditorData,
      minCapacity: facility.minCapacity,
      maxCapacity: facility.maxCapacity,
      groupBookingOptions: facility.groupBookingOptions,
      // facilityType: s.facilityType,
    };
  });

  const initialFullFacilityDuration = {};
  listingFormatedData?.forEach((sport) => {
    const sportName = Object.keys(sport)[0];
    const fullFacilityDurationAndPrice = sport[sportName].fullFacilityDurationAndPrice;
    initialFullFacilityDuration[sportName] = fullFacilityDurationAndPrice;
  });

  const sportsFullFacilityDurationAndPrice = listingFormatedData?.reduce((acc, obj) => {
    const sportName = Object.keys(obj)[0];
    const fullFacilityDurationAndPrice = obj[sportName].fullFacilityDurationAndPrice;
    acc[sportName] = fullFacilityDurationAndPrice;
    return acc;
  }, {});

  const sportsHalfFacilityDurationAndPrice = listingFormatedData?.reduce((acc, obj) => {
    const sportName = Object.keys(obj)[0];
    const halfFacilityDurationAndPrice = obj[sportName].halfFacilityDurationAndPrice;
    acc[sportName] = halfFacilityDurationAndPrice;
    return acc;
  }, {});

  const sportsQuaterFacilityDurationAndPrice = listingFormatedData?.reduce((acc, obj) => {
    const sportName = Object.keys(obj)[0];
    const quaterFacilityDurationAndPrice = obj[sportName].quaterFacilityDurationAndPrice;
    acc[sportName] = quaterFacilityDurationAndPrice;
    return acc;
  }, {});

  const sportsRentalExtraEquipment = listingFormatedData?.reduce((acc, obj) => {
    const sportName = Object.keys(obj)[0];
    const rentalExtraEquipment = obj[sportName].rentalExtraEquipment;
    acc[sportName] = rentalExtraEquipment;
    return acc;
  }, {});

  const currentListingImages = stListingData && stListingData.images ? stListingData.images : [];

  const imageOrder = page.imageOrder || [];
  const unattachedImages = imageOrder.map((i) => page.images[i]);

  const allImages = currentListingImages.concat(unattachedImages);
  const removedImageIds = page.removedImageIds || [];
  const images = allImages.filter((img) => {
    return !removedImageIds.includes(img.id);
  });

  const listingInitialValues = {
    sports: listing?.sportsInfo.map((s) => ({ [s.sportName]: {} })) || [],
    fullFacilityDuration: initialFullFacilityDuration,
    halfFacilityDurationAndPrice: sportsHalfFacilityDurationAndPrice,
    quaterFacilityDurationAndPrice: sportsQuaterFacilityDurationAndPrice,
    fullFacilityDurationAndPrice: sportsFullFacilityDurationAndPrice,
    rentalExtraEquipment: sportsRentalExtraEquipment,
    images,
    durationName: listing?.listingDetails?.durationName,
    durationType: listing?.listingDetails?.durationType,
    timeInterval: listing?.listingDetails?.timeInterval,
    ...initialFacilities,
  };

  return (
    <div>
      <ToastMessage position="bottom-center" />
      <div className={css.mainContainer}>
        <ListingSportsInfoForm
          onSubmit={handleSubmit}
          listing={listing}
          listingId={listingId}
          // sports={sports}
          // ready={ready}
          formId="ListingFacilityHireSportsInfoForm"
          // inProgress={ready}
          images={images}
          page={page}
          onImageUpload={onImageUpload}
          onUpdateImageOrder={onUpdateImageOrder}
          onRemoveImage={onRemoveImage}
          facilityImage={facilityImage}
          stListingData={stListingData}
          initialValues={
            isEmpty(page.restFormValues || {}) ? listingInitialValues : page.restFormValues
            // listingInitialValues
          }
          listingFormatedData={listingFormatedData}
        />
      </div>
    </div>
  );
}

const ListingSportsInfoPanel = compose(injectIntl, withRouter)(ListingSportsInfoPanelComponent);

export default ListingSportsInfoPanel;
