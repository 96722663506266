import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { ReactComponent as UpArrow } from '../../assets/arrowUp.svg';
import { ReactComponent as DownArrow } from '../../assets/arrowDown.svg';

import css from './CustomBookingForm.module.css';
import { isArray, isEmpty } from 'lodash';
import moment from 'moment';
import { createInstance } from '../../util/sdkLoader';
import config from '../../config';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import { Avatar } from '../../components';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';

const EXTRA_PARTICIPANTS = ['Children', 'Infants'];

function CustomBookingForm(props) {
  const { listing, values, form, disableCheck, submitDisable } = props;

  const { fromMongo } = listing.attributes.publicData || {};

  const { listingDetails, scheduleDetails } = fromMongo || {};
  const validExtraParticipants = EXTRA_PARTICIPANTS.filter(
    (f) =>
      (f === 'Children' && listingDetails?.priceForChildren > 0) ||
      (f === 'Infants' && listingDetails?.priceForInfant > 0)
  );
  const [rawDate, setrawDate] = useState([]);
  const [attendeeErr, setAttendeeErr] = useState(false);
  const [extraErr, setExtraErr] = useState({});
  const [userData, setUserData] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [otherJoinersCount, setOtherJoinersCount] = useState(0);
  const sdkInstance = useRef(createInstance({ clientId: config.sdk.clientId }));
  const [maxAttendee, setMaxAttendee] = useState(
    listingDetails?.groupBookingInfo?.maxCapacity || 1
  );
  useEffect(() => {
    const { listingId } = props;
    const {
      recurringScheduleTime,
      recurringScheduleFrequency,
      startDate,
      startTime,
      endDate,
      endTime,
    } = scheduleDetails || {};
    const { groupBookingInfo } = listingDetails || {};
    const timeZone = listing?.attributes?.availabilityPlan?.timezone;
    const { options = [] } = groupBookingInfo || {};
    const shouldBlockTimeSlot = options?.includes('blockTimeSlot');
    let [hours = 0, minutes = 0] = endTime?.split(':')?.map(Number) || [];
    const start = moment.tz(startDate, timeZone);
    const end = !endTime
      ? endDate
        ? moment(endDate).add(23, 'h')
        : moment(startDate).add(23, 'h')
      : endDate
      ? moment(endDate)
      : moment(startDate);

    const rawDates = [];

    // Set the end time to the current date with the parsed hour and minute
    switch (recurringScheduleTime) {
      case 'daily':
        while (start <= end) {
          if (start > moment())
            rawDates.push({
              start: start.clone(),
              end: start.clone().startOf('day').add(hours, 'hours').add(minutes, 'minutes'),
            });
          start.add(recurringScheduleFrequency, 'day');
        }

        break;
      case 'weekly':
        while (start <= end) {
          if (start > moment())
            rawDates.push({
              start: start.clone(),
              end: start.clone().startOf('day').add(hours, 'hours').add(minutes, 'minutes'),
            });
          start.add(7 * recurringScheduleFrequency, 'days');
        }
        break;
      case 'monthly':
        while (start <= end) {
          if (start > moment())
            rawDates.push({
              start: start.clone(),
              end: start.clone().startOf('day').add(hours, 'hours').add(minutes, 'minutes'),
            });
          start.add(recurringScheduleFrequency, 'months');
        }
        break;
      default:
        console.log(`Invalid recurringScheduleTime: ${recurringScheduleTime}`);
    }

    const fetchData = async () => {
      const { data: bookingDetails } = await axios.get(
        `${apiBaseUrl()}/api/transactions/listing/groupByStartDate/${
          listingId.uuid
        }?startDate=${moment().clone().startOf('day').valueOf()}&endDate=${moment(end)
          .clone()
          .endOf('day')
          .valueOf()}`,
        {
          withCredentials: true,
        }
      );
      // console.log(2345, bookingDetails);
      const allBookedSlots = bookingDetails.map(({ _id: startDate }) =>
        moment(startDate).valueOf()
      );
      const availableSlotsOnly = rawDates.filter(
        ({ start }) => !allBookedSlots.includes(moment(start).valueOf())
      );

      if (availableSlotsOnly?.length > 0) {
        const promises = availableSlotsOnly.map((d, idx) => {
          let seats;
          const params = {
            listingId: listing?.id?.uuid,
            start: d.start.toDate(),
            end: d.end.toDate(),
          };
          return sdkInstance.current?.timeslots
            .query(params)
            .then((resp) => {
              seats = resp?.data?.data?.[0]?.attributes?.seats || 0;
              availableSlotsOnly[idx] = { ...d, seats };
            })
            .catch((e) => (availableSlotsOnly[idx] = { ...d, seats: 0, seatError: true }));
        });
        Promise.all(promises);
      }
      setrawDate(availableSlotsOnly);
      return bookingDetails;
    };
    if (shouldBlockTimeSlot) {
      fetchData();
    } else {
      if (rawDates?.length > 0) {
        const promises = rawDates.map((d, idx) => {
          let seats;
          const params = {
            listingId: listing?.id?.uuid,
            start: d.start.toDate(),
            end: d.end.toDate(),
          };
          return sdkInstance.current?.timeslots
            .query(params)
            .then((resp) => {
              seats = resp?.data?.data?.[0]?.attributes?.seats || 0;
              rawDates[idx] = { ...d, seats };
            })
            .catch((e) => (rawDates[idx] = { ...d, seats: 0, seatError: true }));
        });
        Promise.all(promises);
      }
      setrawDate(rawDates);
    }

    form.change('attendee', { quantity: listingDetails?.groupBookingInfo?.minCapacity });
  }, [scheduleDetails]);
  useEffect(() => {
    if (
      values.attendee &&
      (values.attendee['quantity'] < (listingDetails?.groupBookingInfo?.minCapacity || 0) ||
        values.attendee['quantity'] > (listingDetails?.groupBookingInfo?.maxCapacity || 0))
    ) {
      setAttendeeErr(true);
    } else {
      setAttendeeErr(false);
    }

    const hasExtraError = Object.values(extraErr).some((error) => error === true);
    const submitBtnDisable =
      attendeeErr || hasExtraError || !values.bookingStartTime || !values.bookingEndTime;

    if (submitDisable !== submitBtnDisable) {
      disableCheck(submitBtnDisable);
    }
  }, [values, attendeeErr, extraErr]);

  const [menueSelected, setMenueSelected] = useState({
    time: false,
    participants: false,
    extra: false,
    reservation: false,
  });
  const isDepositAvailable = listingDetails?.deposit > 0;
  return (
    <>
      <div
        className="css.rootContainer"
        tabIndex={0}
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setMenueSelected((menueSelected) => ({
              participants: false,
              extra: false,
              reservation: false,
              time: false,
            }));
          }
        }}
      >
        {/* Date and time slection section */}

        <div
          className={classNames(css.timeContainer, css.collapsable)}
          onClick={() =>
            setMenueSelected((menueSelected) => ({
              participants: false,
              extra: false,
              reservation: false,
              time: !menueSelected.time,
            }))
          }
        >
          <div className={css.valueContainer}>
            <div className={css.label}>DATE AND TIME</div>
            <div className={css.velue}>{values.bookingDateAndTime ?? 'Select'}</div>
          </div>
          <div className={css.arrowContainer}>
            {menueSelected.time ? <UpArrow /> : <DownArrow />}
          </div>
        </div>
        {menueSelected.time && (
          <div className={css.selectContainer}>
            <div
              className={css.optionsList}
              onClick={() => {
                // console.log(110);
              }}
            >
              {rawDate.length === 0 ? (
                <div className={css.option}>No Slots Available</div>
              ) : (
                rawDate
                  // .filter((date) => {
                  //   // checking whether there is atleast minimum seat available for the day or not if not then will not show the day in frontend
                  //   const { start, end, seats } = date || {};

                  //   return listingDetails?.groupBookingInfo?.minCapacity <= seats;
                  // })
                  .map((date) => {
                    const { start, end, seats } = date || {};
                    const formattedDate = start && start.format('ddd, MMM D, YYYY');
                    const startTimeFormatted =
                      start && start.clone().tz(getDefaultTimeZoneOnBrowser()).format('h:mmA');
                    const endTimeFormatted =
                      end && end.clone().tz(getDefaultTimeZoneOnBrowser()).format('h:mmA');
                    const endTime = end && end.format('H:mm');
                    const dateRange = `${formattedDate} ${startTimeFormatted} - ${endTimeFormatted}`;
                    const bookingEnd = moment(start)
                      .clone()
                      .startOf('day')
                      .add(moment.duration(endTime));
                    const isOptionDisabled = listingDetails?.groupBookingInfo?.minCapacity > seats;
                    return (
                      <div
                        key={dateRange}
                        className={classNames(css.option, {
                          [css.disabledOpt]: isOptionDisabled,
                          [css.optionActive]: values.bookingDateAndTime === dateRange,
                        })}
                        onClick={() => {
                          if (isOptionDisabled) return;
                          form.change('bookingEndTime', null);
                          form.change('bookingDateAndTime', dateRange);
                          form.change('bookingStartTime', start.clone().valueOf());
                          form.change('bookingEndTime', bookingEnd.clone().valueOf());
                          form.change('max', seats);
                          setMaxAttendee(seats);

                          setMenueSelected((menueSelected) => ({
                            ...menueSelected,
                            time: false,
                          }));

                          axios
                            .get(
                              `${apiBaseUrl()}/api/transactions/listing/groupByCustomerId/${
                                listing.id.uuid
                              }?startDate=${moment(start)
                                .clone()
                                .startOf('day')
                                .valueOf()}&endDate=${moment(bookingEnd)
                                .clone()
                                .endOf('day')
                                .valueOf()}`,
                              {
                                withCredentials: true,
                              }
                            )
                            .then((res) => {
                              if (res?.data?.length === 0) {
                                return;
                              }
                              let customerIds = [];
                              let joinersCount = 0;
                              res.data.forEach((c, indx) => {
                                const allAteendeesForThisTx = c.data.reduce((sum, curr) => {
                                  return sum + curr.attendee;
                                }, 0);
                                if (indx < 5) {
                                  customerIds.push(c._id);
                                  joinersCount += allAteendeesForThisTx - 1;
                                } else {
                                  joinersCount += allAteendeesForThisTx;
                                }
                              });

                              setOtherJoinersCount(joinersCount);
                              return customerIds;
                            })
                            .then((res) => {
                              const promises = res?.map((m) =>
                                sdkInstance.current.users.show(
                                  {
                                    id: m,
                                    include: ['profileImage'],
                                    'fields.image': [
                                      'variants.default',
                                      'variants.square-small',
                                      'variants.square-small2x',
                                    ],
                                  },
                                  { expand: true }
                                )
                              );
                              return Promise.all(promises || []);
                            })
                            .then((res) => {
                              setUserData(res);
                            });
                        }}
                      >
                        {dateRange}{' '}
                        {!isNaN(seats) && !date?.seatError
                          ? seats === 0
                            ? '(Fully booked)'
                            : `(${seats} slots left)`
                          : null}
                      </div>
                    );
                  })
              )}
            </div>
          </div>
        )}

        {/* Participants section section */}

        {listingDetails?.groupBookingInfo &&
          isArray(listingDetails?.groupBookingInfo.options) &&
          listingDetails?.groupBookingInfo.options.includes('selectAttendees') && (
            <div
              className={classNames(css.ParticipantsContainer, css.collapsable, {
                [css.hideBorderBottom]:
                  !isDepositAvailable &&
                  !(
                    !isEmpty(listingDetails.rentalExtraEquipment) &&
                    listingDetails.rentalExtraEquipment[0]?.equipmentName &&
                    listingDetails.rentalExtraEquipment[0]?.maxQuantity
                  ),
              })}
              onClick={() =>
                setMenueSelected((menueSelected) => ({
                  time: false,
                  extra: false,
                  reservation: false,
                  participants: !menueSelected.participants,
                }))
              }
            >
              <div className={css.valueContainer}>
                <div className={css.label}>PARTICIPANTS</div>
                <div className={css.velue}>{`Adult${values.attendee?.quantity > 1 ? 's' : ''}:${
                  values.attendee?.quantity || ''
                } ${
                  values.participants && values.participants['Children']?.quantity
                    ? '. Children:' + values.participants['Children']?.quantity
                    : ''
                } ${
                  values.participants && values.participants['Infants']?.quantity
                    ? '. Infant:' + values.participants['Infants']?.quantity
                    : ''
                }`}</div>
              </div>
              <div className={css.arrowContainer}>
                {menueSelected.participants ? <UpArrow /> : <DownArrow />}
              </div>
            </div>
          )}
        {menueSelected.participants && (
          <div className={css.selectContainer}>
            <div className={css.optionsList}>
              <div
                key={'attendee'}
                className={classNames(css.option)}
                // onClick={() => handleOptionClick(option)}
              >
                <div className={css.AttendeeWrap}>
                  <div className={css.checkBox}></div>
                  <div className={css.equipmentName}>Adult</div>
                </div>
                <div className={css.rangeContainer}>
                  <div className={classNames(css.rangeDiv, attendeeErr && css.invalidInp)}>
                    <button
                      type="button"
                      onClick={() => {
                        if (
                          (values.attendee &&
                            values.attendee['quantity'] >
                              listingDetails?.groupBookingInfo?.minCapacity) ||
                          0
                        ) {
                          form.change(`attendee['quantity']`, values.attendee['quantity'] - 1);
                        }
                      }}
                    >
                      -
                    </button>

                    <input
                      type="text"
                      name={`attendee['quantity']`}
                      value={
                        values.attendee && typeof values.attendee === 'object'
                          ? values.attendee['quantity'] || 0
                          : 0
                      }
                      onChange={(e) => {
                        if (e.target.value <= (maxAttendee || 0)) {
                          form.change(`attendee['quantity']`, e.target.value);
                        }
                      }}
                    />

                    <button
                      type="button"
                      onClick={() => {
                        if (
                          (values.attendee ? values.attendee['quantity'] : 0) < (maxAttendee || 0)
                        ) {
                          form.change(
                            `attendee['quantity']`,
                            (values.attendee && typeof values.attendee === 'object'
                              ? Number(values.attendee['quantity']) || 0
                              : 0) + 1
                          );
                        }
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              {validExtraParticipants.map((participant) => (
                <div
                  key={participant}
                  className={classNames(css.option)}
                  // onClick={() => handleOptionClick(option)}
                >
                  <div className={css.AttendeeWrap}>
                    <div className={css.checkBox}></div>
                    <div className={css.equipmentName}>{participant}</div>
                  </div>

                  <div className={css.rangeContainer}>
                    <div className={classNames(css.rangeDiv)}>
                      <button
                        type="button"
                        onClick={() => {
                          if (
                            values.participants &&
                            values.participants[participant] &&
                            values.participants[participant]['quantity'] > 0
                          ) {
                            form.change(
                              `participants[${participant}]['quantity']`,
                              values.participants[participant]['quantity'] - 1
                            );
                          }
                        }}
                      >
                        -
                      </button>

                      <input
                        type="text"
                        name={`participants[${participant}]['quantity']`}
                        value={
                          values.participants &&
                          values.participants[participant] &&
                          typeof values.participants[participant] === 'object'
                            ? values.participants[participant]['quantity'] || 0
                            : 0
                        }
                        onChange={(e) => {
                          if (parseInt(e.target.value) > 0) {
                            form.change(
                              `participants[${participant}]['quantity']`,
                              parseInt(e.target.value).toString()
                            );
                          } else {
                            form.change(`participants[${participant}]['quantity']`, e.target.value);
                          }
                        }}
                      />

                      <button
                        type="button"
                        onClick={() =>
                          form.change(
                            `participants[${participant}]['quantity']`,
                            (values.participants &&
                            values.participants[participant] &&
                            typeof values.participants[participant] === 'object'
                              ? Number(values.participants[participant]['quantity']) || 0
                              : 0) + 1
                          )
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              {listingDetails &&
                listingDetails.groupBookingInfo &&
                isArray(listingDetails.groupBookingInfo.options) &&
                listingDetails.groupBookingInfo.options.includes('showMinMaxCapacity') &&
                listingDetails.groupBookingInfo.maxCapacity &&
                listingDetails.groupBookingInfo.minCapacity && (
                  <div className={css.msgContainer}>
                    {/* Min: {listingDetails.groupBookingInfo.minCapacity} and Max:
                  {listingDetails.groupBookingInfo.maxCapacity} */}
                    Min: {listingDetails.groupBookingInfo.minCapacity || 1} and Max:{' '}
                    {/* {listingDetails.groupBookingInfo.maxCapacity || 1} */}
                    {maxAttendee || 1}
                  </div>
                )}
            </div>
          </div>
        )}

        {/* extras selection section */}

        {listingDetails &&
          !isEmpty(listingDetails.rentalExtraEquipment) &&
          listingDetails?.rentalExtraEquipment[0]?.equipmentName &&
          listingDetails?.rentalExtraEquipment[0]?.maxQuantity && (
            <div
              className={classNames(css.extraContainer, css.collapsable, {
                [css.hideBorderBottom]: !isDepositAvailable,
              })}
              onClick={() =>
                setMenueSelected((menueSelected) => ({
                  time: false,
                  participants: false,
                  reservation: false,
                  extra: !menueSelected.extra,
                }))
              }
            >
              <div className={css.valueContainer}>
                <div className={css.label}>EXTRAS</div>
                <div className={css.velue}>
                  {values.extra
                    ? Object.keys(values.extra).map((ext) => {
                        const { quantity } = values.extra[ext] || {};
                        return `${ext} : ${quantity} . `;
                      })
                    : 'Select'}
                </div>
              </div>
              <div className={css.arrowContainer}>
                {menueSelected.extra ? <UpArrow /> : <DownArrow />}
              </div>
            </div>
          )}
        {menueSelected.extra && (
          <div className={css.selectContainer}>
            <div className={css.optionsList}>
              {listingDetails &&
                isArray(listingDetails.rentalExtraEquipment) &&
                listingDetails.rentalExtraEquipment.map(
                  ({ equipmentName, maxQuantity }) =>
                    equipmentName &&
                    maxQuantity && (
                      <div
                        key={equipmentName}
                        className={classNames(css.option)}
                        // onClick={() => handleOptionClick(option)}
                      >
                        <div className={css.checkBox}></div>
                        <div className={css.equipmentName}>{equipmentName}</div>
                        <div className={css.rangeContainer}>
                          <div
                            className={classNames(
                              css.rangeDiv,
                              extraErr[`extra[${equipmentName}]['quantity']`] && css.invalidInp
                            )}
                          >
                            <button
                              type="button"
                              onClick={() => {
                                if (
                                  values.extra &&
                                  values.extra[equipmentName] &&
                                  values.extra[equipmentName]['quantity'] > 0
                                ) {
                                  if (
                                    Number(values.extra[equipmentName]['quantity']) - 1 <=
                                    maxQuantity
                                  ) {
                                    setExtraErr((extraErr) => ({
                                      ...extraErr,
                                      [`extra[${equipmentName}]['quantity']`]: false,
                                    }));
                                  }
                                  form.change(
                                    `extra[${equipmentName}]['quantity']`,
                                    values.extra[equipmentName]['quantity'] - 1
                                  );
                                }
                              }}
                            >
                              -
                            </button>

                            <input
                              type="text"
                              name={`extra[${equipmentName}]['quantity']`}
                              value={
                                values.extra &&
                                values.extra[equipmentName] &&
                                typeof values.extra[equipmentName] === 'object'
                                  ? values.extra[equipmentName]['quantity'] || 0
                                  : 0
                              }
                              onChange={(e) => {
                                if (e.target.value > maxQuantity) {
                                  // setExtraErr((extraError) => ({
                                  //   ...extraError,
                                  //   [`extra[${equipmentName}]['quantity']`]: true,
                                  // }));
                                } else {
                                  if (parseInt(e.target.value) > 0) {
                                    form.change(
                                      `extra[${equipmentName}]['quantity']`,
                                      parseInt(e.target.value).toString()
                                    );
                                  } else {
                                    form.change(
                                      `extra[${equipmentName}]['quantity']`,
                                      e.target.value
                                    );
                                  }

                                  // setExtraErr((extraError) => ({
                                  //   ...extraError,
                                  //   [`extra[${equipmentName}]['quantity']`]: false,
                                  // }));
                                }
                              }}
                            />

                            <button
                              type="button"
                              onClick={() => {
                                if (
                                  (values.extra &&
                                  values.extra[equipmentName] &&
                                  typeof values.extra[equipmentName] === 'object'
                                    ? Number(values.extra[equipmentName]['quantity']) || 0
                                    : 0) < maxQuantity
                                ) {
                                  setExtraErr((extraErr) => ({
                                    ...extraErr,
                                    [`extra[${equipmentName}]['quantity']`]: false,
                                  }));
                                  form.change(
                                    `extra[${equipmentName}]['quantity']`,
                                    (values.extra &&
                                    values.extra[equipmentName] &&
                                    typeof values.extra[equipmentName] === 'object'
                                      ? Number(values.extra[equipmentName]['quantity']) || 0
                                      : 0) + 1
                                  );
                                }
                              }}
                            >
                              +
                            </button>
                          </div>
                          <div className={css.quantitiyMaxMsg}>{`Max: ${maxQuantity}`}</div>
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        )}

        {/* reservation selecetion section */}

        <div
          className={classNames(css.reservationContainer, css.collapsable, {
            [css.hidePayment]: !isDepositAvailable,
          })}
          onClick={() =>
            !isNaN(listingDetails?.deposit) && listingDetails?.deposit > 0
              ? setMenueSelected((menueSelected) => ({
                  time: false,
                  participants: false,
                  extra: false,
                  reservation: !menueSelected.reservation,
                }))
              : null
          }
        >
          <div className={css.valueContainer}>
            <div className={css.label}>RESERVATION METHOD</div>
            <div className={css.velue}>
              {values.reservationMethod === 'fullPayment'
                ? 'Full Payment'
                : values.reservationMethod === 'partialPayment'
                ? 'Partial Payment'
                : 'Select'}
            </div>
          </div>
          <div className={css.arrowContainer}>
            {menueSelected.reservation ? <UpArrow /> : <DownArrow />}
          </div>
        </div>
        {menueSelected.reservation && (
          <div className={css.selectContainer}>
            <div className={css.optionsList}>
              <div
                key={'fullPayment'}
                className={classNames(
                  css.option,
                  values.reservationMethod === 'fullPayment' && css.optionActive
                )}
                onClick={() => {
                  form.change('reservationMethod', 'fullPayment');
                  setMenueSelected((menueSelected) => ({
                    ...menueSelected,
                    reservation: false,
                  }));
                }}
              >
                Full Payment
              </div>
              {!isNaN(listingDetails?.deposit) && listingDetails?.deposit > 0 && (
                <div
                  key={'partialPayment'}
                  className={classNames(
                    css.option,
                    values.reservationMethod === 'partialPayment' && css.optionActive
                  )}
                  onClick={() => {
                    form.change('reservationMethod', 'partialPayment');
                    setMenueSelected((menueSelected) => ({
                      ...menueSelected,
                      reservation: false,
                    }));
                  }}
                >
                  Partial Payment
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {userData?.length > 0 ? (
        <>
          <div className={css.otherAttendees}>Other attendees</div>
          <div className={css.bookedimgDiv}>
            {userData?.slice(0, showAll ? userData?.length : 3)?.map((m, indx) => {
              return (
                <div
                  key={indx}
                  className={classNames(css.imgwrapper, {
                    [css.negmar]: indx > 0,
                  })}
                >
                  {m?.data?.hasOwnProperty('included') && m?.data?.included !== undefined ? (
                    <img
                      src={m?.data?.included?.[0]?.attributes?.variants?.default?.url}
                      alt={m?.data?.data?.attributes?.profile?.displayName}
                      className={css.bookedProfileImg}
                    />
                  ) : (
                    <Avatar className={css.avatar} user={m?.data?.data} disableProfileLink />
                  )}
                  {/* {m?.data?.data?.attributes?.profile?.displayName} */}
                </div>
              );
            })}

            {otherJoinersCount > 0 && !showAll && (
              <span
                // onClick={() => setShowAll(true)}
                className={css.showBtn}
              >
                +{otherJoinersCount} are joining
              </span>
            )}
            {showAll && (
              <button onClick={() => setShowAll(false)} className={css.showBtn}>
                Show -
              </button>
            )}
          </div>
        </>
      ) : null}
    </>
  );
}

export default CustomBookingForm;
