import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import * as validators from '../../util/validators';

import css from './ListingGeneralInfoForm.module.css';
import { Form, Button, FieldSelect, FieldTextInput } from '../../components';
import { apiBaseUrl } from '../../util/api';
import axios from 'axios';

function ListingGeneralInfoFormComponent(props) {
  const { listing } = props;
  const [venues, setVenues] = useState([]);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(`${apiBaseUrl()}/api/venue`, {
          withCredentials: true,
        });
        setVenues(data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <FinalForm
      {...props}
      render={(fieldRenderProps) => {
        const {
          form,
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          ready,
          invalid,
          intl,
          onOpenTermsOfService,
          values,
        } = fieldRenderProps;

        const submitDisabled = invalid || inProgress;

        //Facility Name

        const facilityNameLabel = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilityNameLabel',
        });
        const facilityNamePlaceholder = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilityNamePlaceholder',
        });
        const facilityNameRequiredMessage = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilityNameRequired',
        });
        const facilityNameRequired = validators.required(facilityNameRequiredMessage);

        //Facility Venue

        const facilityVenueLabel = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilityVenueLabel',
        });
        const facilityVenuePlaceholder = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilityVenuePlaceholder',
        });
        const facilityVeneuRequiredMessage = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilityVenueRequired',
        });
        const facilityVeneuRequired = validators.required(facilityVeneuRequiredMessage);

        // Facility Visibility
        const facilityVisibilityLabel = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilityVisibilityLabel',
        });
        const facilityVisibilityPlaceholder = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilityVisibilityPlaceholder',
        });
        const facilityVisibilityRequiredMessage = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilityVisibilityRequired',
        });
        const facilityVisibilityRequired = validators.required(facilityVisibilityRequiredMessage);

        //facility format

        const facilityFormatLabel = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilityFormatLabel',
        });
        const facilityFormatPlaceholder = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilityFormatPlaceholder',
        });
        const facilityFormatRequiredMessage = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilityFormatRequired',
        });
        const facilityFormatRequired = validators.required(facilityFormatRequiredMessage);

        //facility surface

        const facilitySurfaceLabel = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilitySurfaceLabel',
        });
        const facilitySurfacePlaceholder = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilitySurfacePlaceholder',
        });
        const facilitySurfaceRequiredMessage = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilitySurfaceRequired',
        });
        const facilitySurfaceRequired = validators.required(facilitySurfaceRequiredMessage);

        //commssion

        const commissionLabel = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.commissionLabel',
        });
        const commissionPlaceholder = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.commissionPlaceholder',
        });
        const commissionRequiredMessage = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.commissionRequired',
        });
        const commissionRequired = validators.required(commissionRequiredMessage);

        //number required

        const validNumberRequiredMessage = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.validNumberRequiredMessage',
        });

        //validation
        const numberRequired = (val) => validators.requiredNumber(validNumberRequiredMessage, val);
        return (
          <Form
            onSubmit={(e) => {
              setSubmitInProgress(true);
              handleSubmit(e);
            }}
          >
            <div className={css.fieldContainer}>
              <div className={css.textFields}>
                <div className={css.fullInp}>
                  <FieldTextInput
                    className={css.facilityNameRoot}
                    type="text"
                    id={formId ? `${formId}.facilityName` : 'facilityName'}
                    name="facilityName"
                    label={facilityNameLabel}
                    placeholder={facilityNamePlaceholder}
                    validate={facilityNameRequired}
                    initialValue={listing?.listingName}
                  />
                </div>

                <div>
                  <div className={css.halfInp}>
                    <FieldSelect
                      id={formId ? `${formId}.facilityVenue` : 'facilityVenue'}
                      name="facilityVenue"
                      label={facilityVenueLabel}
                      className={css.facilityinfofields}
                      // disabled={!datesSelected}
                      validate={facilityVeneuRequired}
                      defaultValue={listing?.venueId?._id}
                    >
                      <option disabled value="">
                        {facilityVenuePlaceholder}
                      </option>
                      {venues.length &&
                        venues.map((venue) => {
                          return (
                            <option key={venue._id} value={venue._id}>
                              {venue.venueName}
                            </option>
                          );
                        })}
                    </FieldSelect>

                    <FieldSelect
                      id="BookingDateRangeLengthFilter.facilityVisibility"
                      name="facilityVisibility"
                      label={facilityVisibilityLabel}
                      className={css.facilityinfofields}
                      // disabled={!datesSelected}
                      validate={facilityVisibilityRequired}
                      initialValue={listing?.visibility}
                    >
                      <option disabled value="">
                        {facilityVisibilityPlaceholder}
                      </option>
                      <option value="public">public</option>
                      <option value="private">private</option>
                    </FieldSelect>
                  </div>

                  <div className={css.halfInp}>
                    <FieldTextInput
                      className={css.facilityFormatRoot}
                      type="text"
                      id={formId ? `${formId}.facilityFormat` : 'facilityFormat'}
                      name="facilityFormat"
                      label={facilityFormatLabel}
                      placeholder={facilityFormatPlaceholder}
                      validate={facilityFormatRequired}
                      initialValue={listing?.facilityFormat}
                    />

                    <FieldTextInput
                      className={css.facilitySurfaceRoot}
                      type="text"
                      id={formId ? `${formId}.facilitySurface` : 'facilitySurface'}
                      name="facilitySurface"
                      label={facilitySurfaceLabel}
                      placeholder={facilitySurfacePlaceholder}
                      validate={facilitySurfaceRequired}
                      initialValue={listing?.facilitySurface}
                    />
                  </div>
                </div>

                <div className={css.fullInp}>
                  <FieldTextInput
                    className={css.commissionRoot}
                    type="text"
                    id={formId ? `${formId}.commission` : 'commission'}
                    name="facilityCommisionPercentage"
                    label={commissionLabel}
                    placeholder={commissionPlaceholder}
                    // validate={commissionRequired}
                    validate={validators.composeValidators(
                      commissionRequired,
                      numberRequired(values.commissionRoot)
                    )}
                    initialValue={listing?.facilityCommisionPercentage}
                    onChange={(e) => {
                      e.preventDefault();
                      const value = e.target.value;
                      const regex = /^[1-9]\d*$/;
                      if (value.match(regex) || value === '') {
                        form.change('facilityCommisionPercentage', value);
                      }
                    }}
                  />
                </div>
                <div className={css.sbmitcnclbtn}>
                  <Button
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={ready}
                    className={css.sbmtbtn}
                  >
                    Save and Next
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}

const ListingGeneralInfoForm = compose(injectIntl)(ListingGeneralInfoFormComponent);

export default ListingGeneralInfoForm;
