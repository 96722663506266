import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: 01/10/2024.</p>

      <p>
        Please read these terms of service carefully before using Our Platform.
      </p>

      
      <h2>1 - Interpretation and Definitions</h2>

      <h3>1.1 - Interpretation</h3>
      <p>
        The words of which the initial letter is capitalised have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in the singular or in the plural.
      </p>

      <h3>1.2 - Definitions</h3>
      <p>
        For the purposes of these Terms of Service:
      </p>
      <ul className={css.unorderedList}>
        <li><b>Account</b> means a unique account created for You to access our Platform or parts of our Platform.</li>
        <li><b>Affiliate</b> means an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for the election of directors or other managing authority.</li>
        <li><b>Application</b> means the software program provided by the Company or Operator downloaded by You on any electronic device, named Aqtive Co.</li>
        <li><b>Buyer</b> refers to users of the Platform who are placing Bookings for Services.</li>
        <li><b>Country</b> refers to Kenya.</li>
        <li><b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Aqtive Co, AQTIVECO PERFORMANCE LIMITED..</li>
        <li><b>Content</b> refers to content such as text, images, or other information that can be posted, uploaded, linked to, or otherwise made available by You, regardless of the form of that content.</li>
        <li><b>Device</b> means any device that can access the Platform such as a computer, a cell phone, or a digital tablet</li>
        <li><b>Feedback</b> means feedback, innovations, or suggestions sent by You regarding the attributes, performance, or features of our Platform.</li>
        <li><b>Services</b> refers to the items or services offered for sale, rental, auction, contact, or any other means of trading on the Platform.</li>
        <li><b>Operator</b> (referred to as either "the Operator", "We", "Us" or "Our" in this Agreement) refers to Aqtive Co.</li>
        <li><b>Booking</b> means a request by You to purchase or trade by any means Goods and/or services on the Application or Website.</li>
        <li><b>Service</b> Provider refers to users of the Platform who are listing services and making them available for trade by any means.</li>
        <li><b>Platform</b> refers to the Application or the Website or both.</li>
        <li><b>Terms of Service</b> (also referred to as "Terms") mean these Terms of Service that form the entire agreement between You and the Company or Operator regarding the use of the Platform. </li>
        <li><b>Third-party Social Media Service</b> means any services or content (including data, information, products, or services) provided by a third party that may be displayed, included, or made available by the Platform.</li>
        <li><b>Website</b> refers to Aqtive Co, accessible from <a href="https://aqtive.co/" target="_blank">https://aqtive.co/</a></li>
        <li><b>You</b> means the individual accessing or using the Platform, or the company, or other legal entity on behalf of which such individual is accessing or using the Platform, as applicable.</li>
      </ul>

      
      <h2>2 - Contact Us</h2>
      <p>
        If you have any questions about these Terms of Service, You can contact us:
      </p>
      <ul className={css.unorderedList}>
        <li>By email: <a href="mailto:info@aqtive.co">info@aqtive.co</a></li>
        <li>By visiting this page on our website: <a href="https://aqtive.co/contactus" target="_blank">https://aqtive.co/contactus</a></li>
      </ul>

      
      <h2>3 - Acknowledgment</h2>
      <p>
        These are the Terms of Service governing the use of this Platform and the agreement that operates between You and the Company or Operator. These Terms of Service set out the rights and obligations of all users regarding the use of the Platform.
      </p>
      <p>
        Your access to and use of the Platform is conditioned on Your acceptance of and compliance with these Terms of Service. These Terms of Service apply to all visitors, users, and others who access or use the Platform.
      </p>
      <p>
        By accessing or using the Platform You agree to be bound by these Terms of Service. If You disagree with any part of these Terms of Service then You may not access the Platform.
      </p>
      <p>
        You represent that you are at least 18 years old or visiting this Site under the supervision of a parent or guardian.
      </p>
      <p>
        Your access to and use of the Platform is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company or Operator. Our Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your personal information when You use the Application or Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Platform.
      </p>


      <h2>4 - Governing Law</h2>
      <p>
        The laws of the Country, excluding its conflicts of law rules, shall govern these Terms and Your use of the Platform. Your use of the Application or Website may also be subject to other local, state, national, or international laws.
      </p>

      <h3>4.1 - Severability</h3>
      <p>
        If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force, and effect.
      </p>

      <h3>4.2 - Waiver</h3>
      <p>
        Except as provided herein, the failure to exercise a right or to require the performance of an obligation under these Terms shall not affect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.
      </p>


      <h2>5 - User Accounts</h2>

      <h3>5.1 - Account Creation</h3>
      <p>
        When You create an account with Us, You must provide Us with information that is accurate, complete, and current at all times.
      </p>
      <p>
        You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.
      </p>

      <h3>5.2 - Account Information</h3>
      <p>
        You may be asked to supply certain information relevant to Your Account including, without limitation, Your name, Your email, Your phone number, and Your address.
      </p>
      <p>
        Before or during posting Goods, you may be asked to supply, without limitation, Your bank account details, and Your identity documents.
      </p>
      <p>
        Before or during placing a Booking, you may be asked to supply, without limitation,Your phone number, Your credit card number, the expiration date of Your credit card, Your billing address, and Your shipping information.
      </p>

      <h3>5.3 - Account Review</h3>
      <p>
        Unless part of a feature of the Platform, We do not perform background checks or endorse any users. We do not accept any responsibility for the reliability, accuracy, and completeness of any information provided by users.
      </p>

      <h3>5.4 - Account Password</h3>
      <p>
        You are responsible for safeguarding the password that You use to access the Platform and for any activities or actions under Your password, whether Your password is with Our Platform Or a Third-Party Social Media service.
      </p>
      <p>
        You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorised use of Your account.
      </p>


      <h3>5.5 - Account Termination</h3>
      <p>
        We may terminate or suspend Your Account immediately, without prior notice or liability if You breach these Terms of Service. Upon termination, Your right to use the Platform will cease immediately.
      </p>
      <p>
        If You wish to terminate Your Account, You may simply discontinue using the Platform or contact Us for help.
      </p>
      

      <h2>6 - Content</h2>
      <h3>6.1 - Your Right to Post Content</h3>
      <p>
        Our Platform allows You to post Content. You are responsible for the Content that You post to the Platform, including its legality, reliability, and appropriateness.
      </p>
      <p>
        By posting Content to the Platform, You grant Us the right and licence to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Platform. You retain any and all of Your rights to any Content You submit, post, or display on or through the Platform and You are responsible for protecting those rights. You agree that this licence includes the right for Us to make Your Content available to other users of the Platform, who may also use Your Content subject to these Terms.
      </p>
      <p>
        You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it and grant Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on or through the Platform does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.
      </p>
      
      <h3>6.2 - Content Restrictions</h3>
      <p>
        The Company or Operator is not responsible for the content of the Platform's users. You expressly understand and agree that You are solely responsible for the Content and for all activity that occurs under your account, whether done so by You or any third person using Your account.
      </p>
      <p>
        You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening, libellous, defamatory, obscene, or otherwise objectionable. Examples of such objectionable Content include, but are not limited to, the following:
      </p>
      <ul className={css.unorderedList}>
        <li>Unlawful or promoting unlawful activity.</li>
        <li>Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.</li>
        <li>Spam, machine, or randomly-generated, constituting unauthorised or unsolicited advertising, chain letters, any other form of unauthorised solicitation, or any form of lottery or gambling.</li>
        <li>Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware, or telecommunications equipment or to damage or obtain unauthorised access to any data or other information of a third person.</li>
        <li>Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity, or other rights.</li>
        <li>Impersonating any person or entity including the Company or Operator and its employees or representatives.</li>
        <li>Violating the privacy of any third person.</li>
        <li>False information and features.</li>
      </ul>
      <p>
        The Company or Operator reserves the right, but not the obligation, to, in its sole discretion, determine whether or not any Content is appropriate and complies with these Terms, refuse or remove this Content. The Company or Operator further reserves the right to make formatting and edits and change the manner of any Content. The Company or Operator can also limit or revoke the use of the Platform if You post such objectionable Content. As the Company or Operator cannot control all content posted by users and/or third parties on the Platform, you agree to use the Platform at your own risk. You understand that by using the Platform You may be exposed to content that You may find offensive, indecent, incorrect, or objectionable, and You agree that under no circumstances will the Company or Operator be liable in any way for any content, including any errors or omissions in any content, or any loss or damage of any kind incurred as a result of your use of any content.
      </p>


      <h3>6.3 - Content Backups</h3>
      <p>
        Although regular backups of Content are performed, the Company or Operator does not guarantee there will be no loss or corruption of data.
      </p>
      <p>
        Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted prior to being backed up or that changes during the time a backup is performed.
      </p>
      <p>
        The Company or Operator will provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of Content. But You acknowledge that the Company or Operator has no liability related to the integrity of Content or the failure to successfully restore Content to a usable state.
      </p>      

      <h3>6.4 - Intellectual Property of Others and Copyright Infringement</h3>
      <p>
        We respect the intellectual property and copyrights of others. You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing Your copyright. It is Our policy to respond to any claim that Content posted on the Platform infringes a copyright or other intellectual property infringement of any person.
      </p>
      <p>
        We are ready to comply with local regulations in that matter.
      </p>
      <p>
        If You are a copyright owner or authorised on behalf of one, and You believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Platform, You must submit Your notice in writing and include in Your notice the following information related to the alleged infringement:
      </p>
      <ul className={css.unorderedList}>
        <li>An electronic or physical signature of the person authorised to act on behalf of the owner of the copyright's interest.</li>
        <li>A description of the copyrighted work that You claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work.</li>
        <li>Identification of the URL or other specific location on the Platform where the material that You claim is infringing is located.</li>
        <li>Your address, telephone number, and email address.</li>
        <li>A statement by You that You have a good faith belief that the disputed use is not authorised by the copyright owner, its agent, or the law.</li>
        <li>A statement by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are the copyright owner or authorised to act on the copyright owner's behalf.</li>
      </ul>

      <p>
        Upon receipt of a notification, the Company or Operator will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from the Platform.
      </p>


      <h2>7 - Booking of Services</h2>
      <p>By placing a Booking for services through the Platform, You warrant that You are legally capable of entering into binding contracts.</p>

      <h3>7.1 - Position of the Platform in Bookings</h3>
      <p>
        In the provision of some services, our role is one of a facilitator between You and the Service Providers, using the Platform. We are, therefore, a third party in those Bookings, which limits Our liabilities in any disputes between You and the Service Providers.
      </p>
      <p>
        We are not a party to any agreement You have with the Service Providers. Any agreement You enter with the Service Providers does not form a part of any agreement We have with you.
      </p>

      <h3>7.2 - Your Information as Buyer</h3>
      <p>
        If You wish to place a Booking for Goods and or services available on the Platform, You may be asked to supply certain information relevant to Your Booking including, without limitation, Your name, Your email, Your phone number, Your credit card number, the expiration date of Your credit card, Your billing address, and Your shipping information.
      </p>
      <p>
        You represent and warrant that: (i) You have the legal right to use any credit or debit card(s) or other payment method(s) in connection with any Booking; and that (ii) the information You supply to us is true, correct, and complete.
      </p>
      <p>
        By submitting such information, You grant us the right to provide the information to payment processing third parties for purposes of facilitating the completion of Your Booking.
      </p>


      <h3>7.3 - Availability, Errors, and Inaccuracies</h3>
      <p>
        We and Service Providers are constantly updating Our offerings of services on the Platform. The services available on the Platform may be mispriced, described inaccurately, or unavailable, and Service Providers and We may experience delays in updating information regarding the services on the Platform and in Our advertising on other websites.
      </p>
      <p>
        We and Service Providers cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.
      </p>

      <h3>7.4 - Prices Policy</h3>
      <p>
        The Company or Operator and Service Providers reserve the right to revise their prices at any time prior to accepting a Booking.
      </p>

      <p>
        The prices quoted may be revised by the Company or Operator subsequent to accepting a Booking in the event of any occurrence affecting delivery caused by government action, variation in customs duties, increased shipping charges, higher foreign exchange costs, and any other matter beyond the control of the Company or Operator or the Service Provider. In that event, You will have the right to cancel Your Booking.
      </p>

      <h3>7.5 - Payments</h3>
      <p>
        Payment can be made through various payment methods we have available. We rely on payment gateways that have their own terms of service and their own limitations.
      </p>
      <p>
        Payment cards (credit cards or debit cards) are subject to validation checks and authorization by Your card issuer. If we do not receive the required authorization, We will not be liable for any delay or non-delivery of Your Booking.
      </p>

      <h3>7.6 - Service Fees</h3>
      <p>
        We may charge You some fees (and applicable Taxes) for the right to use the Platform. More information about when service fees apply and how they are calculated is displayed during your Booking. We reserve the right to change the service fees at any time.
      </p>

      <h3>7.7 - Booking Modification</h3>
      <p>
        For services rendered by Service Providers, You and the Service Providers are responsible for any Booking modifications you agree to make via the Platform and agree to pay any additional amounts, fees, or taxes associated with any Booking modification.
      </p>

      <h3>7.8 - Booking Cancellation</h3>
      <h4 className={css.headingStyle}>7.8.1 - Our Booking Cancellation Rights</h4>
      <p>
        We reserve the right to refuse or cancel Your Booking at any time for certain reasons including but not limited to:
      </p>
      <ul className={css.unorderedList}>
        <li>Goods availability</li>
        <li>Errors in the description or prices for Goods</li>
        <li>Errors in Your Booking</li>
        <li>Mistakes from the Seller</li>
      </ul>
      <p>We reserve the right to refuse or cancel Your Booking if fraud or an unauthorised or illegal transaction or trade is suspected.</p>

      <h4 className={css.headingStyle}>7.8.2 - Booking Cancellation by Buyers</h4>
      <p>
      If You as a Buyer cancel a Booking, the amount You paid (including the Service fees) may be refundable if the cancellation occurs 48 hours before the service is to be rendered.
      </p>
      <p>
      If something outside Your control requires You to cancel a Booking, or if You think your Booking should be refunded, contact Us.
      </p>

      <h4 className={css.headingStyle}>7.8.3 - Booking Cancellation by Service Providers</h4>
      <p>
      If You as a Service Provider cancel a Booking, the amount the Buyer paid (including the Service fees) will be refunded to the Buyer and will not be transferred to the Service Provider.
      </p>
      <p>
      If something outside Your control requires You to cancel a Booking, or if You think your Booking should be refunded, contact Us.
      </p>

      <h3>7.9 - Booking Dispute</h3>
      <p>
        If a Buyer or a Service Provider disputes a Booking, the Company or Operator should be notified. The dispute will be resolved at Our sole discretion.
      </p>

      
      <h2>8 - Disclaimer of Warranties and Limitation of Liability</h2>

      <h3>8.1 - Limitation of Liability</h3>
      <p>
        To the maximum extent permitted by applicable law, in no event shall the Company or Operator or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, death, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or Operator or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.
      </p>
      <p>
        Some jurisdictions do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these jurisdictions, each party's liability will be limited to the greatest extent permitted by law.
      </p>

      <h3>8.2 - "AS IS" and "AS AVAILABLE" Disclaimer</h3>
      <p>
        The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company or Operator, on its own behalf and on behalf of its Affiliates and its and their respective licensors and Service Providers, expressly disclaims all warranties, whether express, implied, statutory, or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of the course of dealing, performance, usage or trade practice. Without limitation to the foregoing, the Company or Operator provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems, or services, operate without interruption, meet any performance or reliability standards or be error-free or that any errors or defects can or will be corrected.
      </p>
      <p>
        Without limiting the foregoing, neither the Company nor Operator nor any of the company's providers makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company or Operator are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.
      </p>
      <p>
        Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.
      </p>

      <h3>8.3 - Links to Other Websites</h3>
      <p>
        Our Platform may contain links to third-party websites or services that are not owned or controlled by the Company or Operator.
      </p>
      <p>
        The Company or Operator has no control over and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that the Company or Operator shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such web sites or services.
      </p>
      <p>
        We strongly advise You to read the terms of service and privacy policies of any third-party websites or services that You visit.
      </p>

      <h3>8.4 - Translation Interpretation</h3>
      <p>
        These Terms of Service may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.
      </p>
      
      <h3>8.5 - Assumption of Risks</h3>
      <p>
        You acknowledge and understand that physical activity inherently carries risks of injury, illness, and even death. These risks exist regardless of your age, fitness level, or the precautions you take. Examples of such risks include, but are not limited to, muscle strain, sprains, tears, fractures, concussions, heart attack, stroke, and dehydration.
      </p>
      <p>
        By accessing and using this Platform, you voluntarily assume all known and unknown risks associated with your participation in any activities or programs provided or hosted here. You acknowledge that you are solely responsible for your own health and safety, and you waive any and all claims against The Company or Operator or its affiliates, employees, contractors, or agents for any injuries, illnesses, or other damages you may suffer as a result of your participation.
      </p>
      <p>
        By using this Platform, you acknowledge that you have read and understood this Assumption of Risk and User Responsibility clause, and you voluntarily agree to its terms and conditions.
      </p>

      <h3>8.6 - Guest Policy and Responsibility</h3>
      <p>
        You may bring additional guests with you to participate in activities or programs offered on the platform, provided additional guests are allowed and pay any applicable guest fees. You acknowledge and agree that you are solely responsible for your guests' conduct and welfare during their time on the Platform. This includes ensuring your guests understand and comply with all of The Company or Operator's policies and safety guidelines.
      </p>
      <p>
        If any of your guests are children or infants, you represent and warrant that you are their legal parent or guardian and have full authority to act on their behalf and consent to their participation in activities or programs offered on the Platform. You acknowledge and agree that you are solely responsible for the supervision and safety of your children while they are on the Platform and assume all risk of injury or harm they may experience. This includes ensuring your children follow all safety instructions and guidelines provided by The Company or Operator and its instructors, coaches, or other Service Providers.
      </p>
      <p>
        If your guests or any children under your supervision engage in disruptive or unsafe behaviour, violate any of The Company or Operator's policies, or cause damage to property, you may be asked to leave the Platform immediately. Additionally, you may be held financially responsible for any damages caused by your guests or children under your supervision. You acknowledge that you and your guests are solely responsible for your own health and safety, and you waive any and all claims against The Company or Operator or its affiliates, employees, contractors, or agents for any injuries, illnesses, or other damages you and/or your guests may suffer as a result of your participation.
      </p>


      <h2>9 - Disputes Resolution about the Service</h2>
      <p>
        If You have any concerns or disputes about the Platform, You agree to first try to resolve the dispute informally by contacting the Company or Operator.
      </p>
      

      <h2>10 - Intellectual Property of the Service</h2>
      <p>
        The Platform and its original content (excluding Content provided by You or other users), features, and functionality are and will remain the exclusive property of the Company or Operator and its licensors.
      </p>
      <p>
        The Platform is protected by copyright, trademark, and other laws of both the Country and foreign countries.
      </p>
      <p>
        Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company or Operator.
      </p>


      <h2>11 - Your feedback to Us</h2>
      <p>
        You assign all rights, title, and interest in any Feedback You provide the Company or Operator. If for any reason such assignment is ineffective, You agree to grant the Company or Operator a non-exclusive, perpetual, irrevocable, royalty-free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.
      </p>


      <h2>12 - Changes to these Terms of Service</h2>
      <p>
        We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.
      </p>
      <p>
        By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the Application or Website and the Platform.
      </p>

    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
