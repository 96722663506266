/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */
import { ReactComponent as Football } from './assets/Football.svg';
export const filters = [
  {
    id: 'activity',
    label: 'Activity',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_activity'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'sessions', label: 'Sports Session' },
        { key: 'classes', label: 'Fitness Classes' },
        { key: 'facilityHire', label: 'Facility Hire' },
      ],
    },
  },
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      options: [],
    },
  },
  // {
  //   id: 'subCategory',
  //   label: 'Subcategory',
  //   type: 'SelectMultipleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_subCategory'],
  //   config: {
  //     searchMode: 'has_any',
  //     options: [],
  //   },
  // },
  // {
  //   id: 'price',
  //   label: 'Price',
  //   type: 'PriceFilter',
  //   group: 'primary',
  //   // Note: PriceFilter is fixed filter,
  //   // you can't change "queryParamNames: ['price'],"
  //   queryParamNames: ['price'],
  //   // Price filter configuration
  //   // Note: unlike most prices this is not handled in subunits
  //   config: {
  //     min: 0,
  //     max: 1000,
  //     step: 5,
  //   },
  // },
  {
    id: 'difficulty',
    label: 'Difficulty',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_difficulty'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
        { key: 'easy', label: 'Beginner LVL' },
        { key: 'medium', label: 'Intermediate LVL' },
        { key: 'hard', label: 'Advanced LVL' },
        { key: 'allLevels', label: 'All Levels' },
      ],
    },
  },
  {
    id: 'gender',
    label: 'Gender',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_gender'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
        { key: 'male', label: 'Men’s' },
        { key: 'female', label: 'Women’s' },
        { key: 'mixed', label: 'Mixed' },
      ],
    },
  },
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },

  // {
  //   id: 'keyword',
  //   label: 'Keyword',
  //   type: 'KeywordFilter',
  //   group: 'primary',
  //   // Note: KeywordFilter is fixed filter,
  //   // you can't change "queryParamNames: ['keywords'],"
  //   queryParamNames: ['keywords'],
  //   // NOTE: If you are ordering search results by distance
  //   // the keyword search can't be used at the same time.
  //   // You can turn on/off ordering by distance from config.js file.
  //   config: {},
  // },
  // {
  //   id: 'yogaStyles',
  //   label: 'Yoga styles',
  //   type: 'SelectMultipleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_yogaStyles'],
  //   config: {
  //     // Optional modes: 'has_all', 'has_any'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'ashtanga', label: 'Ashtanga' },
  //       { key: 'hatha', label: 'Hatha' },
  //       { key: 'kundalini', label: 'Kundalini' },
  //       { key: 'restorative', label: 'Restorative' },
  //       { key: 'vinyasa', label: 'Vinyasa' },
  //       { key: 'yin', label: 'Yin' },
  //     ],
  //   },
  // },
  // {
  //   id: 'certificate',
  //   label: 'Certificate',
  //   type: 'SelectSingleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_certificate'],
  //   config: {
  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
  //       { key: '200h', label: 'Registered yoga teacher 200h' },
  //       { key: '500h', label: 'Registered yoga teacher 500h' },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const allCategoryList = [
  {
    key: 'football',
    label: 'FootBall',
    subCategory: [
      { key: '5-a-side Football', label: '5-a-side Football' },
      { key: '11-a-side Football', label: '11-a-side Football' },
    ],
  },
  {
    key: 'yoga',
    label: 'Yoga',
    subCategory: [
      { key: 'vinyasaYoga', label: 'Vinyasa Yoga' },
      { key: 'hotYoga', label: 'Hot Yoga' },
    ],
  },
  {
    key: 'tennis',
    label: 'Tennis',
    subCategory: [{ key: 'doublesTennis', label: 'Doubles Tennis' }],
  },
];

// export const amenities = [
//   { key: 'ChangingRoom', label: 'Changing Room', icon: <ChangingRoom/> },
//   { key: 'Disabled', label: 'Disabled Access', icon: <Disabled /> },
//   { key: 'FreeParking', label: 'Free Parking', icon: <FreeParking /> },
//   { key: 'Lockers', label: 'Lockers', icon: <Lockers /> },
//   { key: 'PaidParking', label: 'Paid Parking', icon: <PaidParking /> },
//   { key: 'Restaurant', label: 'Restaurant', icon: <Restaurant /> },
//   { key: 'FloodLight', label: 'Flood Lights', icon: <FloodLight /> },
//   { key: 'Showers', label: 'Showers', icon: <Showers /> },
//   { key: 'WiFi', label: 'WiFi', icon: <WiFi /> },
//   { key: 'FirstAidKit', label: 'First Aid Kit', icon: <FirstAidKit /> },
//   { key: 'Bathroom', label: 'Bathroom', icon: <Bathroom /> },
//   { key: 'EquipmentHiring', label: 'Equipment Hiring', icon: <EquipmentHiring /> },
// ];
