import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import VenueCardImg from './VenueCard.png';
import css from './VenueCard.module.css';
import { ReactComponent as EditIcon } from '../../assets/icons/EditBox.svg';
import NoImageIcon from '../ResponsiveImage/NoImageIcon';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const GENERAL_INFO = 'generalInfo';

function VenueCardComponent(props) {
  const {
    title,
    duration,
    price,
    buffer,
    capacity,
    Vendashedit,
    images,
    type,
    listingId,
    history,
    l,
    currentUser
  } = props;
  return (
    <div className={css.VenueCardMain}>
      <div className={css.card}>
        <div className={css.VenueCardImg}>
          {images?.length > 0 ? (
            <img
              className={css.vcimg}
              src={images[0]?.attributes?.variants['landscape-crop']?.url}
            />
          ) : (
            <div className={css.noImageWrapper}>
              <NoImageIcon className={css.noImageIcon} />
              <div className={css.noImageText}>
                <FormattedMessage id="ResponsiveImage.noImage" />
              </div>
            </div>
          )}
        </div>
        <div className={css.VenueCardTitle}>{title}</div>
        <div className={css.separator}></div>
        <div className={css.VenueCardInfo}>
          <div className={css.duration}>
            <span className={css.label}>Duration:</span>
            <span className={css.value}>{duration}</span>
            <span className={css.label}>Min</span>
          </div>
          {
          l?.attributes?.publicData?.activity[0] !== 'facilityHire' ? (
            <div className={css.price}>
            <span className={css.label}>Price:</span>
            <span className={css.value}>{price}</span>
          </div>
          ) : null
          }
          {/* <div className={css.buffer}>
          <span className={css.label}>Buffer:</span>
          <span className={css.value}>{buffer}</span>
          <span className={css.label}>Min</span>
        </div> */}
          {
          l?.attributes?.publicData?.activity[0] !== 'facilityHire' ? (
            <div className={css.capacity}>
              <span className={css.label}>Capacity:</span>
              <span className={css.value}>{capacity}</span>
              <span className={css.label}>Person</span>
            </div>
          ) : null
          }

          <div className={css.separator}></div>

        </div>
        {
          currentUser?.attributes?.profile?.publicData?.isSuperAdmin ?
          (
          <div className={css.editBtnDiv}>
          <button
            onClick={() => {
              history.push(
                createResourceLocatorString(
                  'SuperAdminDashboard',
                  routeConfiguration(),
                  { tab: 'listings' },
                  {
                    actionType: 'edit',
                    lTab: GENERAL_INFO,
                    listingType: type,
                    listing: listingId,
                    // venueInfo: { ...listingVenue },
                  }
                )
              );
            }}
          >
            <EditIcon /> <span className={css.linkText}>Edit Listing</span>
          </button>
      </div>
          ):null
        }
        
      </div>

      {/* <div className={css.card}>
        <div className={css.VenueCardImg}>
          {images?.length > 0 ? (
            <img
              className={css.vcimg}
              src={images[0]?.attributes?.variants['landscape-crop']?.url}
            />
          ) : (
            <div className={css.noImageWrapper}>
              <NoImageIcon className={css.noImageIcon} />
              <div className={css.noImageText}>
                <FormattedMessage id="ResponsiveImage.noImage" />
              </div>
            </div>
          )}
        </div>
        <div className={css.VenueCardTitle}>{title}</div>
        <div className={css.separator}></div>
        <div className={css.VenueCardInfo}>
          <div className={css.duration}>
            <span className={css.label}>Duration:</span>
            <span className={css.value}>{duration}</span>
            <span className={css.label}>Min</span>
          </div>
          <div className={css.price}>
            <span className={css.label}>Price:</span>
            <span className={css.value}>{price}</span>
          </div>
          <div className={css.buffer}>
            <span className={css.label}>Buffer:</span>
            <span className={css.value}>{buffer}</span>
            <span className={css.label}>Min</span>
          </div>
          <div className={css.capacity}>
            <span className={css.label}>Capacity:</span>
            <span className={css.value}>{capacity}</span>
            <span className={css.label}>Person</span>
          </div>
          <div className={css.separator}></div>
          <div className={css.editBtnDiv}>
            <button>
              <EditIcon /> <span className={css.linkText}>Edit Listing</span>
            </button>
          </div>
        </div>
      </div>

      <div className={css.card}>
        <div className={css.VenueCardImg}>
          {images?.length > 0 ? (
            <img
              className={css.vcimg}
              src={images[0]?.attributes?.variants['landscape-crop']?.url}
            />
          ) : (
            <div className={css.noImageWrapper}>
              <NoImageIcon className={css.noImageIcon} />
              <div className={css.noImageText}>
                <FormattedMessage id="ResponsiveImage.noImage" />
              </div>
            </div>
          )}
        </div>
        <div className={css.VenueCardTitle}>{title}</div>
        <div className={css.separator}></div>
        <div className={css.VenueCardInfo}>
          <div className={css.duration}>
            <span className={css.label}>Duration:</span>
            <span className={css.value}>{duration}</span>
            <span className={css.label}>Min</span>
          </div>
          <div className={css.price}>
            <span className={css.label}>Price:</span>
            <span className={css.value}>{price}</span>
          </div>
          <div className={css.buffer}>
            <span className={css.label}>Buffer:</span>
            <span className={css.value}>{buffer}</span>
            <span className={css.label}>Min</span>
          </div>
          <div className={css.capacity}>
            <span className={css.label}>Capacity:</span>
            <span className={css.value}>{capacity}</span>
            <span className={css.label}>Person</span>
          </div>
          <div className={css.separator}></div>
          <div className={css.editBtnDiv}>
            <button>
              <EditIcon /> <span className={css.linkText}>Edit Listing</span>
            </button>
          </div>
        </div>
      </div>

      <div className={css.card}>
        <div className={css.VenueCardImg}>
          {images?.length > 0 ? (
            <img
              className={css.vcimg}
              src={images[0]?.attributes?.variants['landscape-crop']?.url}
            />
          ) : (
            <div className={css.noImageWrapper}>
              <NoImageIcon className={css.noImageIcon} />
              <div className={css.noImageText}>
                <FormattedMessage id="ResponsiveImage.noImage" />
              </div>
            </div>
          )}
        </div>
        <div className={css.VenueCardTitle}>{title}</div>
        <div className={css.separator}></div>
        <div className={css.VenueCardInfo}>
          <div className={css.duration}>
            <span className={css.label}>Duration:</span>
            <span className={css.value}>{duration}</span>
            <span className={css.label}>Min</span>
          </div>
          <div className={css.price}>
            <span className={css.label}>Price:</span>
            <span className={css.value}>{price}</span>
          </div>
          <div className={css.buffer}>
            <span className={css.label}>Buffer:</span>
            <span className={css.value}>{buffer}</span>
            <span className={css.label}>Min</span>
          </div>
          <div className={css.capacity}>
            <span className={css.label}>Capacity:</span>
            <span className={css.value}>{capacity}</span>
            <span className={css.label}>Person</span>
          </div>
          <div className={css.separator}></div>
          <div className={css.editBtnDiv}>
            <button>
              <EditIcon /> <span className={css.linkText}>Edit Listing</span>
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
}

const mapStateToProps = state => {
    const { currentUser, currentUserListing } = state.user;
    return {
      currentUser,
      currentUserListing,
    };
  };
//   const mapDispatchToProps = dispatch => ({
//     onImageUpload: data => dispatch(uploadImage(data)),
//     onUpdateProfile: data => dispatch(updateProfile(data)),
//   });

const VenueCard = compose(
  // connect(mapStateToProps, mapDispatchToProps),
  connect(mapStateToProps),
  withRouter,
  injectIntl
)(VenueCardComponent);

export default VenueCard;
