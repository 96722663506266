import React from 'react';
import { Toaster } from 'react-hot-toast';
import toast from 'react-hot-toast';

const ToastMessage = ({ position, delay }) => {
  return (
    <Toaster
      position={position}
      toastOptions={{
        className: '',
        duration: delay ?? 4000,
        style: {
          background: '#363636',
          color: '#fff',
        },
      }}
    />
  );
};

export const notify = (type, message) => toast[type](message);
export default ToastMessage;
