import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './LandingPageCard.css';
// import Tabs from 'react-responsive-tabs';
// IMPORTANT you need to include the default styles
// import 'react-responsive-tabs/styles.css';
import { BiUserPin } from 'react-icons/bi';

const presidents = [
  { name: `${(<BiUserPin />)} Sports Session`, biography: '<input type="text" />' },
  { name: 'Fitness Classes', biography: 'B' },
  { name: 'Facility Hire', biography: 'c' },
];
const getTabs = () => {
  return presidents.map((president, index) => ({
    key: index, // Optional. Equals to tab index if this property is omitted
    tabClassName: 'tab', // Optional
    panelClassName: 'panel panelabc', // Optional
    title: president.name,
    getContent: () => president.biography,
  }));
};
const LandingPageCard = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div></div>
    // <Tabs items={getTabs()} transformWidth={528} />

    // <Card sx={{ maxWidth: 345 }} className="card-lnd">
    //   <CardContent>
    //     <Typography gutterBottom variant="h5" component="div">
    //       Discover new amazing place around you
    //     </Typography>
    //     <Typography variant="body2" color="text.secondary">
    //       Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
    //       been the industry's standard dummy text ever since the 1500s, when an unknown printer took
    //       a galley
    //     </Typography>
    //   </CardContent>
    //   <CardActions>
    //     <Tabs value={value} onChange={handleChange} centered>
    //       <Tab label="Sports session" />
    //       <Tab label="Fitness session" />
    //       <Tab label="Facility Hire" />
    //     </Tabs>
    //   </CardActions>
    // </Card>
  );
};

export default LandingPageCard;
