import axios from 'axios';
import { fetchCurrentUser, updateFavCountSuccess } from '../../ducks/user.duck';
import { apiBaseUrl } from '../../util/api';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
const { UUID } = sdkTypes;
const RESULT_PAGE_SIZE = 8;

// ============Action Type =================
export const FETCH_ALL_LISTINGS_REQUEST = 'app/LandingPage/FETCH_ALL_LISTINGS_REQUEST';
export const FETCH_ALL_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_ALL_LISTINGS_SUCCESS';
export const FETCH_ALL_LISTINGS_ERROR = 'app/LandingPage/FETCH_ALL_LISTINGS_ERROR';

//=============Reducer=================
const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: null,
  queryListingsError: null,
  currentPageResultIds: [],
};
const resultIds = (data) => data.data.map((l) => l.id);

const landingPageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  //   console.log(888, payload);
  switch (type) {
    case FETCH_ALL_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_ALL_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data.data),
        pagination: payload.data.data.meta,
        queryInProgress: false,
      };
    case FETCH_ALL_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      return { ...state, queryInProgress: false, queryListingsError: payload };
    default:
      return { ...state };
  }
};
export default landingPageReducer;
//=======ActionCreators==============
export const queryListingsRequest = (currentUser) => ({
  type: FETCH_ALL_LISTINGS_REQUEST,
  payload: { currentUser },
});

export const queryListingsSuccess = (response) => {
  return {
    type: FETCH_ALL_LISTINGS_SUCCESS,
    payload: { data: response },
  };
};

export const queryListingsError = (e) => ({
  type: FETCH_ALL_LISTINGS_ERROR,
  error: true,
  payload: e,
});

//==========fetch from database ===========
export const getMarketplaceListings = (page) => async (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  dispatch(queryListingsRequest);
  // console.log(params);
  let apiResp;

  return sdk.listings
    .query({
      per_page: RESULT_PAGE_SIZE,
      pub_visibility: 'public',
      page,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        // Listing page
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Image carousel
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Avatars
        'vairants.default',
        'variants.square-small',
        'variants.square-small2x',
      ],
    })
    .then((response) => {
      apiResp = response;
      const promises = response.data.data?.map((d) => {
        const listingId = d.id.uuid;
        return axios.get(`${apiBaseUrl()}/api/listing/${listingId}`);
      });
      return Promise.all(promises);
    })
    .then((res) => {
      // console.log(555, apiResp, res);

      apiResp.data.data?.map((d) => {
        const current = res.find((r) => r.data.sharetribeId === d.id.uuid);
        d.attributes.publicData.fromMongo = current && current.data ? current.data : {};
      });
    })
    .then(() => {
      dispatch(addMarketplaceEntities(apiResp));
      dispatch(queryListingsSuccess(apiResp));
      //   console.log(5555, res);

      //   return res;
    })
    .catch((err) => dispatch(queryListingsError(err)));
  //   const totalFavCount = currentUser?.attributes?.profile?.privateData?.favourites?.length;
  //   // console.log(123, totalFavCount);
  //   // console.log(456, currentUser);
  //   if (!!totalFavCount) {
  //     dispatch(updateFavCountSuccess(totalFavCount));
  //   } else {
  //     dispatch(updateFavCountSuccess(0));
  //   }
  //   dispatch(queryListingsRequest(currentUser));
  //   const { favourites } = currentUser?.attributes?.profile?.privateData;

  //   if (!favourites) {
  //     return dispatch(queryListingsError('No Favourite Listing!'));
  //   }
};

export const loadData = (param, search) => async (dispatch, getState) => {
  const queryParams = parse(search);
  const queryPage = queryParams.page || 1;
  // const page = queryParams.page || 1;
  // const queryPage = page && Object.keys(page).length === 0 ? 0 : page;
  await dispatch(fetchCurrentUser());
  return dispatch(getMarketplaceListings());
};
