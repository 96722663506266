import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { OwnListingLink } from '../../components';

import css from './SectionHowItWorks.module.css';

// import Bgbtm from '../../assets/btnbg.jpg';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const SectionHowItWorks = (props) => {
  const {
    rootClassName,
    className,
    currentUserListing,
    currentUserListingFetched,
    history,
    location,
    currentUser,
    isAuthenticated,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.backnewsec}>
        {/* <img src={Bgbtm} /> */}

        <div className={css.backnwschild}>
          <div className={css.backnwscontent}>
            <div className={css.heading}>Can't find an activity that suits you?</div>
            <div className={css.backnwstxt}>
             <p>
              Don't sweat it, suggest it! We're always looking for new activities to add. Tell us
              your ideal activity with specific times, locations, and even skill levels. We'll make
              it happen
            </p>
            </div>

          </div>
          <div className={css.backnewsbtn}>
            <button
              className={css.btngbl}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                if (typeof window !== 'undefined')
                  window?.open(
                    'https://docs.google.com/forms/d/e/1FAIpQLSck-QxIwlAw-4AxTGCbvvabPxI-sLhaV-gFmPd77G98EMeuSw/viewform?usp=sf_link',
                    '_blank'
                  );
              }}
            >
              suggest an activity
            </button>
          </div>
        </div>
        <div className={classNames(css.backnwschild,css.backnwschildNew)}>
          <div className={css.backnwscontent}>
            <div className={css.heading}>For Fitness Instructors</div>
           <div className={css.backnwstxt}>
         
           <p>
              Share your expertise, build dedicated communities, and witness transformations fueled
              by your passion. We'll provide the platform, you provide the motivation and knowledge.
              Every sweat-drenched victory starts with you!
            </p>
            
           </div>


          </div>
          <div className={css.backnewsbtn}>
            {/* {isAuthenticated && ( */}
            <button
              className={css.btngbl}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                if (!isAuthenticated) {
                  return history.push({
                    pathname: '/login',
                    state: {
                      from: `${location.pathname}${location.search}${location.hash}`,
                    },
                  });
                }
                const params = { tab: 'listings' };

                if (!currentUser?.attributes?.profile.publicData.isVendor) {
                  // history.push(
                  //   createResourceLocatorString('SignupVendorPage', routeConfiguration(), params)
                  // );
                  window?.open(
                    'https://docs.google.com/forms/d/e/1FAIpQLSdR3l6le0PEuvgPBz4L44HuW7OSUoWxG4wmc8mdfxxrw7QfRg/viewform',
                    '_blank'
                  );
                } else {
                  history.push(
                    createResourceLocatorString(
                      'VendorDashboard',
                      routeConfiguration(),
                      params,

                      { type: 'allListings' }
                    )
                  );
                }
              }}
            >
              List your class
              {/* Learn more */}
            </button>
          </div>
        </div>
      </div>

      {/* <div className={css.steps}>
        <div className={css.step}>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part1Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionHowItWorks.part1Text" />
          </p>
        </div>

        <div className={css.step}>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part2Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionHowItWorks.part2Text" />
          </p>
        </div>

        <div className={css.step}>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part3Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionHowItWorks.part3Text" />
          </p>
        </div>
      </div> */}
      {/* <div className={css.createListingLink}>
        <OwnListingLink listing={currentUserListing} listingFetched={currentUserListingFetched}>
          <FormattedMessage id="SectionHowItWorks.createListingLink" />
        </OwnListingLink>
      </div> */}
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionHowItWorks;
