import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { TRANSACTION_TYPE_UPCOMMING, propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ListingLink,
  OwnListingLink,
  ExternalLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import css from './TopbarDesktop.module.css';

import MenuIcon from '../MenuIcon/MenuIcon';
import { BiMenu } from 'react-icons/bi';
import { HiUserCircle } from 'react-icons/hi';

const TopbarDesktop = (props) => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const signupLink = isAuthenticatedOrJustHydrated ? (
    ''
  ) : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? (
    ''
  ) : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const vendorDashboard =
    authenticatedOnClientSide && currentUser?.id ? (
      <NamedLink className={css.profileLink} name="VendorDashboardBasePage" params={{}}>
        Vendor Dashboard
      </NamedLink>
    ) : null;

  const SuperAdminDashboard =
    authenticatedOnClientSide && currentUser?.id ? (
      <NamedLink
        className={classNames(css.profileLink, css.dashboardLink)}
        name="SuperAdminDashboardBasePage"
        params={{}}
      >
        Super Admin Dashboard
      </NamedLink>
    ) : null;

  // {
  //   user.id ? (
  //   <NamedLink className={css.profileLink} name="ProfilePage" params={{ id: user.id.uuid }}>
  //     <FiEye />
  //     View Profile
  //   </NamedLink>
  // ) : null;
  // }

  const currentPageClass = (page) => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu className={css.profileMenuDiv}>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        {/* <svg
          width="20"
          height="14"
          viewBox="0 0 20 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 7H19"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 1H19"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 13H19"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg> */}
        <BiMenu />

        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {/* <MenuItem key="EditListingPage">
          <OwnListingLink
            listing={currentUserListing}
            listingFetched={currentUserListingFetched}
            className={css.yourListingsLink}
          >
            <div>
              {currentUserListing ? (
                <FormattedMessage id="TopbarDesktop.editYourListingLink" />
              ) : (
                <FormattedMessage id="TopbarDesktop.addYourListingLink" />
              )}
            </div>
          </OwnListingLink>
        </MenuItem> */}
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfilePage"
            params={{ id: currentUser?.id.uuid, tab: 'bookings' }}
            to={{ search: `?hTab=${TRANSACTION_TYPE_UPCOMMING}` }}
          >
            {/* <span className={css.menuItemBorder} /> */}
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            {/* <span className={css.menuItemBorder} /> */}
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="FavouritesPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('FavouritesPage'))}
            name="FavouritesPage"
          >
            {/* My Wishlist */}
            My favourites
          </NamedLink>
        </MenuItem>
        <MenuItem key={'inbox'}>
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span>
              <FormattedMessage id="TopbarDesktop.inbox" />
              {notificationDot}
            </span>
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            {/* <span className={css.menuItemBorder} /> */}
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <BiMenu />
        <HiUserCircle className={css.coloricon} />
      </MenuLabel>
      <MenuContent className={css.loginMenuContent}>
        <MenuItem key="LoginPage">{loginLink}</MenuItem>
        <MenuItem key="SignupPage">{signupLink}</MenuItem>
      </MenuContent>
    </Menu>
  );

  const listingLink =
    authenticatedOnClientSide && currentUserListingFetched && currentUserListing ? (
      <ListingLink
        className={css.createListingLink}
        listing={currentUserListing}
        children={
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.viewListing" />
          </span>
        }
      />
    ) : null;

  // const createListingLink =
  //   isAuthenticatedOrJustHydrated && !(currentUserListingFetched && !currentUserListing) ? null : (
  //     <NamedLink className={css.createListingLink} name="NewListingPage">
  //       <span className={css.createListing}>
  //         <FormattedMessage id="TopbarDesktop.createListing" />
  //       </span>
  //     </NamedLink>
  //   );

  const hostYourVenue = !isAuthenticated ? (
    <NamedLink
      className={css.createListingLink}
      name={isAuthenticated ? 'SignupVendorPage' : 'SignupPage'}
      to={!isAuthenticated ? { flow: 'vendor' } : {}}
    >
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.HostYourVenue" />
      </span>
    </NamedLink>
  ) : (
    <ExternalLink
      href={
        'https://docs.google.com/forms/d/e/1FAIpQLSdR3l6le0PEuvgPBz4L44HuW7OSUoWxG4wmc8mdfxxrw7QfRg/viewform'
      }
    >
      <span className={classNames(css.createListing, css.dashboardLink)}>
        <FormattedMessage id="TopbarDesktop.HostYourVenue" />
      </span>
    </ExternalLink>
  );
  return (
    <>
      <nav className={classes}>
        <NamedLink className={css.logoLink} name="LandingPage">
          <Logo
            format="desktop"
            className={css.logo}
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
          />
        </NamedLink>

        {/* {search} */}
        {/* {listingLink} */}
        {/* {hostYourVenue} */}
        {!currentUser?.attributes?.profile?.publicData?.requestForVendor && hostYourVenue}
        {/* {!currentUser?.attributes?.profile.publicData.isVendor &&
          !currentUser?.attributes?.profile.publicData.requestForVendor &&
          hostYourVenue} */}

        {currentUser?.attributes?.profile?.publicData?.isSuperAdmin && SuperAdminDashboard}
        {currentUser?.attributes?.profile?.publicData?.isVendor && vendorDashboard}

        {/* {inboxLink} */}
        {/* {menuIcon} */}

        {profileMenu}
        {/* {signupLink} */}
        {/* {loginLink} */}
      </nav>
    </>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
