import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../../util/errors';
import { parse } from '../../../util/urlHelpers';
import { TRANSITIONS } from '../../../util/transaction';
import { addMarketplaceEntities } from '../../../ducks/marketplaceData.duck';
import axios from 'axios';
import { apiBaseUrl } from '../../../util/api';

const sortedTransactions = (txs) =>
  reverse(
    sortBy(txs, (tx) => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/VendorDashboard/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/VendorDashboard/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/VendorDashboard/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_PRIVATE_CUSTOMERS_REQUEST =
  'app/VendorDashboard/FETCH_PRIVATE_CUSTOMERS_REQUEST';
export const FETCH_PRIVATE_CUSTOMERS_SUCCESS =
  'app/VendorDashboard/FETCH_PRIVATE_CUSTOMERS_SUCCESS';
export const FETCH_PRIVATE_CUSTOMERS_ERROR = 'app/VendorDashboard/FETCH_PRIVATE_CUSTOMERS_ERROR';

export const FETCH_MARKETPLACE_CUSTOMERS_REQUEST =
  'app/VendorDashboard/FETCH_MARKETPLACE_CUSTOMERS_REQUEST';
export const FETCH_MARKETPLACE_CUSTOMERS_SUCCESS =
  'app/VendorDashboard/FETCH_MARKETPLACE_CUSTOMERS_SUCCESS';
export const FETCH_MARKETPLACE_CUSTOMERS_ERROR =
  'app/VendorDashboard/FETCH_MARKETPLACE_CUSTOMERS_ERROR';

//------------------------------------
export const UPLOAD_IMAGE_REQUEST = 'app/ProfileSettingsPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/ProfileSettingsPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/ProfileSettingsPage/UPLOAD_IMAGE_ERROR';
export const REMOVE_IMAGE = 'app/ProfileSettingsPage/REMOVE_IMAGE';
// ================ Reducer ================ //

const entityRefs = (entities) =>
  entities.map((entity) => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  image: null,
  uploadImageError: null,
  uploadInProgress: false,
  updateInProgress: false,
  updateProfileError: null,

  //-----------------
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  fetchPrivateCustomersRequest: false,
  fetchPrivateCustomersSuccess: false,
  privateCustomers: [],
  fetchPrivateCustomersError: null,
  fetchMarketplaceCustomersRequest: false,
  fetchMarketplaceCustomersSuccess: false,
  marketplaceCustomers: [],
  fetchMarketplaceCustomersError: null,
};

export default function SuperAdminAgentsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case FETCH_PRIVATE_CUSTOMERS_REQUEST:
      return {
        ...state,
        fetchPrivateCustomersRequest: true,
        fetchPrivateCustomersError: null,
        fetchPrivateCustomersSuccess: false,
      };

    case FETCH_PRIVATE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        fetchPrivateCustomersSuccess: true,
        privateCustomers: payload,
        fetchPrivateCustomersRequest: false,
      };

    case FETCH_PRIVATE_CUSTOMERS_ERROR:
      return {
        ...state,
        fetchPrivateCustomersError: payload,
        fetchPrivateCustomersRequest: false,
        fetchPrivateCustomersSuccess: false,
        privateCustomers: [],
      };

    case FETCH_MARKETPLACE_CUSTOMERS_REQUEST:
      return {
        ...state,
        fetchMarketplaceCustomersRequest: true,
        fetchMarketplaceCustomersError: null,
        fetchMarketplaceCustomersSuccess: false,
      };

    case FETCH_MARKETPLACE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        fetchMarketplaceCustomersSuccess: true,
        marketplaceCustomers: payload,
        fetchMarketplaceCustomersRequest: false,
      };

    case FETCH_MARKETPLACE_CUSTOMERS_ERROR:
      return {
        ...state,
        fetchMarketplaceCustomersError: payload,
        fetchMarketplaceCustomersRequest: false,
        fetchMarketplaceCustomersSuccess: false,
        marketplaceCustomers: [],
      };
    case UPLOAD_IMAGE_REQUEST:
      // payload.params: { id: 'tempId', file }
      return {
        ...state,
        image: { ...payload.params },
        uploadInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS:
      // payload: { id: 'tempId', uploadedImage }
      const { uploadedImage } = payload;
      const { file, id } = state.image || {};
      const image = { id, file, uploadedImage };
      return { ...state, image, uploadInProgress: false };

    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, image: null, uploadInProgress: false, uploadImageError: payload.error };
    }
    case REMOVE_IMAGE: {
      return { ...state, image: null };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const removeImage = () => {
  return { type: REMOVE_IMAGE };
};

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = (response) => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = (e) => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const fetchPrivateCustomersRequest = () => ({ type: FETCH_PRIVATE_CUSTOMERS_REQUEST });
const fetchPrivateCustomersSuccess = (response) => ({
  type: FETCH_PRIVATE_CUSTOMERS_SUCCESS,
  payload: response,
});
const fetchPrivateCustomersError = (e) => ({
  type: FETCH_PRIVATE_CUSTOMERS_ERROR,
  error: true,
  payload: e,
});

const fetchMarketPlaceCustomersRequest = () => ({ type: FETCH_MARKETPLACE_CUSTOMERS_REQUEST });
const fetchMarketPlaceCustomersSuccess = (response) => ({
  type: FETCH_MARKETPLACE_CUSTOMERS_SUCCESS,
  payload: response,
});
const fetchMarketPlaceCustomersError = (e) => ({
  type: FETCH_MARKETPLACE_CUSTOMERS_ERROR,
  error: true,
  payload: e,
});

//-------------------------------------

export const uploadImageRequest = (params) => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = (result) => {
  console.log(result.data, 123);
  return { type: UPLOAD_IMAGE_SUCCESS, payload: result.data };
};
export const uploadImageError = (error) => ({
  type: UPLOAD_IMAGE_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

const PAGE_SIZE = 10;

export const fetchPrivateCustomer = (param) => (dispatch, getState, sdk) => {
  return axios
    .get(`${apiBaseUrl()}/api/private-customer`, {
      withCredentials: true,
    })
    .then((response) => {
      dispatch(fetchPrivateCustomersSuccess(response.data));
    })
    .catch((error) => {
      dispatch(fetchOrdersOrSalesError(error));
    });
};

export const fetchMarketPlaceCustomers = (param) => (dispatch, getState, sdk) => {
  const { page } = param;
  return axios
    .get(`${apiBaseUrl()}/api/marketplace-customer/${page}`, {
      withCredentials: true,
    })
    .then((response) => {
      dispatch(fetchMarketPlaceCustomersSuccess(response.data));
    })
    .catch((error) => {
      dispatch(fetchMarketPlaceCustomersError(error));
    });
};

export const fetchUser = (id) => (dispatch, getState, sdk) => {
  return sdk.users.show({
    id,
    include: ['profileImage', 'profile.publicData'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab, type } = params;
  const { page = 1 } = parse(search);

  const onlyFilterValues = {
    dashboard: 'dashboard',
    calendar: 'calendar',
    booking: 'booking',
    listings: 'listings',
    customers: 'customers',
    vendorRequest: 'vendorRequest',
    agents: 'agents',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for VendorDashboard: ${tab}`));
  }

  if (tab === 'customers' && type === 'list') {
    dispatch(fetchPrivateCustomersRequest());

    return dispatch(fetchPrivateCustomer({ page }));
  }

  if (tab === 'customers' && type === 'all') {
    dispatch(fetchMarketPlaceCustomersRequest());

    return dispatch(fetchMarketPlaceCustomers({ page }));
  }

  dispatch(fetchOrdersOrSalesRequest());

  const apiQueryParams = {
    only: 'order', //'sale',
    lastTransitions: TRANSITIONS,
    include: [
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
      'listing',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then((response) => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch((e) => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};

//-------------------------------------------

export function uploadImageS3(actionPayload) {
  console.log(actionPayload, 990);
  const { name, type } = actionPayload?.file || {};
  return (dispatch, getState) => {
    const id = actionPayload.id;

    dispatch(uploadImageRequest(actionPayload));

    const bodyParams = {
      image: actionPayload.file,
    };
    const queryParams = {
      expand: true,
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };
    console.log(996, id);

    return axios
      .post(`${apiBaseUrl()}/api/getSignedUrl`, {
        fileName: name,
        fileType: type,
      })
      .then((res) => {
        // setSelectedFile(prevState => [...prevState, srcFile]);
        // setSignedURL(signedURL => [...signedURL, res.data]);
        // setFileUploadSuccess(false);
        // console.log(type, res, 991);
        const { data } = res || {};

        return axios({
          method: 'put',
          url: data,
          data: actionPayload?.file,
          headers: { 'content-type': type },
          //   onUploadProgress: progressEvent => {
          //     let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          //     setFileUploadProgress(progress);
          //   },
        })
          .then((res) => {
            // setFileUploadInProgress(false);
            // setSelectedFile([]);
            // setSignedURL([]);
            // setFileUploadSuccess(true);
            // resolve(signedURL.split('?')[0]);

            // const data = {
            //   data: {
            //     id: {
            //       _sdkType: 'UUID',
            //       uuid: '641ac2a8-ac6e-407a-9891-d91e265b61b6',
            //     },
            //     type: 'image',
            //     attributes: {
            //       variants: {
            //         'square-small2x': {
            //           height: 480,
            //           width: 480,
            //           url:
            //             'https://sharetribe.imgix.net/636a7ee5-fa00-432a-85b0-f843c9d72ca3/641ac2a8-ac6e-407a-9891-d91e265b61b6?auto=format&crop=edges&fit=crop&h=480&w=480&s=8657de3a86e9d21906cb6cc90902ceb8',
            //           name: 'square-small2x',
            //         },
            //         'square-small': {
            //           height: 240,
            //           width: 240,
            //           url:
            //             'https://sharetribe.imgix.net/636a7ee5-fa00-432a-85b0-f843c9d72ca3/641ac2a8-ac6e-407a-9891-d91e265b61b6?auto=format&crop=edges&fit=crop&h=240&w=240&s=2f1fc21072f94c86ea5ff77791f026bf',
            //           name: 'square-small',
            //         },
            //       },
            //     },
            //   },
            // };
            const uploadedImage = data.split('?')[0];
            console.log(res, uploadedImage, 992);
            dispatch(
              uploadImageSuccess({
                data: { uploadedImage },
              })
            );
            // return uploadedImage;
          })
          .catch((e) => dispatch(uploadImageError({ id, error: storableError(e) })));
        //   .catch(e => {
        //     // setFileUploadError('Someting went wrong, please try again.');
        //     // setFileUploadInProgress(false);
        //     // setSelectedFile([]);
        //     // setSignedURL([]);
        //   });.catch(e => dispatch(uploadImageError({ id, error: storableError(e) })))
      })
      .catch((e) => console.log(e));

    // return sdk.images
    //   .upload(bodyParams, queryParams)
    //   .then(resp => {
    //     const uploadedImage = resp.data.data;
    //     dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));
    //   })
    //   .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}
