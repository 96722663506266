import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { apiBaseUrl } from '../../util/api';
import { ReactComponent as Pin } from '../../assets/location.svg';
import css from './VenueAdressComponent.module.css';

const VenueAdressComponent = (props) => {
  const { venueId, divClass } = props;
  const [venueName, setVenueName] = useState('');
  const [address, setAddress] = useState(null);
  const [bounds, setBounds] = useState('');
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    address === null &&
      !success &&
      venueId &&
      axios
        .get(`${apiBaseUrl()}/api/venue-detail/${venueId}`)
        .then((res) => {
          //not venueDisplayAddress
          setAddress(res.data?.venueDisplayAddress || '');
          setBounds(res.data.bound);
          setSuccess(true);
          setVenueName(res.data?.venueName || '');
        })
        .catch((err) => {
          setAddress(null);
          setSuccess(false);
        });
  });
  const googleURL = `https://www.google.com/maps/search/?api=1&query=${bounds?.lat},${bounds?.lng}`;

  return (
    <div className={divClass}>
      <b>Venue Address </b>
      <span className={css.dash}>-</span>
      <div
        className={css.add}
        onClick={(e) => {
          typeof window !== 'undefined' && window.open(googleURL);
        }}
      >
        {`${venueName} ${address}`}
        {}
      </div>
    </div>
  );
};

export default VenueAdressComponent;
