import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { ReactComponent as ChkIcn } from '../../assets/thank-you-check.svg';
import { ReactComponent as ExclmIcn } from '../../assets/exclaimationIcon.svg';
import { ReactComponent as Home } from '../../assets/homelogo.svg';
import classNames from 'classnames';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
  bookingSuccessfulTransitions,
  paymentIssueTransitions,
  txIsDelivered,
  txRoleIsProvider,
  getUserTxRole,
  TRANSITION_COMPLETE,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  txIsReviewed,
  txRoleIsCustomer,
  txIsInFirstReviewBy,
  transitionIsReviewed,
  isCustomerReview,
  isProviderReview,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  BookingPanel,
  InlineTextButton,
  NamedLink,
  ReviewModal,
  ReviewRating,
  UserDisplayName,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
} from './PanelHeading';

import css from './TransactionPanel.module.css';
import VenueAdressComponent from '../VenueAdressComponent/VenueAdressComponent';
import moment from 'moment';
import { createSlug } from '../../util/urlHelpers';
import NoImageIcon from '../ResponsiveImage/NoImageIcon';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};
const Review = (props) => {
  const { content, rating, className } = props;
  return (
    <div className={className}>
      <p className={css.reviewContent}>{content}</p>
      {rating ? (
        <ReviewRating
          reviewStarClassName={css.reviewStar}
          className={css.reviewStars}
          rating={rating}
        />
      ) : null}
    </div>
  );
};

Review.propTypes = {
  content: string.isRequired,
  rating: number.isRequired,
};
export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then((r) => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch((e) => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then((messageId) => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch((e) => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onFetchTimeSlots,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      monthlyTimeSlots,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      location: historyLocation,
    } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);

    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const stateDataFn = (tx) => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map((transition) => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;
    const slug = currentListing.attributes.deleted ? null : createSlug(listingTitle);
    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => onAcceptSale(currentTransaction.id)}
        onDeclineSale={() => onDeclineSale(currentTransaction.id)}
      />
    );

    const showSendMessageForm = false;
    // !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);
    const formattedTotalPrice = formatMoney(intl, currentTransaction?.attributes?.payinTotal);
    const duration = moment(publicData?.fromMongo?.scheduleDetails?.endTime, 'h:mm').diff(
      moment(publicData?.fromMongo?.scheduleDetails?.startTime, 'h:mm'),
      'hours'
    );
    const listingCategory = currentTransaction.attributes?.protectedData?.category;
    const imgUrl = firstImage?.attributes?.variants?.['landscape-crop2x']?.url;
    const listingDetailsMaybe = (
      <div className={css.seconddiv}>
        {imgUrl ? (
          <div className={css.imgdiv}>
            <img src={firstImage?.attributes?.variants?.['landscape-crop2x']?.url} />
          </div>
        ) : (
          <div className={css.noImageWrapper}>
            <NoImageIcon className={css.noImageIcon} />
            <div className={css.noImageText}>
              <FormattedMessage id="ResponsiveImage.noImage" />
            </div>
          </div>
        )}

        <div className={css.listingdetdv}>
          {currentListing.attributes.deleted ? (
            <p className={css.titlep}>{listingTitle}</p>
          ) : (
            <NamedLink
              name="ListingPage"
              params={{ id: currentListing?.id?.uuid, slug }}
              to={{ search: listingCategory ? `?category=${listingCategory}` : '' }}
            >
              <p className={css.titlep}>{listingTitle}</p>
            </NamedLink>
          )}

          <div className={`${css.refAdd} ${css.venadd}`}>
            <b>Booking reference</b>
            <span className={css.dash}>-</span>

            <span>
              {typeof currentTransaction?.id?.uuid === 'string'
                ? ` ${currentTransaction?.id?.uuid.substring(
                    currentTransaction?.id?.uuid.length - 7,
                    currentTransaction?.id?.uuid.length - 1
                  )}`
                : ' EJHGDHEH'}
            </span>
          </div>
          <VenueAdressComponent
            venueId={currentListing?.attributes?.publicData?.venueId}
            divClass={css.venadd}
          />
          <div className={css.infos}>
            {/* <Football /> */}
            <span className={css.info}>
              {publicData?.activity?.[0] === 'facilityHire'
                ? listingCategory
                  ? publicData?.category?.find((c) => c === listingCategory)
                  : publicData?.category?.[0]
                : publicData?.category?.[0]}
            </span>
            {/* <span className={css.info}> */}
            {publicData?.activity?.[0] === 'facilityHire' ? (
              listingCategory ? (
                <span className={css.info}>
                  {' '}
                  {
                    publicData?.fromMongo?.sportsInfo?.find((f) => f.sportName === listingCategory)
                      ?.subCategory
                  }{' '}
                </span>
              ) : publicData?.subCategory?.[0] ? (
                <span className={css.info}>{publicData?.subCategory?.[0]}</span>
              ) : null
            ) : publicData?.subCategory?.[0] ? (
              <span className={css.info}>{publicData?.subCategory?.[0]}</span>
            ) : null}
            {/* </span> */}

            {publicData?.activity?.[0] === 'facilityHire' ? null : (
              <span className={css.info}>
                {publicData?.difficulty === 'medium'
                  ? 'Intermediate Level'
                  : publicData?.difficulty === 'easy'
                  ? 'Beginner Level'
                  : publicData?.difficulty === 'hard'
                  ? 'Advanced Level'
                  : publicData?.difficulty === 'allLevels'
                  ? 'All Levels'
                  : ''}
              </span>
            )}

            {publicData?.activity?.[0] === 'facilityHire' ? null : (
              <span className={css.info}>
                {publicData?.gender === 'male'
                  ? 'Men Only'
                  : publicData?.gender === 'female'
                  ? 'Women Only'
                  : ''}
              </span>
            )}
            {publicData?.activity?.[0] === 'facilityHire' ? null : (
              <span className={css.info}>
                {duration} {duration > 1 ? 'hours' : 'hour'}
              </span>
            )}
          </div>
          {/* <div className={css.pricediv}>
            <p>Amount paid</p>
            <p>{formattedTotalPrice}</p>
          </div> */}
          <BreakdownMaybe
            className={css.breakdownContainer}
            transaction={currentTransaction}
            transactionRole={transactionRole}
            intl={intl}
          />
        </div>
      </div>
    );

    const isBookingSuccessful = bookingSuccessfulTransitions.includes(
      currentTransaction.attributes?.lastTransition
    );
    const isPaymentIssue = paymentIssueTransitions.includes(
      currentTransaction.attributes?.lastTransition
    );
    const bMessage = isBookingSuccessful
      ? 'We’re so glad that you found what you were looking for. We hope you are satisfied with your reservation.'
      : isPaymentIssue
      ? 'we regret to inform you that the payment for this transaction was not successful. If the payment was successful from your end, try to refresh this page. For further assistance, please contact us.'
      : '';
    const infoDivMaybe = (
      <div className={css.tydv}>
        {isPaymentIssue ? <ExclmIcn /> : <ChkIcn />}
        <p className={css.tymsgp}>{bMessage}</p>
      </div>
    );
    const hasUserLeftAReviewFirst = (userRole, transaction) => {
      // Because function txIsInFirstReviewBy uses isCustomer to check in which state the reviews are
      // we should also use isCustomer insted of isProvider
      const isCustomer = txRoleIsCustomer(userRole);
      return txIsInFirstReviewBy(transaction, isCustomer);
    };
    const ownRole = getUserTxRole(currentUser.id, currentTransaction);
    const otherUsersName = txRoleIsProvider(ownRole) ? (
      <UserDisplayName user={currentTransaction.customer} intl={intl} />
    ) : (
      <UserDisplayName user={currentTransaction.provider} intl={intl} />
    );
    const reviewSectionMaybe = (transition) => {
      const isOwnTransition =
        currentTransaction.attributes.transitions.find((tr) => tr.transition === transition).by ===
        ownRole;
      switch (transition) {
        case TRANSITION_COMPLETE:
          // Show the leave a review link if the state is delivered and if the current user is the first to leave a review
          const reviewPeriodJustStarted = txIsDelivered(transaction);

          const reviewAsFirstLink = reviewPeriodJustStarted ? (
            <InlineTextButton onClick={this.onOpenReviewModal}>
              <FormattedMessage
                id="ActivityFeed.leaveAReview"
                values={{ displayName: otherUsersName }}
              />
            </InlineTextButton>
          ) : null;

          return (
            <FormattedMessage
              id="ActivityFeed.transitionComplete"
              values={{ reviewLink: reviewAsFirstLink }}
            />
          );

        case TRANSITION_REVIEW_1_BY_PROVIDER:
        case TRANSITION_REVIEW_1_BY_CUSTOMER:
          if (isOwnTransition) {
            return (
              <FormattedMessage
                id="ActivityFeed.ownTransitionReview"
                values={{ displayName: otherUsersName }}
              />
            );
          } else {
            // show the leave a review link if current user is not the first
            // one to leave a review
            const reviewPeriodIsOver = txIsReviewed(transaction);
            const userHasLeftAReview = hasUserLeftAReviewFirst(ownRole, transaction);
            const reviewAsSecondLink = !(reviewPeriodIsOver || userHasLeftAReview) ? (
              <InlineTextButton onClick={this.onOpenReviewModal}>
                <FormattedMessage
                  id="ActivityFeed.leaveAReviewSecond"
                  values={{ displayName: otherUsersName }}
                />
              </InlineTextButton>
            ) : null;
            return (
              <FormattedMessage
                id="ActivityFeed.transitionReview"
                values={{ displayName: otherUsersName, reviewLink: reviewAsSecondLink }}
              />
            );
          }
        case TRANSITION_REVIEW_2_BY_PROVIDER:
        case TRANSITION_REVIEW_2_BY_CUSTOMER:
          if (isOwnTransition) {
            return (
              <FormattedMessage
                id="ActivityFeed.ownTransitionReview"
                values={{ displayName: otherUsersName }}
              />
            );
          } else {
            return (
              <FormattedMessage
                id="ActivityFeed.transitionReview"
                values={{ displayName: otherUsersName, reviewLink: null }}
              />
            );
          }

        default:
          return null;
      }
    };
    const reviewByAuthorId = (transaction, userId) => {
      return transaction.reviews.filter(
        (r) => !r.attributes.deleted && r.author.id.uuid === userId.uuid
      )[0];
    };
    const deletedReviewContent = intl.formatMessage({ id: 'ActivityFeed.deletedReviewContent' });

    const showReviewDiv = transitionIsReviewed(currentTransaction.attributes.lastTransition)
      ? currentTransaction.attributes.transitions.map((tr) => {
          if (
            ![
              TRANSITION_REVIEW_2_BY_PROVIDER,
              TRANSITION_REVIEW_2_BY_CUSTOMER,
              TRANSITION_REVIEW_1_BY_PROVIDER,
              TRANSITION_REVIEW_1_BY_CUSTOMER,
            ].includes(tr.transition)
          )
            return null;
          if (isCustomerReview(tr.transition)) {
            const review = reviewByAuthorId(currentTransaction, currentTransaction.customer.id);
            return review ? (
              <Review
                className={classNames(css.review, {
                  [css.othrUserReview]: isCustomer && isCustomerReview(tr.transition),
                })}
                content={review.attributes.content}
                rating={review.attributes.rating}
              />
            ) : (
              <Review
                className={classNames(css.review, {
                  [css.othrUserReview]: isCustomer && isCustomerReview(tr.transition),
                })}
                content={deletedReviewContent}
              />
            );
          } else if (isProviderReview(tr.transition)) {
            const review = reviewByAuthorId(currentTransaction, currentTransaction.provider.id);
            return review ? (
              <Review
                className={classNames(css.review, {
                  [css.othrUserReview]: isProvider && isProviderReview(tr.transition),
                })}
                content={review.attributes.content}
                rating={review.attributes.rating}
              />
            ) : (
              <Review
                className={classNames(css.review, {
                  [css.othrUserReview]: isProvider && isProviderReview(tr.transition),
                })}
                content={deletedReviewContent}
              />
            );
          } else {
            return null;
          }
        })
      : null;

    return (
      <div className={classes}>
        <div className={css.container}>
          {/* <div className={css.txInfo}> */}
          {infoDivMaybe}
          {isBookingSuccessful && listingDetailsMaybe}
          <div className={css.bookinfo}>
            {' '}
            <div className={css.otherUser}>
              <p>{reviewSectionMaybe(currentTransaction.attributes.lastTransition)}</p>
              {isProvider ? (
                <div className={css.avatarWrapperProviderDesktop}>
                  <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
                  <div className={css.userInf}>
                    <p>CUSTOMER</p>
                    <p>
                      {customerDisplayName}{' '}
                      {currentTransaction?.reviews?.length > 0 ? <span>Reviews</span> : null}
                    </p>
                  </div>
                </div>
              ) : (
                <div className={css.avatarWrapperProviderDesktop}>
                  <AvatarLarge user={currentProvider} className={css.avatarDesktop} />
                  <div className={css.userInf}>
                    <p>PROVIDER</p>
                    <p>
                      {authorDisplayName}{' '}
                      {currentTransaction?.reviews?.length > 0 ? <span>Reviews</span> : null}
                    </p>
                  </div>
                </div>
              )}
              <div className={css.reviewsDiv}>{showReviewDiv}</div>
            </div>
            {/* <BreakdownMaybe
              className={css.breakdownContainer}
              transaction={currentTransaction}
              transactionRole={transactionRole}
              intl={intl}
            /> */}
          </div>

          <div className={css.gohomedv}>
            <Home /> <NamedLink name="LandingPage">Go to Homepage</NamedLink>
          </div>

          {/* <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={currentProvider}
              isCustomer={isCustomer}
              listingId={currentListing.id && currentListing.id.uuid}
              listingDeleted={listingDeleted}
            />
            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null} */}

          {/* <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
            />

            <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              <BreakdownMaybe transaction={currentTransaction} transactionRole={transactionRole} />
            </div>

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null} */}

          {/* <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
            /> */}
          {/* {showSendMessageForm ? (
              <SendMessageForm
                formId={this.sendMessageFormName}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={sendMessagePlaceholder}
                inProgress={sendMessageInProgress}
                sendMessageError={sendMessageError}
                onFocus={this.onSendMessageFormFocus}
                onBlur={this.onSendMessageFormBlur}
                onSubmit={this.onMessageSubmit}
              />
            ) : (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            )} */}

          {/* {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null} */}
        </div>

        {/* <div className={css.asideDesktop}>
            <div className={css.detailCard}> */}
        {/* <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
                listingId={currentListing.id && currentListing.id.uuid}
                listingDeleted={listingDeleted}
              />

              <DetailCardHeadingsMaybe
                showDetailCardHeadings={stateData.showDetailCardHeadings}
                listingTitle={listingTitle}
                subTitle={bookingSubTitle}
                location={location}
                geolocation={geolocation}
                venueAddress={currentListing.attributes.publicData?.venueAddress}
                showAddress={stateData.showAddress}
              /> */}
        {/* {stateData.showBookingPanel ? (
                <BookingPanel
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  isOwnListing={false}
                  listing={currentListing}
                  title={listingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                />
              ) : null}
              <BreakdownMaybe
                className={css.breakdownContainer}
                transaction={currentTransaction}
                transactionRole={transactionRole}
              /> */}

        {/* {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null} */}
        {/* </div>
          </div> */}
        {/* </div> */}
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
