import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { compose } from 'redux';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import Select, { components } from 'react-select';
import { FieldArray } from 'react-final-form-arrays';
// import { Accordion } from 'react-bootstrap';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import { ReactComponent as CrossRound } from '../../assets/icons/crossRound.svg';

import {
  Form,
  Button,
  InlineTextButton,
  IconClose,
  PrimaryButton,
  FieldSelect,
  FieldTimeZoneSelect,
  FieldTextInput,
  FieldCheckbox,
  AddImages,
  ValidationError,
  IconSpinner,
} from '../../components';
import FileUpload from '../../components/FileUpload/FileUpload';
import { ReactComponent as AddCircle } from '../../assets/icons/addCircle.svg';

import { ReactComponent as ChangingRoom } from '../../assets/icons/ChangingRoom.svg';
import { ReactComponent as Bathroom } from '../../assets/icons/Bathroom.svg';
import { ReactComponent as Disabled } from '../../assets/icons/Disabled.svg';
import { ReactComponent as EquipmentHiring } from '../../assets/icons/EquipmentHiring.svg';
import { ReactComponent as FirstAidKit } from '../../assets/icons/FirstAidKit.svg';
import { ReactComponent as FloodLight } from '../../assets/icons/FloodLight.svg';
import { ReactComponent as FreeParking } from '../../assets/icons/FreeParking.svg';
import { ReactComponent as Lockers } from '../../assets/icons/Lockers.svg';
import { ReactComponent as PaidParking } from '../../assets/icons/PaidParking.svg';
import { ReactComponent as Restaurant } from '../../assets/icons/Restaurant.svg';
import { ReactComponent as Showers } from '../../assets/icons/Showers.svg';
import { ReactComponent as WiFi } from '../../assets/icons/WiFi.svg';

import { ReactComponent as AccordionIcon } from '../../assets/icons/accordionIcon.svg';

import css from './ListingSportsInfoForm.module.css';
// import css from '../createVenueForm/CreateVenueForm.module.css';

import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { withRouter } from 'react-router-dom';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import { isUploadImageOverLimitError } from '../../util/errors';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import { AiOutlineClose } from 'react-icons/ai';
// import MyRichTextEditor from '../../RichTextEditor/MyRichTextEditor';

const RichEditor = lazy(() => import('../../components/RichEditor/RichEditor'));

// const sportsData = [
//   { key: 'cricket', label: 'Cricket', subCategories: ['T20', 'IPL', 'ODI'] },
//   { key: 'football', label: 'Football', subCategories: ['5-side', '11-side', '13-side'] },
//   { key: 'badminton', label: 'Badminton', subCategories: ['single', 'double'] },
//   { key: 'tenis', label: 'Tenis', subCategories: ['single', 'double'] },
// ];

const aminities = [
  // { key: 'lorem1', label: 'Lorem1', icon: <ChangingRoomIcon /> },
  // { key: 'lorem2', label: 'Lorem2', icon: <ChangingRoomIcon /> },
  // { key: 'lorem3', label: 'Lorem3', icon: <ChangingRoomIcon /> },
  // { key: 'lorem4', label: 'Lorem4', icon: <ChangingRoomIcon /> },
  // { key: 'lorem5', label: 'Lorem5', icon: <ChangingRoomIcon /> },
  // { key: 'lorem6', label: 'Lorem6', icon: <ChangingRoomIcon /> },
  // { key: 'lorem7', label: 'Lorem7', icon: <ChangingRoomIcon /> },
  // { key: 'lorem8', label: 'Lorem8', icon: <ChangingRoomIcon /> },
  // { key: 'lorem9', label: 'Lorem9', icon: <ChangingRoomIcon /> },
  // { key: 'lorem10', label: 'Lorem10', icon: <ChangingRoomIcon /> },
  { key: 'ChangingRoom', label: 'Changing Room', icon: <ChangingRoom /> },
  { key: 'Disabled', label: 'Disabled Access', icon: <Disabled /> },
  { key: 'FreeParking', label: 'Free Parking', icon: <FreeParking /> },
  { key: 'Lockers', label: 'Lockers', icon: <Lockers /> },
  { key: 'PaidParking', label: 'Paid Parking', icon: <PaidParking /> },
  { key: 'Restaurant', label: 'Restaurant', icon: <Restaurant /> },
  { key: 'FloodLight', label: 'Flood Lights', icon: <FloodLight /> },
  { key: 'Showers', label: 'Showers', icon: <Showers /> },
  { key: 'WiFi', label: 'WiFi', icon: <WiFi /> },
  { key: 'FirstAidKit', label: 'First Aid Kit', icon: <FirstAidKit /> },
  { key: 'Bathroom', label: 'Bathroom', icon: <Bathroom /> },
  { key: 'EquipmentHiring', label: 'Equipment Hiring', icon: <EquipmentHiring /> },
];

const durationType = [
  { key: 'session', label: 'Session based' },
  { key: 'time', label: 'Time based' },
];

const halfFacility = { key: 'half', label: 'Half Facility' };
const quaterFacility = { key: 'quater', label: 'Quater Facility' };

const SHOW_MIN_MAX_CAPACITY = 'showMinMaxCapacity';
const SELECT_ATTENDEES = 'selectAttendees';
const MULTIPLY_CHARGES_BY_ATTENDEES = 'multiPlyChargesByAttendee';
const MULTIPLY_DEPOSIT_BY_ATTENDEES = 'multiPlyDepositByAttendee';
const BLOCK_TIME_SLOT = 'blockTimeSlot';
const ACCEPT_IMAGES = 'image/*';

const Control = ({ children, ...props }) => {
  return (
    <components.Control
      {...props}
      className={classNames(css.selectControl, { [css.selectControlFocused]: props.isFocused })}
    >
      {children}
    </components.Control>
  );
};

const FormContent = (props) => {
  const {
    values,
    intl,
    sport,
    editorText,
    setEditorText,
    sportIdx,
    form,
    setEditorContent,
    editorContent,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    page,
    images,
    fetchErrors,
    imageUploadRequested,
    setImageUploadRequested,
    stListingData,
    facilityImage,
    sportsData,
  } = props;
  const [imageTypeError, setImageTypeError] = useState(false);
  const sportKey = sport?.key;

  const savedFacilityImages = stListingData?.attributes?.publicData?.facilityImage;

  //sub category
  const facilitySubCategoryLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilitySubCategoryLabel',
  });

  const facilitySubCategoryPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilitySubCategoryPlaceholder',
  });

  const facilitySubCategoryRequiredMessage = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilitySubCategoryRequiredMessage',
  });

  //image
  const facilityImageLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityImageLabel',
  });

  //description
  const facilityDescriptionLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityDescriptionLabel',
  });
  const facilityDescriptionPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityDescriptionPlaceholder',
  });

  const facilityDescriptionRequiredMessage = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityDescriptionRequiredMessage',
  });

  //aminities
  const facilityAminitiesLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityAminitiesLabel',
  });

  //price

  const facilityPriceForAdultLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityPriceForAdultLabel',
  });
  const facilityPriceForAdultPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityPriceForAdultPlaceholder',
  });

  const priceForAdultRequiredMessage = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.priceForAdultRequiredMessage',
  });

  const facilitypriceForChildrenLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilitypriceForChildrenLabel',
  });
  const facilitypriceForChildrenPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilitypriceForChildrenPlaceholder',
  });

  const priceForChildRequiredMessage = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.priceForChildRequiredMessage',
  });

  const facilityPriceForInfantsLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityPriceForInfantsLabel',
  });
  const facilityPriceForInfantsPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityPriceForInfantsPlaceholder',
  });

  const priceForInfantsRequiredMessage = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.priceForInfantsRequiredMessage',
  });

  const facilityPeakTimingPriceLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityPeakTimingPriceLabel',
  });
  const facilityPeakTimingPricePlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityPeakTimingPricePlaceholder',
  });

  const priceForPeakTimeRequiredMessage = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.priceForPeakTimeRequiredMessage',
  });

  const facilityOffPeakTimingPriceLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityOffPeakTimingPriceLabel',
  });
  const facilityOffPeakTimingPricePlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityOffPeakTimingPricePlaceholder',
  });

  const priceForOffPeakTimeRequiredMessage = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.priceForOffPeakTimeRequiredMessage',
  });

  //duration and price

  const facilityDurationTypeLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityDurationTypeLabel',
  });

  const durationTypeRequiredMessage = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.durationTypeRequiredMessage',
  });

  const facilityDurationNameLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityDurationNameLabel',
  });

  const facilityDurationNamePlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityDurationNamePlaceholder',
  });

  const durationNameRequiredMessage = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.durationNameRequiredMessage',
  });

  const facilityTimeIntervalabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityTimeIntervalabel',
  });

  const facilityTimeIntervalPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityTimeIntervalPlaceholder',
  });

  const timeIntervalRequiredMessage = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.timeIntervalRequiredMessage',
  });

  const facilityQunatityLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityQunatityLabel',
  });

  const facilityQuantityPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityQuantityPlaceholder',
  });

  const facilityFullPriceLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityFullPriceLabel',
  });

  const facilityFullPricePlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityFullPricePlaceholder',
  });

  const facilityDepositLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityDepositLabel',
  });

  const facilityDepositPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityDepositPlaceholder',
  });

  //Rental extra equipment

  const facilityEquipmentNameLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityEquipmentNameLabel',
  });

  const facilityEquipmentNamePlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityEquipmentNamePlaceholder',
  });

  const equipmentNameRequiredMessage = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.equipmentNameRequiredMessage',
  });

  const facilityEquipmentDurationLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityEquipmentDurationLabel',
  });

  const facilityEquipmentDurationPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityEquipmentDurationPlaceholder',
  });

  const equipmentDurationRequiredMessage = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.equipmentDurationRequiredMessage',
  });

  const facilityEquipmentChargeAmountLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityEquipmentChargeAmountLabel',
  });

  const facilityEquipmentChargeAmountPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityEquipmentChargeAmountPlaceholder',
  });

  const facilityEquipmentMaxAmountLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityEquipmentMaxAmountLabel',
  });

  const facilityEquipmentMaxAmountPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityEquipmentMaxAmountPlaceholder',
  });

  const facilityEquipmentMultiplyCostLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityEquipmentMultiplyCostLabel',
  });

  //group info
  const facilityMinCapacityLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityMinCapacityLabel',
  });

  const facilityMinCapacityPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityMinCapacityPlaceholder',
  });

  const facilityMaxCapacityLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityMaxCapacityLabel',
  });

  const facilityMaxCapacityPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityMaxCapacityPlaceholder',
  });

  const facilityMinMaxCapacityLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityMinMaxCapacityLabel',
  });

  const facilitySelectAttendeesLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilitySelectAttendeesLabel',
  });

  const facilityMultiplyChargesByAttendeesLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityMultiplyChargesByAttendeesLabel',
  });

  const facilityMultiplyDepositByAttendeesLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityMultiplyDepositByAttendeesLabel',
  });

  const facilityBlockTimeSlotLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.facilityBlockTimeSlotLabel',
  });

  //editor

  const rulesLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.rulesLabel',
  });
  const rulesPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.rulesPlaceholder',
  });

  const whatToBringLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.whatToBringLabel',
  });
  const whatToBringPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.whatToBringPlaceholder',
  });

  const whatIsProvidedLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.whatIsProvidedLabel',
  });
  const whatIsProvidedPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.whatIsProvidedPlaceholder',
  });

  const thingsToKnowLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.thingsToKnowLabel',
  });
  const thingsToKnowPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.thingsToKnowPlaceholder',
  });

  const whatYouDoLabel = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.whatYouDoLabel',
  });
  const whatYouDoPlaceholder = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.whatYouDoPlaceholder',
  });

  //numeric valid

  const validNumberRequiredMessage = intl.formatMessage({
    id: 'SuperAdminDashboardNewListing.validNumberRequiredMessage',
  });

  //Group_Booking
  const priceForminimumCapacityRequiredMessage = intl.formatMessage({
    id: 'SuperAdminDashboardSessionListing.priceForminimumCapacityRequiredMessage',
  });
  const priceFormaximumCapacityRequiredMessage = intl.formatMessage({
    id: 'SuperAdminDashboardSessionListing.priceFormaximumCapacityRequiredMessage',
  });
  const maximumCheckingRequiredMessage = intl.formatMessage({
    id: 'SuperAdminDashboardSessionListing.maximumCheckingRequiredMessage',
  });
  const minimumCheckingRequiredMessage = intl.formatMessage({
    id: 'SuperAdminDashboardSessionListing.minimumCheckingRequiredMessage',
  });

  //validators
  const checkLen = (val, min) =>
    validators.maximumChecking(val, min, maximumCheckingRequiredMessage);
  const checkMin = (val, max) => validators.minimumChecking(val, max);
  const numberRequired = (val) => validators.requiredNumber(validNumberRequiredMessage, val);
  const facilityDescriptionRequired = validators.required(facilityDescriptionRequiredMessage);
  const facilitySubCategoryRequired = validators.required(facilitySubCategoryRequiredMessage);
  const durationTypeRequired = validators.required(durationTypeRequiredMessage);
  const durationNameRequired = validators.required(durationNameRequiredMessage);
  const timeIntervalRequired = validators.required(timeIntervalRequiredMessage);
  const equipmentNameRequired = validators.required(equipmentNameRequiredMessage);
  const equipmentDurationRequired = validators.required(equipmentDurationRequiredMessage);

  const priceForAdultRequired = validators.required(priceForAdultRequiredMessage);
  const priceForChildRequired = validators.required(priceForChildRequiredMessage);
  const priceForInfantsRequired = validators.required(priceForInfantsRequiredMessage);
  const priceForPeakTimeRequired = validators.required(priceForPeakTimeRequiredMessage);
  const priceForOffPeakTimeRequired = validators.required(priceForOffPeakTimeRequiredMessage);
  const minimumCapacityRequired = validators.required(priceForminimumCapacityRequiredMessage);
  const maximumCapacityRequired = validators.required(priceFormaximumCapacityRequiredMessage);

  const getImageSrc = (srcs) => {
    const sportName = sportKey;
    if (Object.keys(values).includes(sportName)) {
      values[sportName].images = srcs;
    } else {
      values[sportName] = { images: srcs };
    }
  };

  const groupBookingCheck = [
    { key: SHOW_MIN_MAX_CAPACITY, label: facilityMinMaxCapacityLabel },
    { key: SELECT_ATTENDEES, label: facilitySelectAttendeesLabel },
    { key: MULTIPLY_CHARGES_BY_ATTENDEES, label: facilityMultiplyChargesByAttendeesLabel },
    { key: MULTIPLY_DEPOSIT_BY_ATTENDEES, label: facilityMultiplyDepositByAttendeesLabel },
    { key: BLOCK_TIME_SLOT, label: facilityBlockTimeSlotLabel },
  ];

  const chooseImageText = (
    <span className={css.chooseImageText}>
      <span className={css.chooseImage}>
        <FormattedMessage id="EditListingPhotosForm.chooseImage" />
      </span>
      <span className={css.imageTypes}>
        <FormattedMessage id="EditListingPhotosForm.imageTypes" />
      </span>
    </span>
  );

  const { publishListingError, showListingsError, updateListingError, uploadImageError } =
    fetchErrors || {};
  const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

  let uploadImageFailed = null;

  if (uploadOverLimit) {
    uploadImageFailed = (
      <p className={css.error}>
        <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
      </p>
    );
  } else if (uploadImageError) {
    uploadImageFailed = (
      <p className={css.error}>
        <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
      </p>
    );
  }

  const onImageUploadHandler = (file, values, sports) => {
    if (file) {
      setImageUploadRequested(true);
      onImageUpload({
        id: `${sports}-${file.name}_${Date.now()}`,
        file,
        restFormValues: { ...values },
      })
        .then(() => {
          setImageUploadRequested(false);
        })
        .catch(() => {
          setImageUploadRequested(false);
        });
    }
  };

  const isSessionBased = values?.durationType === 'session';
  const isTimeBased = values?.durationType === 'time';
  return (
    <div>
      {sport?.subCategories?.length > 0 && (
        <div>
          <label>{facilitySubCategoryLabel}</label>
          <FieldSelect
            id={`${sportKey}.subCategory`}
            name={`${sportKey}.subCategory`}
            // validate={facilitySubCategoryRequired}
          >
            <option disabled value="">
              {facilitySubCategoryPlaceholder}
            </option>
            {sport?.subCategories?.map(({ key, name }) => (
              <option value={key} key={key}>
                {name}
              </option>
            ))}
          </FieldSelect>
        </div>
      )}
      <div>
        <div className={css.sectionhead}>
          <span>Media</span> <hr />
        </div>
        {/* <FileUpload
          name={sportKey}
          isMultiple={true}
          imageLabel={facilityImageLabel}
          getImageSrc={getImageSrc}
          // getImageBlob={getImageBlob}
        /> */}
        {/* <AddImages
          className={css.imagesField}
          images={
            `${sportKey}.images`
              ? images.filter((i) => i?.file?.meta === `${sportKey}`)
              : images.filter((i) => i.id.uuid === `${sportKey}.ImageId`)
          }
          //   images={images}
          thumbnailClassName={css.thumbnail}
          savedImageAltText={intl.formatMessage({
            id: 'EditListingPhotosForm.savedImageAltText',
          })}
          onRemoveImage={onRemoveImage}
        > */}

        <AddImages
          className={css.imagesField}
          // images={images}
          images={
            !isEmpty(facilityImage)
              ? images.filter((i) =>
                  i.file
                    ? i?.file?.meta === `${sportKey}`
                    : savedFacilityImages[sportKey]?.includes(i.id.uuid)
                )
              : savedFacilityImages
              ? images.filter((i) => savedFacilityImages[sportKey]?.includes(i.id.uuid))
              : []
          }
          thumbnailClassName={css.thumbnail}
          savedImageAltText={intl.formatMessage({
            id: 'EditListingPhotosForm.savedImageAltText',
          })}
          onRemoveImage={onRemoveImage}
        >
          {/* {logoImageCount.length === 0 ? ( */}
          <Field
            component={(props) => {
              const { input, meta } = props;
              return (
                <div className={css.imageRequiredWrapper}>
                  <input {...input} />
                  <ValidationError fieldMeta={meta} />
                </div>
              );
            }}
            name="images"
            type="hidden"
            // validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
          />
          <Field
            id={`${sportKey}`}
            name={`${sportKey}`}
            accept={ACCEPT_IMAGES}
            form={null}
            label={chooseImageText}
            type="file"
            disabled={imageUploadRequested}
          >
            {(fieldprops) => {
              const { accept, input, label, disabled: fieldDisabled } = fieldprops;
              const { name, type } = input;
              const onChange = (e) => {
                const file = e.target.files[0];
                imageTypeError && setImageTypeError(false);
                if (
                  file.type === 'image/jpeg' ||
                  file.type === 'image/jpg' ||
                  file.type === 'image/png'
                ) {
                  file.meta = `${sportKey}`;
                  form.change(`${sportKey}.image`, file);
                  form.blur(`${sportKey}`);
                  onImageUploadHandler(file, values, sportKey);
                } else {
                  setImageTypeError(true);
                }
              };
              const inputProps = { accept, id: name, name, onChange, type };
              return (
                // <div className={css.addImageWrapper}>
                //   <div className={css.aspectRatioWrapper}>
                //     {fieldDisabled ? null : <input {...inputProps} className={css.addImageInput} />}
                //     <label htmlFor={name} className={css.addImage}>
                //       {label}
                //     </label>
                //   </div>
                // </div>
                <div className={css.addImageWrapper}>
                  <div className={css.aspectRatioWrapper}>
                    {fieldDisabled ? null : <input {...inputProps} className={css.addImageInput} />}
                    <label htmlFor={name} className={css.adImg}>
                      <AddCircle />
                      <div className={css.addtxt}>Add new image</div>
                    </label>
                  </div>
                </div>
              );
            }}
          </Field>
          {/* ) : (
            ''
          )} */}

          {/* <Field
            id={`${sportKey}`}
            name={`${sportKey}`}
            accept={ACCEPT_IMAGES}
            form={null}
            label={chooseImageText}
            type="file"
            disabled={imageUploadRequested}
          >
            {(fieldprops) => {
              const { accept, input, label, disabled: fieldDisabled } = fieldprops;
              const { name, type } = input;
              const onChange = (e) => {
                const file = e.target.files[0];
                form.change(`addImage`, file);
                form.blur(`addImage`);
                onImageUploadHandler(file, values);
              };
              const inputProps = { accept, id: name, name, onChange, type };
              return (
                <div className={css.addImageWrapper}>
                  <div className={css.aspectRatioWrapper}>
                    {fieldDisabled ? null : <input {...inputProps} className={css.addImageInput} />}
                    <label htmlFor={name} className={css.adImg}>
                      <AddCircle />
                      <div className={css.addtxt}>Add new image</div>
                    </label>
                  </div>
                </div>
              );
            }}
          </Field> */}
        </AddImages>
        {imageTypeError ? (
          <p className={css.error}>Please select a valid image file (JPEG/PNG).</p>
        ) : null}

        {values[sportKey]?.images?.map((file) => (
          <span>
            <img src={file} height="200px" width="200px" />
          </span>
        ))}
      </div>

      <div>
        <FieldTextInput
          className={css.building}
          type="textarea"
          name={`${sportKey}.description`}
          id={`${sportKey}.description`}
          label={facilityDescriptionLabel}
          placeholder={facilityDescriptionPlaceholder}
          validate={facilityDescriptionRequired}
        />
      </div>

      <div>
        <div className={css.sectionhead}>
          <span>Aminities</span> <hr />
        </div>
        <label>{facilityAminitiesLabel}</label>
        <div className={css.aminitiesCont}>
          {aminities.map((option, idx) => {
            const fieldId = `${sportKey}.${option.key}`;
            return (
              <div key={fieldId} className={css.aminitiesfieldcheck}>
                {/* <span>{option.icon}</span> */}
                <FieldCheckbox
                  id={fieldId}
                  name={`${sportKey}.aminities`}
                  label={option.label}
                  value={option.key}
                  icon={option.icon}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <div className={css.sectionhead}>
          <span>Duration and Price</span> <hr />
        </div>
        <div>Full Facility duration and price</div>
        <FieldArray name={`fullFacilityDurationAndPrice.${sportKey}`}>
          {({ fields }) => {
            return (
              <div>
                {fields.map((name, index) => {
                  const durationValue = values.fullFacilityDurationAndPrice
                    ? values.fullFacilityDurationAndPrice[sportKey][0]
                    : undefined;

                  const isSessionBased = values?.durationType === 'session';
                  const isTimeBased = values?.durationType === 'time';

                  return (
                    <div key={`${name}`}>
                      <div key={`${name}`}>
                        <div className={css.formCardCont}>
                          <div className={css.crossbtnCont}>
                            {index > 0 && (
                              <span
                                onClick={() => {
                                  fields.remove(index);
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                <CrossRound className={css.crossIcon} />
                              </span>
                            )}
                          </div>

                          {isSessionBased && (
                            <FieldTextInput
                              id={`${name}.quantity`}
                              name={`${name}.quantity`}
                              label={facilityQunatityLabel}
                              placeholder={facilityQuantityPlaceholder}
                              className={css.formCardFieldsQuarter}
                              onChange={(e) => {
                                e.preventDefault();
                                const value = e.target.value;
                                const regex = /^[1-9]\d*$/;
                                if (value.match(regex) || value === '') {
                                  form.change(`${name}.quantity`, value);
                                }
                              }}
                            />
                          )}
                          {(isSessionBased || isTimeBased) && (
                            <FieldTextInput
                              id={`${name}.fullPrice`}
                              name={`${name}.fullPrice`}
                              label={facilityFullPriceLabel}
                              placeholder={facilityFullPricePlaceholder}
                              className={css.formCardFieldsQuarter}
                              onChange={(e) => {
                                e.preventDefault();
                                const value = e.target.value;
                                const regex = /^[1-9]\d*$/;
                                if (value.match(regex) || value === '') {
                                  form.change(`${name}.fullPrice`, value);
                                }
                              }}
                            />
                          )}
                          {(isSessionBased || isTimeBased) && (
                            <FieldTextInput
                              id={`${name}.deposit`}
                              name={`${name}.deposit`}
                              label={facilityDepositLabel}
                              placeholder={facilityDepositPlaceholder}
                              className={css.formCardFieldsQuarter}
                              onChange={(e) => {
                                e.preventDefault();
                                const value = e.target.value;
                                const regex = /^[1-9]\d*$/;
                                if (value.match(regex) || value === '') {
                                  form.change(`${name}.deposit`, value);
                                }
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                {isSessionBased && (
                  <InlineTextButton
                    type="button"
                    className={css.buttonAddNew}
                    onClick={() => {
                      fields.push({});
                    }}
                  >
                    <FormattedMessage id="EditListingAvailabilityPlanForm.addAnotherButton" />
                  </InlineTextButton>
                )}
              </div>
            );
          }}
        </FieldArray>
      </div>

      {/* <div>
        {facilityType.map((option, idx) => {
          const fieldId = `${sportKey}.${option.key}`;
          return (
            <div key={fieldId}>
              <FieldCheckbox
                id={fieldId}
                name={`${sportKey}.facilityType`}
                label={option.label}
                value={option.key}
              />
            </div>
          );
        })}
      </div> */}
      <div>
        <FieldCheckbox
          id={`${sportKey}.${halfFacility.key}`}
          name={`${sportKey}.facilityType`}
          label={halfFacility.label}
          value={halfFacility.key}
          onchange={(e) => {
            // donot change the props name its a custom one since child already have onChange we have to use it onchange all small.
            if (
              e.target.checked &&
              values.halfFacilityDurationAndPrice[`${sportKey}`].length === 0
            ) {
              form.change(`halfFacilityDurationAndPrice[${sportKey}]`, [{}]);
            }
          }}
        />
      </div>
      {values[sport?.key]?.facilityType?.includes('half') && (
        <FieldArray name={`halfFacilityDurationAndPrice.${sportKey}`}>
          {({ fields }) => {
            return (
              <div>
                {fields.map((name, index) => {
                  const durationValue = values.halfFacilityDurationAndPrice
                    ? values.fullFacilityDurationAndPrice[sportKey][0]
                    : undefined;

                  const isSessionBased = values?.durationType === 'session';
                  const isTimeBased = values?.durationType === 'time';

                  return (
                    <div key={`${name}`}>
                      <div key={`${name}`}>
                        <div className={css.formCardCont}>
                          <div className={css.crossbtnCont}>
                            {index > 0 && (
                              <span
                                onClick={() => {
                                  fields.remove(index);
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                <CrossRound className={css.crossIcon} />
                              </span>
                            )}
                          </div>
                          {/* <FieldSelect
                            id={`${name}.durationType`}
                            name={`${name}.durationType`}
                            label={facilityDurationTypeLabel}
                            // disabled={
                            //   durationValue?.durationType === 'session' && index > 0 ? true : false
                            // }
                            disabled
                            // initialValue={durationValue?.durationType}
                            validate={durationTypeRequired}
                            className={css.formCardFieldsQuarter}
                          >
                            <option disabled value="">
                              Select
                            </option>
                            {durationType.map((dt) => (
                              <option value={dt.key} key={`${sportKey}.${dt.key}`}>
                                {dt.label}
                              </option>
                            ))}
                          </FieldSelect>

                          {isSessionBased && (
                            <FieldTextInput
                              id={`${name}.durationName`}
                              name={`${name}.durationName`}
                              label={facilityDurationNameLabel}
                              placeholder={facilityDurationNamePlaceholder}
                              // disabled={
                              //   durationValue?.durationType === 'session' && index > 0
                              //     ? true
                              //     : false
                              // }
                              disabled
                              // initialValue={durationValue?.durationName}
                              validate={durationNameRequired}
                              className={css.formCardFieldsQuarter}
                            />
                          )}
                          {(isSessionBased || isTimeBased) && (
                            <FieldTextInput
                              id={`${name}.timeInterval`}
                              name={`${name}.timeInterval`}
                              label={facilityTimeIntervalabel}
                              placeholder={facilityTimeIntervalPlaceholder}
                              // initialValue={durationValue?.timeInterval}
                              // disabled={
                              //   durationValue?.durationType === 'session' && index > 0
                              //     ? true
                              //     : false
                              // }
                              disabled
                              validate={validators.composeValidators(
                                timeIntervalRequired,
                                numberRequired(
                                  values.halfFacilityDurationAndPrice[sportKey][index]?.timeInterval
                                )
                              )}
                              className={css.formCardFieldsQuarter}
                            />
                          )} */}
                          {isSessionBased && (
                            <FieldTextInput
                              id={`${name}.quantity`}
                              name={`${name}.quantity`}
                              label={facilityQunatityLabel}
                              placeholder={facilityQuantityPlaceholder}
                              className={css.formCardFieldsQuarter}
                              onChange={(e) => {
                                e.preventDefault();
                                const value = e.target.value;
                                const regex = /^[1-9]\d*$/;
                                if (value.match(regex) || value === '') {
                                  form.change(`${name}.quantity`, value);
                                }
                              }}
                            />
                          )}
                          {(isSessionBased || isTimeBased) && (
                            <FieldTextInput
                              id={`${name}.fullPrice`}
                              name={`${name}.fullPrice`}
                              label={facilityFullPriceLabel}
                              placeholder={facilityFullPricePlaceholder}
                              className={css.formCardFieldsQuarter}
                              onChange={(e) => {
                                e.preventDefault();
                                const value = e.target.value;
                                const regex = /^[1-9]\d*$/;
                                if (value.match(regex) || value === '') {
                                  form.change(`${name}.fullPrice`, value);
                                }
                              }}
                            />
                          )}
                          {(isSessionBased || isTimeBased) && (
                            <FieldTextInput
                              id={`${name}.deposit`}
                              name={`${name}.deposit`}
                              label={facilityDepositLabel}
                              placeholder={facilityDepositPlaceholder}
                              className={css.formCardFieldsQuarter}
                              onChange={(e) => {
                                e.preventDefault();
                                const value = e.target.value;
                                const regex = /^[1-9]\d*$/;
                                if (value.match(regex) || value === '') {
                                  form.change(`${name}.deposit`, value);
                                }
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                {isSessionBased && (
                  <InlineTextButton
                    type="button"
                    className={css.buttonAddNew}
                    onClick={() => {
                      fields.push({});
                    }}
                  >
                    <FormattedMessage id="EditListingAvailabilityPlanForm.addAnotherButton" />
                  </InlineTextButton>
                )}
              </div>
            );
          }}
        </FieldArray>
      )}

      <div>
        <FieldCheckbox
          id={`${sportKey}.${quaterFacility.key}`}
          name={`${sportKey}.facilityType`}
          label={quaterFacility.label}
          value={quaterFacility?.key}
          onchange={(e) => {
            // donot change the props name its a custom one since child already have onChange we have to use it onchange all small.
            if (
              e.target.checked &&
              values.quaterFacilityDurationAndPrice[`${sportKey}`].length === 0
            ) {
              form.change(`quaterFacilityDurationAndPrice[${sportKey}]`, [{}]);
            }
          }}
        />
      </div>

      {sport && values[sport.key]?.facilityType?.includes('quater') && (
        <FieldArray name={`quaterFacilityDurationAndPrice.${sportKey}`}>
          {({ fields }) => {
            return (
              <div>
                {fields.map((name, index) => {
                  const durationValue = values.quaterFacilityDurationAndPrice
                    ? values.fullFacilityDurationAndPrice[sportKey][0]
                    : undefined;

                  const isSessionBased = values?.durationType === 'session';
                  const isTimeBased = values?.durationType === 'time';

                  return (
                    <div>
                      <div key={`${name}`}>
                        <div className={css.formCardCont}>
                          <div className={css.crossbtnCont}>
                            {index > 0 && (
                              <span
                                onClick={() => {
                                  fields.remove(index);
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                <CrossRound className={css.crossIcon} />
                              </span>
                            )}
                          </div>
                          {/* <FieldSelect
                            id={`${name}.durationType`}
                            name={`${name}.durationType`}
                            label={facilityDurationTypeLabel}
                            // disabled={
                            //   durationValue?.durationType === 'session' && index > 0 ? true : false
                            // }
                            // disabled
                            // initialValue={durationValue?.durationType}
                            validate={durationTypeRequired}
                            className={css.formCardFieldsQuarter}
                          >
                            <option disabled value="">
                              Select
                            </option>
                            {durationType.map((dt) => (
                              <option value={dt.key} key={`${sportKey}.${dt.key}`}>
                                {dt.label}
                              </option>
                            ))}
                          </FieldSelect>

                          {isSessionBased && (
                            <FieldTextInput
                              id={`${name}.durationName`}
                              name={`${name}.durationName`}
                              label={facilityDurationNameLabel}
                              placeholder={facilityDurationNamePlaceholder}
                              // disabled={
                              //   durationValue?.durationType === 'session' && index > 0
                              //     ? true
                              //     : false
                              // }
                              // disabled
                              // initialValue={durationValue?.durationName}
                              validate={durationNameRequired}
                              className={css.formCardFieldsQuarter}
                            />
                          )}
                          {(isSessionBased || isTimeBased) && (
                            <FieldTextInput
                              id={`${name}.timeInterval`}
                              name={`${name}.timeInterval`}
                              label={facilityTimeIntervalabel}
                              placeholder={facilityTimeIntervalPlaceholder}
                              // initialValue={durationValue?.timeInterval}
                              // disabled={
                              //   durationValue?.durationType === 'session' && index > 0
                              //     ? true
                              //     : false
                              // }
                              // disabled
                              validate={validators.composeValidators(
                                timeIntervalRequired,
                                numberRequired(
                                  values.quaterFacilityDurationAndPrice[sportKey][index]
                                    ?.timeInterval
                                )
                              )}
                              className={css.formCardFieldsQuarter}
                            />
                          )} */}
                          {isSessionBased && (
                            <FieldTextInput
                              id={`${name}.quantity`}
                              name={`${name}.quantity`}
                              label={facilityQunatityLabel}
                              placeholder={facilityQuantityPlaceholder}
                              className={css.formCardFieldsQuarter}
                              onChange={(e) => {
                                e.preventDefault();
                                const value = e.target.value;
                                const regex = /^[1-9]\d*$/;
                                if (value.match(regex) || value === '') {
                                  form.change(`${name}.quantity`, value);
                                }
                              }}
                            />
                          )}
                          {(isSessionBased || isTimeBased) && (
                            <FieldTextInput
                              id={`${name}.fullPrice`}
                              name={`${name}.fullPrice`}
                              label={facilityFullPriceLabel}
                              placeholder={facilityFullPricePlaceholder}
                              className={css.formCardFieldsQuarter}
                              onChange={(e) => {
                                e.preventDefault();
                                const value = e.target.value;
                                const regex = /^[1-9]\d*$/;
                                if (value.match(regex) || value === '') {
                                  form.change(`${name}.fullPrice`, value);
                                  form.change(`${sportKey}.offPeakTimingPrice`, value);
                                }
                              }}
                            />
                          )}
                          {(isSessionBased || isTimeBased) && (
                            <FieldTextInput
                              id={`${name}.deposit`}
                              name={`${name}.deposit`}
                              label={facilityDepositLabel}
                              placeholder={facilityDepositPlaceholder}
                              className={css.formCardFieldsQuarter}
                              onChange={(e) => {
                                e.preventDefault();
                                const value = e.target.value;
                                const regex = /^[1-9]\d*$/;
                                if (value.match(regex) || value === '') {
                                  form.change(`${name}.deposit`, value);
                                }
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                {isSessionBased && (
                  <InlineTextButton
                    type="button"
                    className={css.buttonAddNew}
                    onClick={() => {
                      fields.push({});
                    }}
                  >
                    <FormattedMessage id="EditListingAvailabilityPlanForm.addAnotherButton" />
                  </InlineTextButton>
                )}
              </div>
            );
          }}
        </FieldArray>
      )}

      <div className={css.formCardContBorderless}>
        {/* <FieldTextInput
          className={css.formCardFieldsQuarter}
          name={`${sportKey}.priceForAdult`}
          id={`${sportKey}.priceForAdult`}
          label={facilityPriceForAdultLabel}
          placeholder={facilityPriceForAdultPlaceholder}
          validate={validators.composeValidators(
            priceForAdultRequired,
            numberRequired(values[sportKey]?.priceForAdult)
          )}
        /> */}

        <FieldTextInput
          className={css.formCardFieldsQuarter}
          name={`${sportKey}.priceForChildren`}
          id={`${sportKey}.priceForChildren`}
          label={facilitypriceForChildrenLabel}
          placeholder={facilitypriceForChildrenPlaceholder}
          // validate={validators.composeValidators(
          // priceForChildRequired,
          // numberRequired(values[sportKey]?.priceForChildren)
          // )}
          onChange={(e) => {
            e.preventDefault();
            const value = e.target.value;
            const regex = /^[1-9]\d*$/;
            if (value.match(regex) || value === '') {
              form.change(`${sportKey}.priceForChildren`, value);
            }
          }}
        />

        <FieldTextInput
          className={css.formCardFieldsQuarter}
          name={`${sportKey}.priceForInfants`}
          id={`${sportKey}.priceForInfants`}
          label={facilityPriceForInfantsLabel}
          placeholder={facilityPriceForInfantsPlaceholder}
          // validate={validators.composeValidators(
          // priceForInfantsRequired,
          // numberRequired(values[sportKey]?.priceForInfants)
          // )}
          onChange={(e) => {
            e.preventDefault();
            const value = e.target.value;
            const regex = /^[1-9]\d*$/;
            if (value.match(regex) || value === '') {
              form.change(`${sportKey}.priceForInfants`, value);
            }
          }}
        />

        <FieldTextInput
          className={css.formCardFieldsHalf}
          name={`${sportKey}.peakTimingPrice`}
          id={`${sportKey}.peakTimingPrice`}
          label={facilityPeakTimingPriceLabel}
          placeholder={facilityPeakTimingPricePlaceholder}
          validate={validators
            .composeValidators
            // priceForPeakTimeRequired,
            // numberRequired(values[sportKey]?.peakTimingPrice)
            ()}
          onChange={(e) => {
            e.preventDefault();
            const value = e.target.value;
            const regex = /^[1-9]\d*$/;
            if (value.match(regex) || value === '') {
              form.change(`${sportKey}.peakTimingPrice`, value);
            }
          }}
        />
        <FieldTextInput
          className={classNames(css.formCardFieldsHalf, { [css.offpeakdisabled]: true })}
          name={`${sportKey}.offPeakTimingPrice`}
          id={`${sportKey}.offPeakTimingPrice`}
          label={facilityOffPeakTimingPriceLabel}
          placeholder={facilityOffPeakTimingPricePlaceholder}
          input={{ value: values[sportKey]?.fullFacilityDurationAndPrice?.[0].fullPrice }}
          validate={validators
            .composeValidators
            // priceForOffPeakTimeRequired,
            // numberRequired(values[sportKey]?.offPeakTimingPrice)
            ()}
          disabled={true}
          onChange={(e) => {
            // e.preventDefault();
            // const value = e.target.value;
            // const regex = /^[1-9]\d*$/;
            // if (value.match(regex) || value === '') {
            //   form.change(`${sportKey}.offPeakTimingPrice`, value);
            // }
          }}
        />
      </div>

      <div>
        <div className={css.sectionhead}>
          <span>Rental Extra Equipment</span> <hr />
        </div>
        <FieldArray name={`rentalExtraEquipment.${sportKey}`}>
          {({ fields }) => {
            return (
              <div>
                {fields.map((name, index) => {
                  return (
                    <div key={index}>
                      <div key={`${name}`}>
                        <div className={css.formCardCont}>
                          {index >= 0 && (
                            <div className={css.crossbtnCont}>
                              <span
                                onClick={() => {
                                  fields.remove(index);
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                <CrossRound className={css.crossIcon} />
                              </span>
                            </div>
                          )}
                          <FieldTextInput
                            id={`${name}.equipmentName`}
                            name={`${name}.equipmentName`}
                            label={facilityEquipmentNameLabel}
                            placeholder={facilityEquipmentNamePlaceholder}
                            // validate={equipmentNameRequired}
                            className={css.formCardFieldsFull}
                          />

                          <FieldTextInput
                            id={`${name}.duration`}
                            name={`${name}.duration`}
                            label={facilityEquipmentDurationLabel}
                            placeholder={facilityEquipmentDurationPlaceholder}
                            // validate={validators.composeValidators(
                            //   equipmentDurationRequired,
                            //   numberRequired(values.rentalExtraEquipment[sportKey][index]?.duration)
                            // )}
                            className={css.formCardFieldsQuarter}
                            onChange={(e) => {
                              e.preventDefault();
                              const value = e.target.value;
                              const regex = /^[1-9]\d*$/;
                              if (value.match(regex) || value === '') {
                                form.change(`${name}.duration`, value);
                              }
                            }}
                          />
                          <FieldTextInput
                            id={`${name}.chargeAmount`}
                            name={`${name}.chargeAmount`}
                            label={facilityEquipmentChargeAmountLabel}
                            placeholder={facilityEquipmentChargeAmountPlaceholder}
                            className={css.formCardFieldsQuarter}
                            onChange={(e) => {
                              e.preventDefault();
                              const value = e.target.value;
                              const regex = /^[1-9]\d*$/;
                              if (value.match(regex) || value === '') {
                                form.change(`${name}.chargeAmount`, value);
                              }
                            }}
                          />
                          <FieldTextInput
                            id={`${name}.maxQuantity`}
                            name={`${name}.maxQuantity`}
                            label={facilityEquipmentMaxAmountLabel}
                            placeholder={facilityEquipmentMaxAmountPlaceholder}
                            className={css.formCardFieldsQuarter}
                            onChange={(e) => {
                              e.preventDefault();
                              const value = e.target.value;
                              const regex = /^[1-9]\d*$/;
                              if (value.match(regex) || value === '') {
                                form.change(`${name}.maxQuantity`, value);
                              }
                            }}
                          />
                          <FieldCheckbox
                            id={`${name}.multiplyCost`}
                            name={`${name}.multiplyCost`}
                            label={facilityMultiplyChargesByAttendeesLabel}
                            // value={option.key}
                            defaultValue={false}
                            className={css.formCardFieldsFull}
                            onChange={(e) => {
                              e.preventDefault();
                              const value = e.target.value;
                              const regex = /^[1-9]\d*$/;
                              if (value.match(regex) || value === '') {
                                form.change(`${name}.multiplyCost`, value);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <InlineTextButton
                  type="button"
                  className={css.buttonAddNew}
                  disabled={
                    values?.rentalExtraEquipment &&
                    values?.rentalExtraEquipment?.[sportKey]?.length > 0 &&
                    (!values?.rentalExtraEquipment[sportKey][
                      values?.rentalExtraEquipment[sportKey]?.length - 1
                    ]?.equipmentName ||
                      !values?.rentalExtraEquipment[sportKey][
                        values?.rentalExtraEquipment[sportKey]?.length - 1
                      ]?.maxQuantity)
                      ? true
                      : false
                  }
                  onClick={() => {
                    fields.push({});
                  }}
                >
                  <FormattedMessage id="EditListingAvailabilityPlanForm.addAnother" />
                </InlineTextButton>
              </div>
            );
          }}
        </FieldArray>
      </div>

      <div>
        <div className={css.sectionhead}>
          <span>Group Booking</span> <hr />
        </div>
        <div className={css.formCardContBorderless}>
          <FieldTextInput
            name={`${sportKey}.minCapacity`}
            id={`${sportKey}.minCapacity`}
            label={facilityMinCapacityLabel}
            placeholder={facilityMinCapacityPlaceholder}
            validate={validators.composeValidators(
              minimumCapacityRequired,
              checkMin(values.minCapacity, values.maxCapacity),
              numberRequired(values.minCapacity)
            )}
            onChange={(e) => {
              e.preventDefault();
              form.change(`${sportKey}.maxCapacity`, '');
              const value = e.target.value;
              const regex = /^[1-9]\d*$/;
              if (value.match(regex) || value === '') {
                form.change(`${sportKey}.minCapacity`, value);
              }
            }}
            className={css.formCardFieldsHalf}
          />
          <FieldTextInput
            name={`${sportKey}.maxCapacity`}
            id={`${sportKey}.maxCapacity`}
            label={facilityMaxCapacityLabel}
            placeholder={facilityMaxCapacityPlaceholder}
            disabled={values[sportKey]?.minCapacity > 0 ? false : true}
            validate={validators.composeValidators(
              checkLen(values[sportKey]?.maxCapacity, values[sportKey]?.minCapacity),
              numberRequired(values.maxCapacity)
            )}
            className={css.formCardFieldsHalf}
            onChange={(e) => {
              e.preventDefault();
              const value = e.target.value;
              const regex = /^[1-9]\d*$/;
              if (value.match(regex) || value === '') {
                form.change(`${sportKey}.maxCapacity`, value);
              }
            }}
          />
        </div>

        <div>
          {groupBookingCheck.map((option) => {
            const fieldId = `${sportKey}.${option.key}`;
            return (
              <div key={fieldId}>
                <FieldCheckbox
                  id={fieldId}
                  name={`${sportKey}.groupBookingOptions`}
                  label={option.label}
                  value={option.key}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <div className={css.sectionhead}>
          <span>Extra Information</span> <hr />
        </div>
        <div>
          <label>{rulesLabel}</label>
          <Suspense fallback={<div>Loading...</div>}>
            <RichEditor
              name={`${sportKey}.rules`}
              placeholder={rulesPlaceholder}
              editorText={editorText}
              setEditorText={setEditorText}
              setEditorContent={setEditorContent}
              editorContent={editorContent}
            />
          </Suspense>
        </div>
        <div>
          <label>{whatToBringLabel}</label>
          <Suspense fallback={<div>Loading...</div>}>
            <RichEditor
              name={`${sportKey}.whatToBring`}
              placeholder={whatToBringPlaceholder}
              editorText={editorText}
              setEditorText={setEditorText}
              setEditorContent={setEditorContent}
              editorContent={editorContent}
            />
          </Suspense>
        </div>
        <div>
          <label>{whatIsProvidedLabel}</label>
          <Suspense fallback={<div>Loading...</div>}>
            <RichEditor
              name={`${sportKey}.whatIsProvided`}
              placeholder={whatIsProvidedPlaceholder}
              editorText={editorText}
              setEditorText={setEditorText}
              setEditorContent={setEditorContent}
              editorContent={editorContent}
            />
          </Suspense>
        </div>
        <div>
          <label>{thingsToKnowLabel}</label>
          <Suspense fallback={<div>Loading...</div>}>
            <RichEditor
              name={`${sportKey}.thingsToKnow`}
              placeholder={thingsToKnowPlaceholder}
              editorText={editorText}
              setEditorText={setEditorText}
              setEditorContent={setEditorContent}
              editorContent={editorContent}
            />
          </Suspense>
        </div>
        <div>
          <label>{whatYouDoLabel}</label>
          <Suspense fallback={<div>Loading...</div>}>
            <RichEditor
              name={`${sportKey}.whatYouDo`}
              placeholder={whatYouDoPlaceholder}
              editorText={editorText}
              setEditorText={setEditorText}
              setEditorContent={setEditorContent}
              editorContent={editorContent}
              form={form}
            />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

const submit = (handleSubmit, editorContent, sportsData) => (values) => {
  Object.keys(editorContent).forEach((k) => {
    const [sport, editorKey] = k.split('.');
    values[sport] = {
      ...values[sport],
      editorData: { ...values[sport]['editorData'], [editorKey]: editorContent[k] },
    };
  });

  Object.keys(values?.fullFacilityDurationAndPrice || {}).forEach((k) => {
    if (values[k]) values[k].fullFacilityDurationAndPrice = values.fullFacilityDurationAndPrice[k];
  });

  Object.keys(values?.halfFacilityDurationAndPrice || {}).forEach((k) => {
    if (values[k] && values[k].facilityType?.includes('half'))
      values[k].halfFacilityDurationAndPrice = values.halfFacilityDurationAndPrice[k];
  });

  Object.keys(values?.quaterFacilityDurationAndPrice || {}).forEach((k) => {
    if (values[k] && values[k].facilityType?.includes('quater'))
      values[k].quaterFacilityDurationAndPrice = values.quaterFacilityDurationAndPrice[k];
  });

  // Object.keys(values).filter((v) => sportsData.map((s) => s.key).includes(v)).length === 1 &&
  !values?.rentalExtraEquipment
    ? (values[
        Object.keys(values).find((v) => sportsData.map((s) => s.key).includes(v))
      ].rentalExtraEquipment = [])
    : Object.keys(values)
        .filter((v) => sportsData.map((s) => s.key).includes(v))
        .forEach((k) => {
          if (values[k])
            values[k].rentalExtraEquipment = values.rentalExtraEquipment[k]
              ? values.rentalExtraEquipment[k]
              : [];
        });

  const finalValues = Object.keys(values)
    .filter((v) => sportsData.map((s) => s.key).includes(v))
    .map((vk) => {
      return { sportName: vk, ...values[vk], images: values?.images };
    });
  handleSubmit({
    durationName: values.durationName,
    durationType: values.durationType,
    timeInterval: values.timeInterval,
    finalValues,
  });
};

const ListingSportsInfoFormComponent = (props) => {
  const { onSubmit, listing, listingId, history, listingFormatedData } = props;

  const initialEditorData = {};
  listingFormatedData?.forEach((facility) => {
    const key = Object.keys(facility)[0];

    for (let subKey in facility[key]?.editorData) {
      initialEditorData[`${key}.${subKey}`] = facility[key].editorData[subKey];
    }
  });

  // {
  //   'cricket.thingsToKnow': convertFromHTML('<p>gfdgfxddfdsx</p>\n'),
  //   'cricket.whatYouDo': convertFromHTML('<p>kughugjfg333</p>\n'),
  // }

  const initialEditorDataKeys = Object.keys(initialEditorData);

  const [editorText, setEditorText] = useState({});
  const [editorContent, setEditorContent] = useState({});
  const [expanded, setExpanded] = useState('0');
  const [sportsData, setSportsData] = useState([]);
  const [sportsDataStatus, setSportsDataStatus] = useState(false);
  const [expandNew, setExpandNew] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const [sports, setSports] = useState(
    sportsData.filter(({ key }) =>
      props.initialValues.sports
        ?.map((element) => {
          return Object.keys(element)[0];
        })
        .includes(key)
    )
  );
  useEffect(() => {
    setSportsDataStatus(true);
    axios.get(`${apiBaseUrl()}/api/category`).then((resp) => {
      setSportsDataStatus(false);
      if (resp.data) {
        setSportsData(
          resp.data.map(({ name, subCat, key }) => ({
            key: key,
            label: name,
            subCategories: subCat.map(({ name, key }) => ({ name, key })),
          }))
        );
      }
    });
  }, []);
  useEffect(() => {
    let finalInitialEditorData = {};
    initialEditorDataKeys.forEach(
      (key) =>
        (finalInitialEditorData = {
          ...finalInitialEditorData,
          [key]: EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(initialEditorData[key]))
          ),
        })
    );
    setEditorText(finalInitialEditorData);
  }, [props.initialValues]);

  useEffect(() => {
    setSports(
      sportsData.filter(({ key }) =>
        props.initialValues.sports
          ?.map((element) => {
            return Object.keys(element)[0];
          })
          .includes(key)
      )
    );
  }, [props.initialValues]);
  // useEffect(()=>{
  //   if(sports?.length > 1){
  //     setExpanded(false)
  //   }else{
  //     setExpanded('0')
  //   }
  // },[sports])
  // );
  return (
    <FinalForm
      {...props}
      // onSubmit={props.onSubmit}
      onSubmit={submit(onSubmit, editorContent, sportsData)}
      mutators={{
        // potentially other mutators could be merged here
        ...arrayMutators,
      }}
      // validate={validate}
      render={(fieldRenderProps) => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          // listingTitle,
          // weekdays,
          // fetchErrors,
          values,
          form,
          initialValues,
          ...rest
        } = fieldRenderProps;
        useEffect(() => {
          if (expandNew) {
            setExpanded(`${values?.sports?.length - 1}` || false);
            setExpandNew(false);
          }
        }, [expandNew]);
        const [imageUploadRequested, setImageUploadRequested] = useState(false);
        const [currentSport, setCurrentSport] = useState(sports[0]);

        useEffect(() => {
          form.change('images', rest.images);
        }, [rest.images]);
        useEffect(() => {
          if (currentSport === '') {
            const element = document.getElementById(`${sports?.[sports?.length - 1]?.key}-div`);
            const rect = element.getBoundingClientRect();
            window.scrollTo(rect?.x, rect?.y);
            // window.scrollTo({ top: rect?.x, left: rect?.y, behavior: 'smooth' });
            setExpandNew(true);
          }
        }, [currentSport]);

        const handleChange = (panel) => (event, newExpanded) => {
          setExpanded(newExpanded ? panel : false);
        };
        const facilitySportsInfoLabel = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilitySportsInfoLabel',
        });

        const facilitySportsInfoPlaceholder = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilitySportsInfoPlaceholder',
        });

        //duration and price

        const facilityDurationTypeLabel = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilityDurationTypeLabel',
        });

        const durationTypeRequiredMessage = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.durationTypeRequiredMessage',
        });

        const facilityDurationNameLabel = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilityDurationNameLabel',
        });

        const facilityDurationNamePlaceholder = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilityDurationNamePlaceholder',
        });

        const durationNameRequiredMessage = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.durationNameRequiredMessage',
        });

        const facilityTimeIntervalabel = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilityTimeIntervalabel',
        });

        const facilityTimeIntervalPlaceholder = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.facilityTimeIntervalPlaceholder',
        });

        const timeIntervalRequiredMessage = intl.formatMessage({
          id: 'SuperAdminDashboardNewListing.timeIntervalRequiredMessage',
        });

        const durationTypeRequired = validators.required(durationTypeRequiredMessage);
        const durationNameRequired = validators.required(durationNameRequiredMessage);
        const timeIntervalRequired = validators.required(timeIntervalRequiredMessage);
        const timeInterValValid = (val) =>
          validators.validTimeInterval('Invalid time interval', val);

        // const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress || !sports.length;

        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setSubmitInProgress(true);
              handleSubmit(e);
            }}
          >
            {/* <Accordion defaultActiveKey="0" flush> */}
            <FieldArray name="sports">
              {({ fields }) => {
                return (
                  <div>
                    <div>
                      <FieldSelect
                        id={`durationType`}
                        name={`durationType`}
                        label={facilityDurationTypeLabel}
                        // initialValue={durationValue?.durationType}
                        validate={durationTypeRequired}
                        className={css.formCardFieldsQuarter}
                      >
                        <option disabled value="">
                          Select
                        </option>
                        {durationType.map((dt) => (
                          <option value={dt.key} key={`${dt.key}`}>
                            {dt.label}
                          </option>
                        ))}
                      </FieldSelect>

                      {values.durationType === 'session' && (
                        <FieldTextInput
                          id={`durationName`}
                          name={`durationName`}
                          label={facilityDurationNameLabel}
                          placeholder={facilityDurationNamePlaceholder}
                          // initialValue={durationValue?.durationName}
                          validate={durationNameRequired}
                          className={css.formCardFieldsQuarter}
                        />
                      )}
                      {/* {(isSessionBased || isTimeBased) && ( */}
                      <FieldTextInput
                        id={`timeInterval`}
                        name={`timeInterval`}
                        label={facilityTimeIntervalabel}
                        placeholder={facilityTimeIntervalPlaceholder}
                        // initialValue={durationValue?.timeInterval}
                        validate={validators.composeValidators(
                          timeIntervalRequired,
                          // numberRequired(values.fullFacilityDurationAndPrice[sportKey][index]?.timeInterval)
                          timeInterValValid(values.timeInterval)
                        )}
                        className={css.formCardFieldsQuarter}
                        onChange={(e) => {
                          e.preventDefault();
                          const value = e.target.value;
                          const regex = /^[1-9]\d*$/;
                          if (value.match(regex) || value === '') {
                            form.change('timeInterval', value);
                          }
                        }}
                        // onChange={(e) => {
                        //   if (/^[0-9]*[05]$/.test(e.target.value)) {
                        //     form.change('timeInterval', e.target.value);
                        //   }
                        // }}
                        // validate={validators.composeValidators(
                        //   // timeIntervalRequired,
                        //   validators.validTimeInterval('Invalid time interval')
                        // )}
                      />
                      {/* )} */}
                    </div>
                    {sportsDataStatus ? (
                      <IconSpinner />
                    ) : (
                      sports?.length > 0 &&
                      fields.map((name, index) => {
                        const sport = sports[name.split('[')[1][0]];
                        return (
                          <div id={`${sport?.key}-div`} key={`${sport?.key}`}>
                            <Accordion
                              expanded={expanded === `${index}`}
                              onChange={handleChange(`${index}`)}
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                expandIcon={<AccordionIcon />}
                                id={`${sport?.key}_header`}
                                key={`${sport?.key}`}
                              >
                                <span>{sport?.key}</span>
                                <button
                                  className={css.remcat}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    fields.remove(index);
                                    form.change(sport?.key, undefined);
                                    setSports(sports.filter((f) => f.key !== sport?.key));
                                  }}
                                >
                                  <AiOutlineClose /> remove
                                </button>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div>
                                  <FormContent
                                    intl={intl}
                                    sport={sport}
                                    values={values}
                                    editorText={editorText}
                                    setEditorText={setEditorText}
                                    setEditorContent={setEditorContent}
                                    editorContent={editorContent}
                                    form={form}
                                    imageUploadRequested={imageUploadRequested}
                                    setImageUploadRequested={setImageUploadRequested}
                                    sportsData={sportsData}
                                    {...rest}
                                  />
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        );
                      })
                    )}

                    <div>
                      {!sports.length && <label>{facilitySportsInfoLabel}</label>}

                      <div className={css.chooseSelectCategory}>
                        <Select
                          name="facilityHireSportsInfo"
                          onChange={(selectedOption) => setCurrentSport(selectedOption)}
                          options={sportsData.filter(
                            (sd) => !sports.map((s) => s.key).includes(sd.key)
                          )}
                          value={currentSport || ''}
                          placeholder={facilitySportsInfoPlaceholder}
                          components={{ Control }}
                        />
                      </div>

                      <button
                        type="submit"
                        disabled={!currentSport}
                        className={css.buttonAddNew}
                        onClick={(e) => {
                          e.preventDefault();
                          setSports([...sports, currentSport]);
                          // form.change('sports', [...values.sports, currentSport]);

                          values?.fullFacilityDurationAndPrice
                            ? (values.fullFacilityDurationAndPrice = {
                                ...values.fullFacilityDurationAndPrice,
                                [currentSport.key]: [{}],
                              })
                            : (values.fullFacilityDurationAndPrice = {
                                [currentSport.key]: [{}],
                              });

                          values?.halfFacilityDurationAndPrice
                            ? (values.halfFacilityDurationAndPrice = {
                                ...values.halfFacilityDurationAndPrice,
                                [currentSport.key]: [{}],
                              })
                            : (values.halfFacilityDurationAndPrice = {
                                [currentSport.key]: [{}],
                              });

                          values?.quaterFacilityDurationAndPrice
                            ? (values.quaterFacilityDurationAndPrice = {
                                ...values.quaterFacilityDurationAndPrice,
                                [currentSport.key]: [{}],
                              })
                            : (values.quaterFacilityDurationAndPrice = {
                                [currentSport.key]: [{}],
                              });

                          values.rentalExtraEquipment
                            ? (values.rentalExtraEquipment = {
                                ...values.rentalExtraEquipment,
                                [currentSport.key]: [{}],
                              })
                            : (values.rentalExtraEquipment = {
                                [currentSport.key]: [{}],
                              });
                          fields.push({ [currentSport.key]: {} });

                          setCurrentSport('');
                        }}
                      >
                        + Add this category
                      </button>
                    </div>
                  </div>
                );
              }}
            </FieldArray>
            {/* <MyRichTextEditor /> */}
            {/* </Accordion> */}
            <div className={css.formsbmitcnclbtn}>
              <Button
                type="button"
                onClick={() => {
                  history.goBack();
                }}
                className={css.backbtn}
              >
                Back
              </Button>
              <Button
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                className={css.sbmtbtn}
              >
                Save & Next
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};

const ListingSportsInfoForm = compose(withRouter, injectIntl)(ListingSportsInfoFormComponent);
export default ListingSportsInfoForm;

{
  /* <AddImages
          className={css.imagesField}
          images={images}
          thumbnailClassName={css.thumbnail}
          savedImageAltText={intl.formatMessage({
            id: 'EditListingPhotosForm.savedImageAltText',
          })}
          onRemoveImage={onRemoveImage}
        >
          <Field
            component={(props) => {
              const { input, meta } = props;
              return (
                <div className={css.imageRequiredWrapper}>
                  <input {...input} />
                  <ValidationError fieldMeta={meta} />
                </div>
              );
            }}
            name="images"
            type="hidden"
            // validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
          />
          <Field
            id="addImage"
            name="addImage"
            accept={ACCEPT_IMAGES}
            form={null}
            label={chooseImageText}
            type="file"
            disabled={imageUploadRequested}
          >
            {(fieldprops) => {
              const { accept, input, label, disabled: fieldDisabled } = fieldprops;
              const { name, type } = input;
              const onChange = (e) => {
                const file = e.target.files[0];
                form.change(`addImage`, file);
                form.blur(`addImage`);
                onImageUploadHandler(file, values);
              };
              const inputProps = { accept, id: name, name, onChange, type };
              return (
                <div className={css.addImageWrapper}>
                  <div className={css.aspectRatioWrapper}>
                    {fieldDisabled ? null : <input {...inputProps} className={css.addImageInput} />}
                    <label htmlFor={name} className={css.adImg}>
                      <AddCircle />
                      <div className={css.addtxt}>Add new image</div>
                    </label>
                  </div>
                </div>
              );
            }}
          </Field>
        </AddImages> */
}
