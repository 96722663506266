import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { ListingScheduleForm } from '../../forms';
import { apiBaseUrl } from '../../util/api';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import css from './ListingSchedulePanel.module.css';

const ListingSchedulePanelComponent = (props) => {
  const routes = routeConfiguration();
  // const listingId = parse(history.location.search)?.listing || '';

  const { history, listingId, listing, actionType } = props;

  if (!listingId)
    history.replace(
      createResourceLocatorString(
        'SuperAdminDashboard',
        routes,
        { tab: 'listings' },
        { actionType: 'new', lTab: 'generalInfo' }
      )
    );

  const listingSchedule = (listing && { ...listing?.schedule }) || {};

  const initialSchedule = Object.values(listingSchedule).reduce((acc, cur) => {
    const { day, times } = cur;
    acc[day] = times.map(({ startTime, endTime, isPeak }) => ({
      startTime,
      endTime,
      [`-isPeak`]: isPeak ? 'peak' : 'offPeak',
    }));
    return acc;
  }, {});
  const availableDays = Object.values(listingSchedule)
    .filter(({ isActive }) => isActive)
    .map(({ day }) => day);

  const timeInterval = listing?.listingDetails?.timeInterval || 0;

  return (
    <div className={css.mainContainer}>
      <ListingScheduleForm
        onSubmit={() => {}}
        listingId={listingId}
        initialValues={{ ...initialSchedule, availableDays }}
        availableDays={availableDays}
        duration={Number(timeInterval)}
        listing={listing}
        actionType={actionType}
      />
    </div>
  );
};

const ListingSchedulePanel = compose(injectIntl, withRouter)(ListingSchedulePanelComponent);

export default ListingSchedulePanel;
