import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';
import { ReactComponent as Phone } from '../../assets/icons/phone.svg';
import { ReactComponent as Location } from '../../assets/icons/location.svg';
import { ReactComponent as Mail } from '../../assets/icons/mail.svg';

import { FiFacebook, FiTwitter } from 'react-icons/fi';
import { AiOutlineInstagram, AiOutlineYoutube } from 'react-icons/ai';
import { PiTiktokLogo } from 'react-icons/pi';
import footerlogo from '../../assets/footerlogo.svg';
const renderSocialMediaLinks = (intl) => {
  const { siteFacebookPage, siteInstagramPage, siteTiktokPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter((v) => v != null);
};

const Footer = (props) => {
  const { rootClassName, className, intl } = props;
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle, siteTiktokPage } = config;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          {/* <div className={css.someLiksMobile}>{socialMediaLinks}</div> */}
          <div className={css.links}>
            {/* <div className={css.organization} id="organization"> */}
            {/* <NamedLink name="LandingPage" className={css.logoLink}>
                <span className={css.logo}>
                  <Logo format="desktop" />
                </span>
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p>
                <p className={css.organizationCopyright}>
                  <NamedLink name="LandingPage" className={css.copyrightLink}>
                    <FormattedMessage id="Footer.copyright" />
                  </NamedLink>
                </p>
              </div> */}
            {/* <h2>Customer service</h2>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <a className={css.link} href="">
                    How it works?
                  </a>
                </li>
                <li className={css.listItem}>
                  <a className={css.link} href="">
                    Your account
                  </a>
                </li>
                <li className={css.listItem}>
                  <a className={css.link} href="">
                    FAQ
                  </a>
                </li>
                <li className={css.listItem}>
                  <a className={css.link} href="">
                    Make an inquiry
                  </a>
                </li>
              </ul>
            </div> */}
            <div className={css.infoLinks}>
              <h2>About us</h2>
              <ul className={css.list}>
                {/* <li className={css.listItem}>
                  <a className={css.link} href="">
                    Aqtive-inc reviews
                  </a>
                </li> */}

                <li>
                  <NamedLink name="AboutPage" className={css.legalLink}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>

                <li>
                  <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                    <FormattedMessage id="Footer.termsOfUse" />
                  </NamedLink>
                </li>
                <li>
                  <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                    <FormattedMessage id="Footer.privacyPolicy" />
                  </NamedLink>
                </li>

                <li className={css.listItem}>
                  <NamedLink name="ContactUsPage" className={css.legalLink}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.contactLinks}>
              {/* <div className={css.staticRightHeading}>
                <h2>Contact Information</h2>
              </div> */}
              <h2>Contact Us</h2>
              <div className={css.contacts}>
                <div
                  // href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
                  className={css.someLinks}
                >
                  <Location />
                  <div>Nairobi, Kenya</div>
                </div>
                <div className={css.someLinks}>
                  <Phone />
                  <div className={css.linkClass}>
                    <a href="tel:+254 757 578 504">+254 757 578 504</a>
                  </div>
                </div>
                <div className={css.someLinks}>
                  <Mail />
                  <div className={css.linkClass}>
                    <a href="mailto:hello@aqtive.co" rel="noreferer">
                      {/* sabrieman1@live.com */}
                      hello@aqtive.co
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={css.searches}>
              <h2>Marketplace</h2>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=New%20York%20City%2C%20New%20York%2C%20USA&bounds=40.917576401307%2C-73.7008392055224%2C40.477399%2C-74.2590879797556',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchNewYork" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Los%20Angeles%2C%20California%2C%20USA&bounds=34.161440999758%2C-118.121305008073%2C33.9018913203336%2C-118.521456965901',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchLosAngeles" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=San%20Francisco%2C%20California%2C%20USA&bounds=37.8324430069081%2C-122.354995082683%2C37.6044780500533%2C-122.517910874663',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchSanFrancisco" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Seattle%2C%20Washington%2C%20USA&bounds=47.7779392908564%2C-122.216605992108%2C47.3403950185547%2C-122.441233019046',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchSeattle" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Portland%2C%20Oregon%2C%20USA&bounds=45.858099013046%2C-122.441059986416%2C45.3794799927623%2C-122.929215816001',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchPortland" />
                  </NamedLink>
                </li>
              </ul>
            </div> */}

            <div className={css.extraLinks}>
              <h2>Follow us</h2>
              {/* <div className={css.someLinks}>{socialMediaLinks}</div> */}

              <div className={css.someLinks}>
                <ExternalLink href={siteTiktokPage} key="linkToTiktok" title="tiktokPage">
                  <PiTiktokLogo />
                </ExternalLink>
                {/* <a href="" className={css.fillsvg}>
                  <FiFacebook />
                </a> */}
                <ExternalLink href={siteInstagramPage} key="linkToInstagram" title={goToInsta}>
                  <AiOutlineInstagram />
                </ExternalLink>
                {/* <ExternalLink href={siteTiktokPage} key="linkToTiktok" title="hbjh">
                  <Tiktok className={css.sMediaIcon} />
                </ExternalLink> */}
                {/* <a href="" className={css.fillsvg}>
                  <FiTwitter />
                </a>
                <a href="">
                  <AiOutlineYoutube />
                </a> */}
              </div>
            </div>
          </div>
          {/* <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
            </div>
          </div> */}
          <div className={css.bottmfooter}>
            <img src={footerlogo} />
            <p>Copyright © {new Date().getFullYear()} Aqtive Co. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
