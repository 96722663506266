import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldCheckbox } from '../../components';

import css from './SignupForm.module.css';
import { ReactComponent as View } from '../../assets/icons/PasswordView.svg';
import { ReactComponent as Hide } from '../../assets/icons/PasswordHide.svg';
const KEY_CODE_ENTER = 13;

const SignupFormComponent = (props) => (
  <FinalForm
    {...props}
    render={(fieldRenderProps) => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
        values,
      } = fieldRenderProps;
      //password hide and view
      const [isPasswordHidden, setIsPasswordHidden] = useState(true);
      const toggleShow = () => {
        setIsPasswordHidden((isPasswordHidden) => !isPasswordHidden);
      };
      const [isConfirmPasswordHidden, setIsConfirmPasswordHidden] = useState(true);
      const toggleConfirmShow = () => {
        setIsConfirmPasswordHidden((isConfirmPasswordHidden) => !isConfirmPasswordHidden);
      };
      const [passwordNotMatch, setPasswordNotMatch] = useState(false);

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });

      const confirmPasswordPlaceholder = intl.formatMessage({
        id: 'SignupForm.confirmPasswordPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );

      useEffect(() => {
        setPasswordNotMatch(values.password !== values.confirmPassword);
      }, [values.password, values.confirmPassword]);

      // const matchPasswordValidator = validators.matchPasswordValidator(values, touched);

      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });

      const confirmPasswordLabel = intl.formatMessage({
        id: 'SignupForm.confirmPasswordLabel',
      });

      const termsAndCondLabel = intl.formatMessage({
        id: 'SignupForm.termsAndCondLabel',
      });

      const lastNameRequired = validators.required(lastNameRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress || passwordNotMatch;

      const handleTermsKeyUp = (e) => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
              />
            </div>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.passwordDiv}>
              <FieldTextInput
                className={css.password}
                // type="password"
                type={isPasswordHidden ? 'password' : 'text'}
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={passwordLabel}
                placeholder={passwordPlaceholder}
                validate={passwordValidators}
              />
              {isPasswordHidden ? (
                <Hide className={css.hide} onClick={toggleShow} />
              ) : (
                <View className={css.view} onClick={toggleShow} />
              )}
            </div>

            {values.password &&
              values.password.length >= validators.PASSWORD_MIN_LENGTH &&
              values.password.length <= validators.PASSWORD_MAX_LENGTH &&
              values.password === values.confirmPassword && (
                <div style={{ color: 'green' }}>passwords match</div>
              )}
            <div className={css.passwordDiv}>
              <FieldTextInput
                className={css.password}
                // type="password"
                type={isConfirmPasswordHidden ? 'password' : 'text'}
                id={formId ? `${formId}.confirmPassword` : 'confirmPassword'}
                name="confirmPassword"
                autoComplete="new-password"
                label={confirmPasswordLabel}
                placeholder={confirmPasswordPlaceholder}
                // validate={validators.composeValidators(passwordValidators, matchPasswordValidator)}
                validate={passwordValidators}
              />
              {isConfirmPasswordHidden ? (
                <Hide className={css.hide} onClick={toggleConfirmShow} />
              ) : (
                <View className={css.view} onClick={toggleConfirmShow} />
              )}
            </div>
            {values.password &&
              values.password.length >= validators.PASSWORD_MIN_LENGTH &&
              values.password.length <= validators.PASSWORD_MAX_LENGTH &&
              values.password === values.confirmPassword && (
                <div style={{ color: 'green' }}>passwords match</div>
              )}

            {values.password &&
              values.password.length >= validators.PASSWORD_MIN_LENGTH &&
              values.password.length <= validators.PASSWORD_MAX_LENGTH &&
              passwordNotMatch && <div style={{ color: 'red' }}>Passwords don’t match</div>}

            <FieldCheckbox
              className={css.signupTerms}
              id="signupTerms"
              name="termsAndCond"
              label={termsAndCondLabel}
              value="termsAndCond"
              validate={validators.requiredFieldArrayCheckbox(
                'You need to accept Terms of Service'
              )}
            />
          </div>
          <div className={css.bottomWrapper}>
            {/* <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p> */}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
