import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
// import DatePicker from 'react-datepicker';
import { compose } from 'redux';
import Schedule from '../../components/Schedule/Schedule';
// import 'react-datepicker/dist/react-datepicker.css';
// import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  Button,
  NamedLink,
  IconClose,
  FieldCheckbox,
} from '../../components';

import css from './ListingScheduleForm.module.css';
import moment, { duration } from 'moment';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { withRouter } from 'react-router-dom';

import { ReactComponent as Toggleoff } from '../../assets/icons/toggleOff.svg';
import { ReactComponent as Toggleon } from '../../assets/icons/toggleOn.svg';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';

const weekdays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const ListingScheduleFormComponent = (props) => {
  const [scheduledData, setScheduledData] = useState(
    new Array(7).fill({ isActive: false, isEditing: false }).map((data, idx) => {
      return { day: weekdays[idx], schedule: [], ...data };
    })
  );

  useEffect(() => {
    const availableDays = props.availableDays || [];
    setScheduledData(
      scheduledData.map((s) => {
        if (availableDays.includes(s.day)) return { ...s, isActive: true };
        else return s;
      })
    );
  }, [props.initialValues]);

  const { history, listingId } = props;

  // const [specialDate, setSpecialDate] = useState([null]);

  const defaultTimeZone = () =>
    typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';
  const timezone = defaultTimeZone();

  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={(fieldRenderProps) => {
        const {
          rootClassName,
          className,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          values,
          pristine,
          duration,
          listing,
          actionType,
        } = fieldRenderProps;
        const { listingName } = listing || {};

        const createScheduleData = (values) => {
          const schedule = weekdays
            .map((d, idx) => {
              const isActive = scheduledData[idx].isActive;
              const sch = scheduledData[idx].schedule;

              if (values[d]) {
                return {
                  day: d,
                  isActive,
                  //map "sch" when initial values is ready
                  times: values[d].map((s) => {
                    //map "sch" when initial values is ready
                    return { ...s, isPeak: s['-isPeak'] === 'peak' ? true : false };
                  }),
                };
              }
            })
            .filter((data) => data !== undefined);

          return schedule;
        };

        const prepareScheduleDataForST = (values) => {
          const schedule = {
            type: 'availability-plan/time',
            timezone,
          };

          let entries = [];
          weekdays.forEach((d, idx) => {
            if (values[d]) {
              values[d].forEach((s) => {
                //map "sch" when initial values is ready
                entries.push({
                  dayOfWeek: d,
                  startTime: s.startTime,
                  endTime: s.endTime,
                  seats: 1000,
                });
              });
            }
          });
          const finalEntries = entries.filter((data) => data !== undefined);

          schedule.entries = [...finalEntries];

          return schedule;
        };
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;
        // !(selectedOwner?.name && selectedOwner?.id && selectedOwner?.email);

        const venueScheduleLabel = intl.formatMessage({
          id: 'SuperAdminDashboardListing.venueScheduleLabel',
        });

        const venueSpecialDayLabel = intl.formatMessage({
          id: 'SuperAdminDashboardListing.venueSpecialDayLabel',
        });

        const onSubmit = async (e) => {
          e.preventDefault();
          try {
            const body = {
              step: 3,
              schedule: createScheduleData(values),
              stSchedule: prepareScheduleDataForST(values),
              isFinalStep: true,
              // specialDate: specialDate.map(d => d.toLocaleDateString()),
            };

            axios
              .patch(`${apiBaseUrl()}/api/listing/${props.listingId}`, body, {
                withCredentials: true,
              })
              .then((res) => {
                if (res.data?.message === 'venue updated')
                  props.history.push('/super-admin-dashboard');
                // setReady(true);
                history.push(
                  createResourceLocatorString(
                    'ListingPage',
                    routeConfiguration(),
                    { slug: listingName, id: listingId },
                    {}
                  )
                );
              });
          } catch (err) {
            console.log(116, err);
          }
        };

        return (
          <Form onSubmit={onSubmit}>
            <div>
              <label>{venueScheduleLabel}</label>

              <div className={css.week}>
                {weekdays.map((w, idx) => {
                  return (
                    <div className={css.scheduleCont}>
                      <div
                        className={css.schedulecheckbox}
                        onClick={() => {
                          setScheduledData(
                            scheduledData.map((s) => {
                              if (s.day === w) return { ...s, isActive: !s.isActive };
                              else return s;
                            })
                          );
                        }}
                      >
                        {scheduledData.map(
                          (s) => s.day === w && (s.isActive ? <Toggleon /> : <Toggleoff />)
                        )}
                      </div>
                      {/* <input
                        type="checkbox"
                        onChange={e => {
                          setScheduledData(
                            scheduledData.map(s => {
                              if (s.day === w) return { ...s, isActive: e.target.checked };
                              else return s;
                            })
                          );
                        }}
                      /> */}

                      <Schedule
                        dayOfWeek={w}
                        key={w}
                        values={values}
                        intl={intl}
                        isEditing={scheduledData[idx].isEditing}
                        scheduledData={scheduledData}
                        setScheduledData={setScheduledData}
                        peakShow={true}
                        pristine={pristine}
                        duration={duration}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            {/* <div>
              <label>{venueSpecialDayLabel}</label>
              {specialDate.map((da, dIdx) => (
                <>
                  <DatePicker
                    selected={specialDate[dIdx] ? specialDate[dIdx] : null}
                    onChange={date => {
                      const newDates = specialDate.map((d, idx) => {
                        if (idx === dIdx) return date;
                        else return d;
                      });

                      setSpecialDate(newDates);
                    }}
                    showMonthDropdown
                    showYearDropdown
                    adjustDateOnChange
                  />
                </>
              ))}
              <div onClick={() => setSpecialDate([...specialDate, null])}>
                + Add Another special day
              </div>
            </div> */}
            <div className={css.formsbmitcnclbtn}>
              <Button
                type="button"
                onClick={() => {
                  history.goBack();
                }}
                className={css.backbtn}
              >
                Back
              </Button>

              <Button
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                className={css.sbmtbtn}
              >
                {actionType === 'new' ? 'Save & Publish' : 'Save and Next'}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

const ListingScheduleForm = compose(withRouter, injectIntl)(ListingScheduleFormComponent);
export default ListingScheduleForm;
