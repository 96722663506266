import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import classNames from 'classnames';
import { InlineTextButton, IconClose, FieldSelect, FieldRadioButton } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as RemoveIcon } from '../../assets/icons/remove.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';

import css from './Schedule.module.css';

const printHourStrings = (h) => (h > 9 ? `${h}:00` : `0${h}:00`);

const printTimeStrings = (t) => {
  const m = t % 60;
  const h = parseInt(t / 60);
  if (h > 9) {
    if (m > 9) {
      return `${h}:${m}`;
    } else {
      return `${h}:0${m}`;
    }
  } else {
    if (m > 9) {
      return `0${h}:${m}`;
    } else {
      return `0${h}:0${m}`;
    }
  }
};

// const HOURS = Array(24).fill();
// const ALL_START_HOURS = [...HOURS].map((v, i) => printHourStrings(i));
// const ALL_END_HOURS = [...HOURS].map((v, i) => printHourStrings(i + 1));

const hour = Array(288).fill();
const ALL_START_HOURS = hour.map((v, i) => printTimeStrings(i * 5));

const ALL_END_HOURS = hour.map((v, i) => printTimeStrings(i * 5 + 5));

const sortEntries =
  (defaultCompareReturn = 0) =>
  (a, b) => {
    if (a.startTime && b.startTime) {
      const aStart = Number.parseInt(a.startTime.split(':')[0]);
      const bStart = Number.parseInt(b.startTime.split(':')[0]);
      return aStart - bStart;
    }
    return defaultCompareReturn;
  };

const findEntryFn = (entry) => (e) =>
  e.startTime === entry.startTime && e.endTime === entry.endTime;

const filterStartHours = (availableStartHours, values, dayOfWeek, index) => {
  const entries = values[dayOfWeek];
  const currentEntry = entries[index];

  // If there is no end time selected, return all the available start times
  if (!currentEntry.endTime) {
    return availableStartHours;
  }

  // By default the entries are not in order so we need to sort the entries by startTime
  // in order to find out the previous entry
  const sortedEntries = [...entries].sort(sortEntries());

  // Find the index of the current entry from sorted entries
  const currentIndex = sortedEntries.findIndex(findEntryFn(currentEntry));

  // If there is no next entry or the previous entry does not have endTime,
  // return all the available times before current selected end time.
  // Otherwise return all the available start times that are after the previous entry or entries.
  const prevEntry = sortedEntries[currentIndex - 1];
  const pickBefore = (time) => (h) => h < time;
  const pickBetween = (start, end) => (h) => h >= start && h < end;

  return !prevEntry || !prevEntry.endTime
    ? availableStartHours.filter(pickBefore(currentEntry.endTime))
    : availableStartHours.filter(pickBetween(prevEntry.endTime, currentEntry.endTime));
};

const filterEndHours = (availableEndHours, values, dayOfWeek, index, duration = 5) => {
  // console.log(duration, availableEndHours);
  const entries = values[dayOfWeek];
  const currentEntry = entries[index];

  // If there is no start time selected, return an empty array;
  if (!currentEntry.startTime) {
    return [];
  }

  // By default the entries are not in order so we need to sort the entries by startTime
  // in order to find out the allowed start times
  const sortedEntries = [...entries].sort(sortEntries(-1));

  // Find the index of the current entry from sorted entries
  const currentIndex = sortedEntries.findIndex(findEntryFn(currentEntry));

  // If there is no next entry,
  // return all the available end times that are after the start of current entry.
  // Otherwise return all the available end hours between current start time and next entry.
  const nextEntry = sortedEntries[currentIndex + 1];
  const pickAfter = (time) => (h) => h > time;
  const pickBetween = (start, end) => (h) => h > start && h <= end;

  // return !nextEntry || !nextEntry.startTime
  //   ? availableEndHours.filter(pickAfter(currentEntry.startTime))
  //   : availableEndHours.filter(pickBetween(currentEntry.startTime, nextEntry.startTime));

  const endHours =
    !nextEntry || !nextEntry.startTime
      ? availableEndHours.filter(pickAfter(currentEntry.startTime))
      : ALL_START_HOURS.slice(
          ALL_START_HOURS.indexOf(currentEntry.startTime) + 1,
          ALL_START_HOURS.indexOf(nextEntry.startTime) + 1
        );

  return endHours.filter((x, i) => (i % (duration / 5)) + 1 === duration / 5);
};

const getEntryBoundaries = (values, dayOfWeek, intl, findStartHours) => (index) => {
  const entries = values[dayOfWeek];
  const boundaryDiff = findStartHours ? 0 : 1;

  return entries.reduce((allHours, entry, i) => {
    const { startTime, endTime } = entry || {};

    if (i !== index && startTime && endTime) {
      const startHour = Number.parseInt(startTime.split(':')[0]);
      const endHour = Number.parseInt(endTime.split(':')[0]);
      // const hoursBetween = Array(endHour - startHour)
      //   .fill()
      //   .map((v, i) => printHourStrings(startHour + i + boundaryDiff));

      const hoursBetween = ALL_START_HOURS.slice(
        ALL_START_HOURS.indexOf(startTime),
        endTime === '24:00' ? ALL_START_HOURS.length : ALL_START_HOURS.indexOf(endTime)
      );

      const hoursBetween2 = ALL_END_HOURS.slice(
        ALL_END_HOURS.indexOf(startTime),
        ALL_END_HOURS.indexOf(endTime)
      );

      // return allHours.concat(hoursBetween);
      return findStartHours
        ? hoursBetween.length
          ? allHours.concat(hoursBetween)
          : allHours.concat(['23:55'])
        : allHours.concat(hoursBetween2);
    }

    return allHours;
  }, []);
};

const Schedule = (props) => {
  const {
    dayOfWeek,
    values,
    intl,
    isEditing,
    scheduledData,
    setScheduledData,
    peakShow,
    pristine,
    duration,
  } = props;
  const getEntryStartTimes = getEntryBoundaries(values, dayOfWeek, intl, true);
  const getEntryEndTimes = getEntryBoundaries(values, dayOfWeek, intl, false);
  const hasEntries = values[dayOfWeek] && values[dayOfWeek][0];

  const startTimePlaceholder = intl.formatMessage({
    id: 'EditListingAvailabilityPlanForm.startTimePlaceholder',
  });
  const endTimePlaceholder = intl.formatMessage({
    id: 'EditListingAvailabilityPlanForm.endTimePlaceholder',
  });

  const pickTimeLabel = intl.formatMessage({
    id: 'EditListingAvailabilityPlanForm.pickTimeLabel',
  });
  const offPickTimeLabel = intl.formatMessage({
    id: 'EditListingAvailabilityPlanForm.offPickTimeLabel',
  });
  const printStartHour = (hour) => {
    const hr = parseInt(hour.split(':')[0]);
    const min =
      parseInt(hour.split(':')[1]) < 10
        ? `0${parseInt(hour.split(':')[1])}`
        : parseInt(hour.split(':')[1]);

    return parseInt(hr) <= 11
      ? `${hr}:${min} AM`
      : parseInt(hr) === 12
      ? `${hour} PM`
      : `${hr % 12}:${min} PM`;
  };

  const printEndHour = (hour) => {
    const hr = parseInt(hour.split(':')[0]);
    const min =
      parseInt(hour.split(':')[1]) < 10
        ? `0${parseInt(hour.split(':')[1])}`
        : parseInt(hour.split(':')[1]);

    return parseInt(hr) <= 11
      ? `${hr}:${min} AM`
      : hr === 12
      ? `12:00 PM`
      : hr === 24
      ? '00:00 PM'
      : `${hr % 12}:${min} PM`;
  };

  return (
    <div className={css.scheduledatacont}>
      {' '}
      <div className={classNames(css.weekDay, hasEntries ? css.hasEntries : null)}>
        <div className={css.daybtncont}>
          <div className={css.dayOfWeek}>
            <FormattedMessage id={`EditListingAvailabilityPlanForm.dayOfWeek.${dayOfWeek}`} />
          </div>

          {!isEditing && (
            <div className={css.timenedit}>
              <div className={css.timechipcont}>
                {values[dayOfWeek]?.map((s) => {
                  if (s.startTime && s.endTime) {
                    return (
                      <div className={css.timedisplay}>
                        {printStartHour(s.startTime)} - {printEndHour(s.endTime)}
                      </div>
                    );
                  }
                })}
              </div>
              <div>
                <button
                  onClick={() =>
                    setScheduledData(
                      scheduledData.map((data, idx) => {
                        if (data.day === dayOfWeek) return { ...data, isEditing: true };
                        else return data;
                      })
                    )
                  }
                >
                  <FieldArray name={dayOfWeek}>
                    {({ fields }) => {
                      return (
                        // fields.length === 0 && (
                        <InlineTextButton
                          type="button"
                          className={css.buttonSetHours}
                          onClick={() => {
                            fields.length === 0 && fields.push({ startTime: null, endTime: null });
                          }}
                        >
                          {/* <FormattedMessage id="EditListingAvailabilityPlanForm.setHours" /> */}
                          <EditIcon />
                          Edit
                        </InlineTextButton>

                        // )
                      );
                    }}
                  </FieldArray>
                  {/* <EditIcon />
                Edit */}
                </button>
              </div>
            </div>
          )}

          {/* {isEditing && (
            <>
            <FieldArray name={dayOfWeek}>
            {({ fields }) => {
              return (
            fields.length === 0 && (
                      <InlineTextButton
                        type="button"
                        className={css.buttonSetHours}
                        onClick={() => fields.push({ startTime: null, endTime: null })}
                      >
                        <FormattedMessage id="EditListingAvailabilityPlanForm.setHours" />
                      </InlineTextButton>
            
          ))}}
            </FieldArray>
            </>
                    ) } */}

          {isEditing && (
            <>
              {values[dayOfWeek]?.length > 0 && (
                <button
                  className={css.scheduledonebtn}
                  onClick={() =>
                    setScheduledData(
                      scheduledData.map((data, idx) => {
                        if (data.day === dayOfWeek)
                          return { ...data, isEditing: false, schedule: values[dayOfWeek] };
                        else return data;
                      })
                    )
                  }
                >
                  Done
                </button>
              )}
            </>
          )}
        </div>
        {isEditing && (
          <>
            <FieldArray name={dayOfWeek}>
              {({ fields }) => {
                return (
                  <div className={css.timePicker}>
                    {fields.map((name, index) => {
                      // Pick available start hours
                      const pickUnreservedStartHours = (h) =>
                        !getEntryStartTimes(index).includes(h);
                      const availableStartHours = ALL_START_HOURS.filter(pickUnreservedStartHours);

                      // Pick available end hours
                      const pickUnreservedEndHours = (h) => !getEntryEndTimes(index).includes(h);
                      const availableEndHours = ALL_END_HOURS.filter(pickUnreservedEndHours);

                      return (
                        <div className={css.fieldWrapper} key={name}>
                          <div className={css.formRow}>
                            <div className={css.field}>
                              <div className={css.labelText}>Starting Time</div>
                              <FieldSelect
                                id={`${name}.startTime`}
                                name={`${name}.startTime`}
                                selectClassName={css.fieldSelect}
                              >
                                <option disabled value="">
                                  {startTimePlaceholder}
                                </option>
                                {filterStartHours(
                                  availableStartHours,
                                  values,
                                  dayOfWeek,
                                  index
                                ).map((s) => (
                                  <option value={s} key={s}>
                                    {printStartHour(s)}
                                    {/* {s} */}
                                  </option>
                                ))}
                              </FieldSelect>
                            </div>
                            {/* <span className={css.dashBetweenTimes}>-</span> */}
                            <div className={css.field}>
                              <div className={css.labelText}>Finishing Time</div>
                              <FieldSelect
                                id={`${name}.endTime`}
                                name={`${name}.endTime`}
                                selectClassName={css.fieldSelect}
                              >
                                <option disabled value="">
                                  {endTimePlaceholder}
                                </option>
                                {filterEndHours(
                                  availableEndHours,
                                  values,
                                  dayOfWeek,
                                  index,
                                  duration
                                ).map((s) => (
                                  <option value={s} key={s}>
                                    {printEndHour(s)}
                                    {/* {s} */}
                                  </option>
                                ))}
                              </FieldSelect>
                            </div>
                            {peakShow && (
                              <div className={css.peakTimeDiv}>
                                <div>
                                  <FieldRadioButton
                                    id={`${name}-isPeak`}
                                    name={`${name}-isPeak`}
                                    label={pickTimeLabel}
                                    value="peak"
                                    // checkedClassName={css.checkedAvailable}
                                    showAsRequired={pristine}
                                    className={css.peakRadioBtn}
                                  />
                                </div>
                                <div>
                                  <FieldRadioButton
                                    id={`${name}-isOffPeak`}
                                    name={`${name}-isPeak`}
                                    label={offPickTimeLabel}
                                    value="offPeak"
                                    // checkedClassName={css.checkedAvailable}
                                    showAsRequired={pristine}
                                    className={css.peakRadioBtn}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={css.removeschdulecont}>
                            <div></div>
                            <div
                              className={css.fieldArrayRemove}
                              onClick={() => {
                                fields.remove(index);
                                setScheduledData(
                                  scheduledData.map((data, idx) => {
                                    if (data.day === dayOfWeek)
                                      return {
                                        ...data,
                                        isEditing: fields.value?.length === 1 ? false : true,
                                      };
                                    else return data;
                                  })
                                );
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              {/* <IconClose rootClassName={css.closeIcon} /> */}
                              <RemoveIcon />
                              Remove this schedule
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {/* fields.length === 0 ? (
                      <InlineTextButton
                        type="button"
                        className={css.buttonSetHours}
                        onClick={() => fields.push({ startTime: null, endTime: null })}
                      >
                        <FormattedMessage id="EditListingAvailabilityPlanForm.setHours" />
                      </InlineTextButton> */}
                    {/* {console.log(454, fields, dayOfWeek.mon)} */}
                    {fields.length !== 0 && (
                      <div className={css.addanothercont}>
                        <AddIcon />{' '}
                        <InlineTextButton
                          type="button"
                          className={css.linkText}
                          onClick={() => fields.push({ startTime: null, endTime: null })}
                        >
                          <FormattedMessage id="EditListingAvailabilityPlanForm.addAnotherTime" />
                        </InlineTextButton>
                      </div>
                    )}
                  </div>
                );
              }}
            </FieldArray>
          </>
        )}
      </div>
    </div>
  );
};

export default Schedule;
