import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AvatarLarge, NamedLink } from '../../components';

import css from './CustomTabNav.module.css';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { ReactComponent as ArrrowDown } from '../../assets/icons/arrowDown.svg';
import { propTypes } from '../../util/types';

const Tab = (props) => {
  const { className, id, disabled, text, selected, linkProps, icon, selectedClassName } = props;
  const linkClasses = classNames(css.link, {
    [css.selectedLink]: selected,
    [css.disabled]: disabled,
    [selectedClassName]: !!selectedClassName && selected,
  });
  return (
    <div id={id} className={className}>
      <NamedLink className={linkClasses} {...linkProps}>
        {icon && <span className={css.icon}>{icon}</span>}
        {text}
      </NamedLink>
    </div>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string, number, shape } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
  selectedClassName: string,
};

const CustomTabNav = (props) => {
  const {
    className,
    rootClassName,
    tabRootClassName,
    tabs,
    horizontalSeperator,
    viewport,
    mobileBreakpoint,
    showUser,
    user,
    userclassName,
    mobileBtnClassName,
    mobileBtnActiveClassName,
    navSectionRootClassname,
  } = props;
  const [showMobileNav, setShowMobileNav] = useState(false);
  const { width } = viewport;
  const classes = classNames(rootClassName || css.root, className, {
    [css.mobileHiddenNav]: width < mobileBreakpoint && !showMobileNav,
  });
  const tabClasses = tabRootClassName || css.tab;
  const seperator = horizontalSeperator || css.horizontalSeperator;
  const selectedTab = tabs.find((tab) => tab.selected);

  useEffect(() => {
    if (width >= mobileBreakpoint) {
      setShowMobileNav(false);
    }
  }, [width]);

  return (
    <section
      className={classNames(css.navSection, navSectionRootClassname, {
        [css.mobileNavSection]: width < mobileBreakpoint && !showMobileNav,
      })}
      onClick={() => setShowMobileNav((showMobileNav) => !showMobileNav)}
    >
      <div
        className={classNames(css.mobilBtnWrapper, {
          [css.desktopHiddentBtn]: width >= mobileBreakpoint,
          [css.mobileNavActived]: showMobileNav,
          [mobileBtnClassName]: width >= mobileBreakpoint,
          [mobileBtnActiveClassName]: showMobileNav,
        })}
      >
        <span className={css.navTitle}>{selectedTab.text}</span>
        <button
          type="button"
          className={css.selectedBtn}
          // onClick={() => setShowMobileNav(showMobileNav => !showMobileNav)}
        >
          <ArrrowDown
            className={classNames(css.arrowDown, { [css.rotatedArrowDown]: showMobileNav })}
          />
        </button>
      </div>

      <nav className={classes}>
        {showUser && !!user ? (
          <AvatarLarge className={userclassName} user={user} disableProfileLink />
        ) : null}
        {tabs.map((tab, index) => {
          const id = typeof tab.id === 'string' ? tab.id : `${index}`;
          return tab.type === 'seperator' ? (
            <div className={classNames(tabClasses, seperator)} key={index}>
              <h2 className={tab.label ? css.hrLine : css.hrLinewithOutLabel}>
                {tab.label ? tab.label : null}
              </h2>
            </div>
          ) : (
            <Tab key={id} id={id} className={tabClasses} {...tab} />
          );
        })}
      </nav>
    </section>
  );
};

CustomTabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
  mobileBreakpoint: 768,
  showUser: false,
  user: null,
  userclassName: null,
  navSectionRootClassname: null,
  mobileBtnClassName: null,
  mobileBtnActiveClassName: null,
};

CustomTabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
  viewport: shape({ width: number.isRequired, height: number.isRequired }).isRequired,
  mobileBreakpoint: number.isRequired,
  showUser: bool.isRequired,
  user: propTypes.user,
  userclassName: string,
  mobileBtnClassName: string,
  mobileBtnActiveClassName: string,
  navSectionRootClassname: string,
};

export default compose(withViewport)(CustomTabNav);
